import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import PropTypes from 'prop-types';
import {getSemiUniqueKey, isLocalhost, timestamp} from "./functions";
const DataContext = React.createContext();

export class DataProvider extends React.Component {
    constructor(props) {
        super(props);
        this.initState = {
            isDataLoaded: false,
            isDataError: false,
            dataErrorCode: false,
            dataErrorMessage: false,
            isDemo: true,
            isClientOnline: true,
            getOnce: this.getOnce.bind(this),
            // Get data by fetching or listening.
            fetched: [],
            fetch: this.fetch.bind(this),
            listenTo: this.listenTo.bind(this),
            pushTo: this.pushTo.bind(this),
            startSendingPings: this.startSendingPings.bind(this),
            startListeners: this.startListeners.bind(this),
            operationIds: [],
            // Perform actions on a single document.
            create: this.create.bind(this),
            update: this.update.bind(this),
            delete: this.delete.bind(this),
            // Perform actions on multiple documents.
            createMultiple: this.createMultiple.bind(this),
            updateMultiple: this.updateMultiple.bind(this),
            deleteMultiple: this.deleteMultiple.bind(this),
            // User properties. getUser combines usersPublic and usersPrivate data.
            plan: false,
            userId: false,
            userEmail: false,
            userStartedLoggedOut: false,
            userClaims: {},
            userTimeFormat: 'H:mm',
            userRefreshClaims: this.userRefreshClaims.bind(this),
            login: this.login.bind(this),
            getUser: this.getUser.bind(this),
            signOut: this.signOut.bind(this),
            // Ways to mutate arrays in Firestore.
            addToArray: this.addToArray.bind(this),
            toggleInArray: this.toggleInArray.bind(this),
            updateInArray: this.updateInArray.bind(this),
            deleteFromArray: this.deleteFromArray.bind(this),
            // Firebase Storage (no getFile because we save download URL in Firestore).
            uploadFile: this.uploadFile.bind(this),
            deleteFile: this.deleteFile.bind(this),
            // Session keys (sessionStorage) are used for saving short-term view states (e.g. search queries).
            session: {},
            setSession: this.setSession.bind(this),
            clearSession: this.clearSession.bind(this),
            // Local keys (localStorage) are used for saving view states.
            local: {},
            setLocal: this.setLocal.bind(this),
            clearLocal: this.clearLocal.bind(this),
            // Local storage without local suffix is used to cache data from Firestore.
            manuallyAddData: this.manuallyAddData.bind(this),
            updateData: this.updateData.bind(this),
            resetData: this.resetData.bind(this),
            setDemoData: this.setDemoData.bind(this),
            deleteAccount: this.deleteAccount.bind(this),
        };
        this.state = {...this.initState};
        this.hasFirebase = firebase.apps.length > 0;
        this.debug = isLocalhost();
        if(this.debug) console.log('Number of Firebase apps:', firebase.apps.length);
        if(this.hasFirebase) {
            const db = firebase.firestore();
            const realtime = firebase.database();
            const auth = firebase.auth();
            const storage = firebase.storage();
            if(isLocalhost() && !this.props._dangerouslyUseProduction) {
                db.useEmulator("localhost", 8080);
                realtime.useEmulator('localhost', 9000);
                auth.useEmulator('http://localhost:9099');
                storage.useEmulator("localhost", 9199);
            }
            this.db = db;
            this.realtime = realtime;
            this.auth = auth;
            this.storage = storage.ref();
        }
        window.appTimeFormat = 'H:mm';
        window.appDateFormat = 'D M Y';
    }
    componentDidMount() {
        if(this.hasFirebase) {
            this.auth.onAuthStateChanged(this.onAuthChange.bind(this));
        } else {
            if(this.debug) console.log("Initialized without user (no Firebase)");
            this.loadSessionAndLocal();
            this.setState({isDataLoaded: true});
        }
    }
    async deleteAccount(password) {
        return new Promise(async (resolve, reject) => {
            // Reauthenticate
            try {
                const credential = firebase.auth.EmailAuthProvider.credential(
                    this.state.userEmail,
                    password
                );
                await this.auth.currentUser.reauthenticateWithCredential(credential);
            } catch(e) {
                reject('This password is incorrect.');
            }
            try {
                await this.auth.currentUser.delete();
            } catch(e) {
                reject('Could not delete account. Please contact us for help.');
            }
            resolve();
        });
    }
    loadSessionAndLocal() {
        const session = window.sessionStorage.getItem(this.props.storageKey + '-session') || window.sessionStorage.getItem(this.props.storageKey + '-session-demo');
        if(session) {
            try {
                const obj = JSON.parse(session);
                this.setState({session: obj}, () => {
                    if(this.debug) console.log('Session loaded from sessionStorage', obj);
                });
            } catch(e) {
                // Could not load sessionStorage. Do nothing - keep sessionStorage empty.
                if(this.debug) throw e;
            }
        } else {
            if(this.debug) console.log('No session loaded from sessionStorage');
        }
        const local = window.localStorage.getItem(this.props.storageKey + '-local') || window.sessionStorage.getItem(this.props.storageKey + '-local-demo');
        if(local) {
            try {
                const obj = JSON.parse(local);
                this.setState({local: obj}, () => {
                    if(this.debug) console.log('Local loaded from localStorage', obj);
                });
            } catch(e) {
                // Could not load localStorage. Do nothing - keep localStorage empty.
                if(this.debug) throw e;
            }
        } else {
            if(this.debug) console.log('No local loaded from localStorage');
        }
        if(this.props.saveLocally) {
            const localData = window.localStorage.getItem(this.props.storageKey + '-data');
            if(localData) {
                try {
                    const obj = JSON.parse(localData);
                    this.setState(obj, () => {
                        if(this.debug) console.log('Data loaded from localStorage', obj);
                    });
                } catch(e) {
                    // Could not load localStorage. Do nothing - keep localData empty.
                    if(this.debug) throw e;
                }
            } else {
                if(this.debug) console.log('No data loaded from localStorage');
            }
        }
    }
    async onAuthChange(user) {
        if(this.debug) console.log(`Start loading data (${this.state.userId} -> ${user ? user.uid : false}) (show loader)`);
        try {
            if(user) {
                if(this.state.isDataLoaded) this.setState({isDataLoaded: false});
                if(this.debug) console.log("Logged in", {id: user.uid, email: user.email});
                if(this.props.demoData) {
                    if(this.debug) console.log('Clear demo data');
                    // Clear demo data
                    await this.resetData();
                    await this.clearSession(true);
                    await this.clearLocal(true);
                }
                // Load user data
                if(this.debug) console.log('Load data');
                this.loadSessionAndLocal();
                await this.userRefreshClaims();
                if(!!this.props.needsData) await this.fetch(this.props.needsData(user.uid, this.getProjectIdsFromClaims()));
                if(this.debug) console.log(`Done loading data (hide loader)`);
                if(typeof this.props.onAuthChange === 'function') await this.props.onAuthChange(user, this.state, this.manuallyAddData.bind(this));
                this.setState({isDemo: false, userId: user.uid, userEmail: user.email, isDataLoaded: true}, () => {
                    this.startListeners();
                    this.startSendingPings();
                });
            } else {
                if(this.state.isDataLoaded) {
                    // Reset if initialized and user logs out (don't show loader)
                    if(this.debug) console.log("Logged out");
                    if(this.debug) console.log('Clear user data & load demo');
                    this.stopSendingPings();
                    this.stopListeners();
                    await this.resetData(true);
                    await this.clearSession();
                    await this.clearLocal();
                    await this.setDemoData();
                    if(this.debug) console.log(`Done setting demo data (hide loader)`);
                    this.setState({plan: 'business', isDemo: true});
                } else {
                    // Initialize without user (demo if present)
                    if(this.state.isDataLoaded) this.setState({isDataLoaded: false});
                    if(this.debug) console.log("Initialized without user, load demo");
                    this.loadSessionAndLocal();
                    await this.setDemoData();
                    if(this.debug) console.log(`Done setting demo data (hide loader)`);
                    this.setState({plan: 'business', isDemo: true, isDataLoaded: true, userStartedLoggedOut: true});
                }
            }
        } catch(e) {
            if(this.debug) throw e;
            this.setState({isDataLoaded: true, isDataError: true, dataErrorCode: e.code, dataErrorMessage: e.message});
        }
    }
    login(email, password) {
        return this.auth.signInWithEmailAndPassword(email, password);
    }
    getProjectIdsFromClaims() {
        // Note: this includes all projects the user is owner of member of.
        const projectIds = [];
        for(const claimKey in this.state.userClaims) {
            if(!this.state.userClaims.hasOwnProperty(claimKey)) continue;
            if(claimKey.startsWith('project-')) {
                const projectId = claimKey.substring('project-'.length);
                projectIds.push(projectId);
            }
        }
        return projectIds;
    }
    async startListeners() {
        // Note: this should be invoked AFTER needsData() is finished.
        this.stopListeners(); // Stop old listeners just to make sure.
        const startTimestamp = Math.floor(Date.now() / 1000);
        const sharedProjectIds = (this.state.projects || []).map(x => x.id); // Don't filter by x.memberIds.length > 1 here: always broadcast edits in case you have multiple tabs open.
        for(const projectId of sharedProjectIds) {
            this.startListener(`/projects/${projectId}/edits`, startTimestamp);
        }
        if(!this.networkListener && !this.state.isDemo) this.networkListener = this.realtime.ref(".info/connected").on("value", (snap) => {
            // Monitor if the client is online.
            // See: https://firebase.google.com/docs/database/web/offline-capabilities#web-version-8_3
            // Only set state after first invoke to circumvent a false state change while initially connecting.
            if(this.networkListenerInvokes > 0) {
                if(snap.val() !== true && this.state.isClientOnline) {
                    this.setState({isClientOnline: false});
                }
            }
            this.networkListenerInvokes = (this.networkListenerInvokes || 0) + 1;
        });
    }
    startListener(path, startTimestamp) {
        const listener = this.listenTo(path, 'child_added', startTimestamp, ({operation, operationId, collection, id, data, userId}) => {
            const isUnique = !this.state.operationIds.includes(operationId);
            const isSupportedCollection = this.props.pushEditsInCollections?.includes(collection);
            if(isUnique && isSupportedCollection) {
                try {
                    data = data && JSON.parse(data);
                    if(this.debug) console.log(`Received ${collection}`, {operation, operationId, collection, id, data, userId});
                    this.setState({operationIds: [...this.state.operationIds, operationId]});
                    if(operation === 'create') {
                        // Only create if ID does not already exists (should generally never happen, but added as a precaution).
                        if(data?.id && !this.state[collection].some(x => x.id === data.id)) {
                            this.create(collection, data, true);
                        }
                    } else if(operation === 'update') {
                        this.update(collection, id, data, true);
                    } else if(operation === 'delete') {
                        this.delete(collection, id, false, false, false, true);
                    } else if(operation === 'createMultiple') {
                        this.createMultiple(collection, data, true);
                    } else if(operation === 'updateMultiple') {
                        this.updateMultiple(collection, data, false, true);
                    } else if(operation === 'deleteMultiple') {
                        this.deleteMultiple(collection, data, false, false, true);
                    } else if(operation === 'fetch') {
                        this.fetch({collection, id, force: true});
                    }
                } catch(e) {
                    if(this.debug) throw e;
                }
            }
            if(isUnique && operation === 'claims' && userId === 'admin') {
                this.userRefreshClaims(); // In case user got deleted from project.
            }
        });
        this.listeners.push(listener);
    }
    stopListeners() {
        if(this.listeners) for(const listener of this.listeners) {
            listener.stop();
        }
        this.listeners = [];
    }
    setDemoData() {
        if(this.debug) console.log('Set demo data');
        if(this.props.demoData) this.setState({
            ...this.props.demoData,
            userId: 'demo-user',
            userEmail: 'demo@example.com',
        });
        if(this.props.demoLocal) this.setLocal(this.props.demoLocal, true);
        if(this.props.demoSession) this.setSession(this.props.demoSession, true);
    }
    listenTo(path, event, startTimestamp, onData) {
        // child_added / child_changed
        if(this.state.isDemo) return false;
        if(this.debug) console.log('Start listening to ' + path);
        let ref = this.realtime.ref(path).orderByChild('timestamp');
        if(startTimestamp) ref = ref.startAt(startTimestamp);
        const listener = ref.on(event, snapshot => {
            onData(snapshot.val(), snapshot.key);
        });
        return {stop: () => {
            if(this.debug) console.log('Stop listening to ' + path);
            ref.off(event, listener)
        }};
    }
    getOnce(path) {
        if(this.state.isDemo || !this.realtime) return false;
        return new Promise(async (resolve, reject) => {
            let ref = this.realtime.ref(path).orderByChild('timestamp');
            ref.once('value').then((snapshot) => {
                if(snapshot.exists()) {
                    return resolve(snapshot.val());
                } else {
                    return resolve(false);
                }
            }).catch((error) => {
                return reject(error);
            });
        });
    }
    setTo(path, obj) {
        if(this.state.isDemo || !this.realtime) return false;
        return new Promise(async (resolve, reject) => {
            const ref = this.realtime.ref(path);
            const inner = async (isSecondTry = false) => {
                try {
                    await ref.set(obj);
                    resolve();
                } catch(e) {
                    if(!isSecondTry) {
                        // Try again after refreshing user claims.
                        await this.userRefreshClaims();
                        return inner(true);
                    }
                    reject(e);
                }
            };
            inner();
        });
    }
    pushTo(path, obj) {
        if(this.state.isDemo || !this.realtime) return false;
        return new Promise(async (resolve, reject) => {
            const ref = this.realtime.ref(path);
            const inner = async (isSecondTry = false) => {
                try {
                    if(this.debug) console.log(`Pushing${obj.collection ? ` ${obj.collection}` : ''}:`, obj);
                    await ref.push().set(obj);
                    resolve();
                } catch(e) {
                    if(!isSecondTry) {
                        // Try again after refreshing user claims.
                        await this.userRefreshClaims();
                        return inner(true);
                    }
                    console.error(e.message);
                    reject(e);
                }
            };
            return inner();
        });
    }
    updateData() {
        if(!this.state.isDemo && !this.props.saveLocally) return false;
        const initKeys = Object.keys(this.initState);
        const localState = {...this.state};
        for(const initKey of initKeys) {
            if(initKey === 'fetched') continue;
            delete localState[initKey];
        }
        window.localStorage.setItem(this.props.storageKey + '-data', JSON.stringify(localState));
    }
    async resetData(isDataLoaded = false) {
        return new Promise(async (resolve) => {
            const keys = Object.keys(this.state);
            const newState = {...this.initState};
            const newKeys = Object.keys(newState);
            for(const key of keys) {
                if(!newKeys.includes(key)) {
                    newState[key] = undefined;
                }
            }
            newState.isDataLoaded = isDataLoaded;
            if(isDataLoaded) window.localStorage.removeItem(this.props.storageKey + '-data');
            this.setState(newState, () => resolve());
        })
    }
    async fetch(args) {
        if(!this.auth.currentUser && !args.forceAnonymous) return false;
        if(typeof args === 'function') {
            args = args(); // Run function to get data
        }
        if(Array.isArray(args)) {
            const promises = args.map(arg => this.doFetch(arg));
            return Promise.all(promises);
        } else {
            return this.doFetch(args);
        }
    }
    async doFetch(args) {
        if(!this.auth.currentUser && !args.forceAnonymous) return false;
        return new Promise(async (resolve, reject) => {
            let collection = args, ids;

            if(typeof args === 'string') {
                args = {collection: args};
            } else if(typeof args === 'object') {
                collection = args.collection;
                ids = args.ids || args.id;
            } else {
                return reject('Incorrect arg type provided to fetch');
            }

            // Prepare collection to prevent errors while loading.
            if(!this.state[collection]) this.setState({[collection] : []});

            if(!Array.isArray(ids)) ids = ids ? [ids] : [];

            // If command already fetched
            if(!args.id && !args.force) {
                const cmd = `${collection} ${JSON.stringify(args.limit || 0)} ${JSON.stringify(args.orderBy || [])} ${JSON.stringify(args.where || [])}`;
                const fetched = [...this.state.fetched];
                if(fetched.includes(cmd)) {
                    if(this.debug) console.log(`Already fetched`, args);
                    return resolve();
                } else {
                    fetched.push(cmd);
                    if(this.debug) console.log(`Fetching ${collection}`, args, {userId: this.state.userId});
                    this.setState({fetched});
                }
            }

            // If ID already exists
            let alreadyFetchedIds = [];
            if(args.id && !args.force) {
                for(const id of ids) {
                    const hasDoc = !!this.state[collection] && !!this.state[collection].find(x => x.id === id);
                    if(hasDoc) alreadyFetchedIds.push(id);
                }
                if(alreadyFetchedIds.length) if(this.debug) console.log(`Already fetched IDs`, alreadyFetchedIds, args);
                if(alreadyFetchedIds.length === ids.length) return resolve(); // No need to fetch anything.
            }

            const inner = async (isSecondTry = false) => {
                try {
                    let docs;
                    if(ids.length > 1) {
                        const refs = ids.map(id => this.db.doc(`${args.collection}/${id}`).get());
                        if(this.debug) console.log('Created refs', ids.map(id => `this.db.doc(${args.collection}/${id})`))
                        docs = await Promise.all(refs);
                    } else {
                        const ref = this.getRef(collection, args);
                        docs = await ref.get();
                    }
                    const data = this.state[collection] ? [...this.state[collection]] : [];
                    if(ids.length === 1) {
                        if(docs.exists) { // DON'T MERGE WITH STATEMENT ABOVE
                            const index = data.findIndex(x => x.id === docs.id);
                            const obj = {id: docs.id, ...docs.data()};
                            if(index > -1) {
                                data[index] = obj;
                            } else {
                                data.push(obj);
                            }
                            if(args.force) {
                                const projectId = collection === 'projects' ? obj.id : obj.projectId;
                                this.pushToOtherMembers('fetch', collection, args.id, false, projectId);
                            }
                        }
                    } else {
                        docs.forEach(doc => {
                            if(doc.exists) {
                                const index = data.findIndex(x => x.id === doc.id);
                                const obj = {id: doc.id, ...doc.data()};
                                if(index > -1) {
                                    data[index] = obj;
                                } else {
                                    data.push(obj);
                                }
                            }
                        });
                    }
                    if(this.debug) console.log(`Resolved ${collection}`, args, data);
                    this.setState({[collection]: data}, () => {
                        this.updateData();
                        resolve();
                    });
                } catch(err) {
                    if(!isSecondTry) {
                        // Try again after refreshing user claims.
                        await this.userRefreshClaims();
                        return inner(true);
                    }
                    const collection = typeof args === 'object' ? args.collection : args;
                    if(!args.id) {
                        // Delete cmd from fetched
                        const fetched = [...this.state.fetched];
                        const cmd = `${collection} ${JSON.stringify(args.limit || 0)} ${JSON.stringify(args.orderBy || [])} ${JSON.stringify(args.where || [])}`;
                        const index = fetched.indexOf(cmd);
                        if(index > -1) fetched.splice(index, 1);
                        this.setState({fetched});
                    }

                    if(this.debug) throw new Error(`Rejected ${collection}`, args, err.message);
                    reject(err);
                }
            };

            return inner();
        });
    }
    manuallyAddData(obj) {
        for(const collection in obj) {
            if(!obj.hasOwnProperty(collection)) continue;
            const collectionItems = obj[collection];
            const data = this.state[collection] ? [...this.state[collection]] : [];
            for(const item of collectionItems) {
                const index = data.findIndex(x => x.id === item.id);
                if(index > -1) {
                    data[index] = item;
                } else {
                    data.push(item);
                }
            }
            this.setState({[collection]: data}, () => {
                this.updateData();
            });
        }
    }
    async userRefreshClaims() {
        if(!this.auth.currentUser) return false;
        return new Promise(async (resolve, reject) => {
            if(!this.auth.currentUser) return reject();
            try {
                await this.auth.currentUser.getIdToken(true);
                const idTokenResult = await this.auth.currentUser.getIdTokenResult();
                // Claims is object
                let userClaims = {...idTokenResult.claims};
                // Delete redundant stuff so that only our custom claims get saved
                delete userClaims.aud;
                delete userClaims.auth_time;
                delete userClaims.email;
                delete userClaims.email_verified;
                delete userClaims.exp;
                delete userClaims.firebase;
                delete userClaims.iat;
                delete userClaims.iss;
                delete userClaims.sub;
                delete userClaims.user_id;
                if(this.debug) console.log('Refreshed user claims: ', userClaims);
                this.setState({userClaims, plan: userClaims.plan || false}, () => {
                    resolve(userClaims);
                });
            } catch(e) {
                reject(e);
            }
        });
    }
    stopSendingPings() {
        if(Array.isArray(this.pingIntervals) && this.pingIntervals.length) {
            for(const interval of this.pingIntervals) {
                window.clearInterval(interval);
            }
        }
        this.pingIntervals = [];
    }
    async startSendingPings() {
        // Note: this should be invoked AFTER needsData() is finished.
        if(this.state.isDemo) return false;
        this.stopSendingPings(); // Clear old intervals if necessary
        const sharedProjectIds = (this.state.projects || []).filter(x => (x.memberIds || []).length > 1).map(x => x.id);
        for(const projectId of sharedProjectIds) {
            this.startPingingTo(`/projects/${projectId}/pings/${this.state.userId}`);
        }
    }
    startPingingTo(path) {
        if(this.debug) console.log('Start pinging to ' + path);
        this.pingIntervals.push(window.setInterval(() => {
            // Ping every minute
            this.setTo(path, Math.floor(Date.now() / 1000));
        }, 60000));
        // Also ping directly to immediately show up online
        this.setTo(path, Math.floor(Date.now() / 1000));
    }
    pushToOtherMembers(operation, collection, id, data, projectId) {
        if(this.state.isDemo) return false;
        const isShared = this.props.pushEditsInCollections?.includes(collection);
        const project = (this.state.projects || []).find(x => x.id === projectId);
        if(isShared && project) { // Do not check for memberIds.length > 1 here: always broadcast edits in the case you have multiple tabs open.
            const operationId = getSemiUniqueKey();
            const obj = {
                operationId,
                userId: this.state.userId,
                timestamp: Math.floor(Date.now() / 1000),
                operation,
                collection,
            };
            if(id) obj.id = id;
            if(data) obj.data = JSON.stringify(data); // Note: if not stringified, realtime DB will remove things like empty arrays in data.
            this.setState({operationIds: [...this.state.operationIds, operationId]});
            this.pushTo(`/projects/${project.id}/edits`, obj);
        }
    }
    async create(collection, data = {}, onlyLocal = false, forceAnonymous = false) {
        return new Promise(async (resolve, reject) => {
            const newCollection = this.state[collection] ? [...this.state[collection]] : [];
            const id = data.id || this.db.collection(collection).doc().id;
            const prevCollection = this.state[collection] ? [...this.state[collection]] : [];
            const newData = {id, createdBy: this.state.userId, createdOn: timestamp(), ...data};
            newCollection.push(newData);
            this.setState({[collection]: newCollection});

            if(onlyLocal || (this.state.isDemo && !forceAnonymous)) {
                // Save locally for now.
                return resolve();
            }

            const inner = async (isSecondTry = false) => {
                try {
                    await this.db.collection(collection).doc(id).set(newData);
                    this.updateData();
                    if(collection !== 'projects') this.pushToOtherMembers('create', collection, false, newData, newData.projectId);
                    return resolve(id);
                } catch(e) {
                    this.setState({[collection]: prevCollection});
                    if(!isSecondTry) {
                        // Try again after refreshing user claims.
                        await this.userRefreshClaims();
                        return inner(true);
                    }
                    console.error(e.message);
                    return reject(e.message);
                }
            }
            return inner();
        });
    }
    async createMultiple(collection, arrayOfDataObjects, onlyLocal = false) {
        return new Promise(async (resolve, reject) => {
            const prevCollection = this.state[collection] ? [...this.state[collection]] : [];
            const newCollection = this.state[collection] ? [...this.state[collection]] : [];

            const inner = async (isSecondTry = false) => {
                try {
                    const batch = this.db.batch();
                    const newArrayOfDataObjects = [];
                    for(const data of arrayOfDataObjects) {
                        const id = data.id || this.db.collection(collection).doc().id;
                        const newData = {id, createdBy: this.state.userId, createdOn: timestamp(), ...data};
                        newCollection.push(newData);
                        newArrayOfDataObjects.push(newData);
                        const ref = this.db.collection(collection).doc(id);
                        batch.set(ref, newData);
                    }
                    if(!isSecondTry) this.setState({[collection]: newCollection});

                    if(onlyLocal || this.state.isDemo) {
                        // Save locally for now.
                        return resolve();
                    }
                    await batch.commit();
                    this.updateData();
                    if(collection !== 'projects') this.pushToOtherMembers('createMultiple', collection, false, newArrayOfDataObjects, arrayOfDataObjects[0].projectId);
                    return resolve();
                } catch(e) {
                    this.setState({[collection]: prevCollection});
                    if(!isSecondTry) {
                        // Try again after refreshing user claims.
                        await this.userRefreshClaims();
                        return inner(true);
                    }
                    console.error(e.message);
                    return reject(e.message);
                }
            };

            return inner();
        });
    }
    async updateMultiple(collection, objWithIdAndDataOrArrayOfIds, doSetOrObj = false, onlyLocal = false) {
        return new Promise(async (resolve, reject) => {
            if(Array.isArray(objWithIdAndDataOrArrayOfIds)) {
                // In this case, objWithIdAndDataOrArrayOfIds is an array of ids and doSetOrObj is the data object.
                const newObj = {};
                for(const id of objWithIdAndDataOrArrayOfIds) {
                    newObj[id] = doSetOrObj;
                }
                objWithIdAndDataOrArrayOfIds = newObj;
                doSetOrObj = false;
            }

            const prevCollection = this.state[collection] ? [...this.state[collection]] : [];
            const newCollection = this.state[collection] ? [...this.state[collection]] : [];

            const inner = async (isSecondTry = false) => {
                const batch = this.db.batch();

                for(const id in objWithIdAndDataOrArrayOfIds) {
                    if(objWithIdAndDataOrArrayOfIds.hasOwnProperty(id)) {
                        const data = objWithIdAndDataOrArrayOfIds[id];
                        const index = prevCollection.findIndex(x => x.id === id);
                        if(index === -1) {
                            if(doSetOrObj) {
                                newCollection.push(data);
                            } else {
                                return reject(`Trying to update data that does not exist (collection "${collection}" ID "${id}").`);
                            }
                        } else {
                            if(doSetOrObj) {
                                newCollection[id] = {...data};
                            } else {
                                const prevData = {...prevCollection[index]};
                                newCollection[index] = {...prevData, ...data};
                            }
                        }
                        const ref = this.db.collection(collection).doc(id);
                        if(doSetOrObj) {
                            batch.set(ref, data);
                        } else {
                            batch.update(ref, data);
                        }
                    }
                }
                if(!isSecondTry) this.setState({[collection]: newCollection});

                if(onlyLocal || this.state.isDemo) {
                    // Save locally for now.
                    this.updateData();
                    return resolve();
                }

                try {
                    await batch.commit();
                    this.updateData();
                    const first = (prevCollection.find(x => x.id === Object.keys(objWithIdAndDataOrArrayOfIds)[0]) || {});
                    const projectId = collection === 'projects' ? first.id : first.projectId;
                    this.pushToOtherMembers('updateMultiple', collection, false, objWithIdAndDataOrArrayOfIds, projectId);
                    return resolve();
                } catch(e) {
                    this.setState({[collection]: prevCollection});
                    if(!isSecondTry) {
                        // Try again after refreshing user claims.
                        await this.userRefreshClaims();
                        return inner(true);
                    }
                    console.error(e.message);
                    return reject(e.message);
                }
            };

            return inner();
        });
    }
    async deleteMultiple(collection, idArray, warn = false, plural = 'items', onlyLocal = false) {
        return new Promise(async (resolve, reject) => {
            if(!Array.isArray(idArray) || !idArray.length) {
                return reject('No IDs provided to delete.');
            }
            if(warn) {
                const confirm = await warn(
                    'Are you sure?',
                    `Deleting these ${plural} are permanent and cannot be undone.`,
                    'Delete forever',
                );
                if(!confirm) {
                    return resolve(false);
                }
            }
            const prevCollection = this.state[collection] ? [...this.state[collection]] : [];
            const newCollection = this.state[collection] ? [...this.state[collection]] : [];

            const inner = async (isSecondTry = false) => {
                const batch = this.db.batch();
                for(const id of idArray) {
                    const index = newCollection.findIndex(x => x.id === id);
                    newCollection.splice(index, 1);
                    const ref = this.db.collection(collection).doc(id);
                    batch.delete(ref);
                }
                if(!isSecondTry) this.setState({[collection]: newCollection});

                if(onlyLocal || this.state.isDemo) {
                    // Save locally for now.
                    this.updateData();
                    return resolve();
                }

                try {
                    await batch.commit();
                    this.updateData();

                    const first = prevCollection.find(x => x.id === idArray[0]) || {};
                    const projectId = collection === 'projects' ? first.id : first.projectId;
                    this.pushToOtherMembers( 'deleteMultiple', collection, false, idArray, projectId);
                    return resolve(true);
                } catch(e) {
                    this.setState({[collection]: prevCollection});
                    if(!isSecondTry) {
                        // Try again after refreshing user claims.
                        await this.userRefreshClaims();
                        return inner(true);
                    }
                    console.error(e.message);
                    return reject(e.message);
                }
            };

            return inner();
        });
    }
    getUser() {
        if(!this.hasFirebase || !this.state.usersPublic || !this.state.usersPrivate) return {};
        // Combine usersPublic and usersPrivate data in 1 object for logged in user.
        const publicData = this.state.usersPublic.find(x => x.id === this.state.userId);
        const privateData = this.state.usersPrivate.find(x => x.id === this.state.userId);
        window.appTimeFormat = (privateData || {}).timeFormat || 'H:mm';
        window.appDateFormat = (privateData || {}).dateFormat || 'D M Y';
        return {...publicData, ...privateData};
    }
    setSession(obj, isDemo = this.state.isDemo) {
        return new Promise((resolve) => {
            let session = {...this.state.session, ...obj};
            this.setState({session}, () => {
                resolve();
            });
            window.sessionStorage.setItem(`${this.props.storageKey}-session${isDemo ? '-demo' : ''}`, JSON.stringify(session));
        });
    }
    clearSession(isDemo) {
        window.sessionStorage.removeItem(`${this.props.storageKey}-session${isDemo ? '-demo' : ''}`);
        this.setState({session: {}});
    }
    setLocal(obj, isDemo = this.state.isDemo) {
        let local = {...this.state.local, ...obj};
        this.setState({local});
        window.localStorage.setItem(`${this.props.storageKey}-local${isDemo ? '-demo' : ''}`, JSON.stringify(local));
    }
    clearLocal(isDemo) {
        window.localStorage.removeItem(`${this.props.storageKey}-local${isDemo ? '-demo' : ''}`);
        this.setState({local: {}});
    }
    async uploadFile(pathWithoutExtention, file, onStart = false, onUpdate = false) {
        if(this.state.isDemo) return false;
        return new Promise(async (resolve, reject) => {
            if(pathWithoutExtention[0] === '/') throw new Error('Path cannot start with a slash.');
            const ext = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
            const path = pathWithoutExtention + '.' + ext;

            const inner = async (isSecondTry = false) => {
                const uploadTask = this.storage.child(path).put(file);
                if(onStart) onStart(uploadTask);
                uploadTask.on('state_changed', snapshot => {
                    const percentage = Math.round(snapshot.bytesTransferred / snapshot.totalBytes * 100);
                    if(onUpdate) onUpdate(percentage);
                }, async error => {
                    if(!isSecondTry) {
                        // Try again after refreshing user claims.
                        await this.userRefreshClaims();
                        return inner(true);
                    }
                    console.error(error.message);
                    return reject(error.message);
                }, async () => {
                    const url = await uploadTask.snapshot.ref.getDownloadURL();
                    resolve({url, path});
                });
            }

            return inner();
        });
    }
    async signOut() {
        return this.auth.signOut();
    }
    async deleteFile(path) {
        if(this.state.isDemo) return false;
        return new Promise(async (resolve, reject) => {
            const inner = async (isSecondTry = false) => {
                try {
                    await this.storage.child(path).delete().then(() => {
                        resolve();
                    });
                } catch(e) {
                    if(!isSecondTry) {
                        // Try again after refreshing user claims.
                        await this.userRefreshClaims();
                        return inner(true);
                    }
                    console.error(e.message);
                    return reject(e.message);
                }
            };
            return inner();
        });
    }
    async addToArray(collection, id, key, data) {
        const item = this.state[collection].find(x => x.id === id);
        const newArray = item[key] ? [...item[key]] : [];
        if(typeof data === 'object') {
            newArray.push({
                id: getSemiUniqueKey(),
                ...data,
            });
        } else {
            newArray.push(data);
        }
        return this.update(collection, id, {[key]: newArray});
    }
    async toggleInArray(collection, id, key, data) {
        const item = this.state[collection].find(x => x.id === id);
        const newArray = item[key] ? [...item[key]] : [];
        const index = newArray.indexOf(data);
        if(index === -1) {
            // Add
            newArray.push(data);
        } else {
            // Delete
            newArray.splice(index, 1);
        }
        return this.update(collection, id, {[key]: newArray});
    }
    async updateInArray(collection, id, key, itemId, data) {
        const item = this.state[collection].find(x => x.id === id);
        const newArray = item[key] ? [...item[key]] : [];
        const index = newArray.findIndex(x => x.id === itemId);
        if(typeof data === 'object') {
            newArray[index] = {...newArray[index], ...data};
        } else {
            newArray[index] = data;
        }
        return this.update(collection, id, {[key]: newArray});
    }
    async deleteFromArray(collection, id, key, itemId, warn = false, e = false, singular = '') {
        return new Promise(async (resolve) => {
            const item = this.state[collection].find(x => x.id === id);
            const newArray = item[key] ? [...item[key]] : [];
            const hasEvent = e && e.clientX != null;
            const index = newArray.findIndex(x => x.id === itemId);
            if (index === -1) return false;
            if (warn) {
                const confirm = (e || {}).shiftKey || await warn(
                    'Are you sure?',
                    `Deleting this ${singular} is permanent and cannot be undone. ${hasEvent ? 'To skip this warning next time, hold shift while deleting.' : ''}`,
                    'Delete forever'
                );
                if (!confirm) return resolve(false);
            }
            newArray.splice(index, 1);
            await this.update(collection, id, {[key]: newArray});
            resolve(true);
        });
    }
    async delete(collection, id, warn = false, e = false, args = false, onlyLocal = false) {
        return new Promise(async (resolve, reject) => {
            let desc, singular, title, action;
            if(typeof args === 'string') singular = args;
            if(typeof args === 'object') {
                desc = args.desc;
                singular = args.singular;
                title = args.title;
                action = args.action;
            }
            const hasEvent = e && e.clientX != null;
            if(!id) {
                return reject('No ID provided to delete.');
            }
            if(warn) {
                const confirm = (e || {}).shiftKey || await warn(
                    title || 'Are you sure?',
                    desc || `Deleting this ${singular || ''} is permanent and cannot be undone. ${hasEvent ? 'To skip this warning next time, hold shift while deleting.' : ''}`,
                    action || 'Delete forever'
                );
                if(!confirm) {
                    return resolve(false);
                }
            }
            const index = this.state[collection].findIndex(x => x.id === id);
            if(index === -1) return resolve(); // Already deleted.
            const prevCollection = [...this.state[collection]];
            const newCollection = [...this.state[collection]];
            newCollection.splice(index, 1);
            this.setState({[collection]: newCollection});

            if(onlyLocal || this.state.isDemo) {
                // Save locally for now.
                return resolve(true);
            }

            const inner = async (isSecondTry = false) => {
                try {
                    await this.db.collection(collection).doc(id).delete();
                    this.updateData();
                    const projectId = collection === 'projects' ? prevCollection[index].id : prevCollection[index].projectId;
                    this.pushToOtherMembers( 'delete', collection, id, false, projectId);
                    return resolve(true);
                } catch(e) {
                    this.setState({[collection]: prevCollection});
                    if(!isSecondTry) {
                        // Try again after refreshing user claims.
                        await this.userRefreshClaims();
                        return inner(true);
                    }
                    console.error(e.message);
                    return reject(e.message);
                }
            };

            return inner();
        });
    }
    async update(collection, id, data, onlyLocal = false) {
        return new Promise(async (resolve, reject) => {
            const index = this.state[collection].findIndex(x => x.id === id);
            const prevCollection = [...this.state[collection]];
            const prevData = {...prevCollection[index]};
            if(index === -1) return reject();

            // Check if data is same (saves a Firestore update & rerender, e.g. on tabbing through input fields).
            const isSame = Object.keys(data).every(key => {
                const currentDoc = this.state[collection][index];
                const currentVal = currentDoc[key];
                const newVal = data[key];
                return currentVal === newVal;
            });
            if(isSame) return resolve();

            const newCollection = [...this.state[collection]];
            newCollection[index] = {...prevData, ...data};
            this.setState({[collection]: newCollection});

            if(onlyLocal || this.state.isDemo) {
                // Save locally for now.
                return resolve();
            }

            const inner = async (isSecondTry = false) => {
                try {
                    if(this.debug) console.log(`Updating ${collection} ${id}:`, data);
                    await this.db.collection(collection).doc(id).update(data);
                    this.updateData();
                    const projectId = collection === 'projects' ? id : newCollection[index].projectId;
                    this.pushToOtherMembers('update', collection, id, data, projectId);
                    return resolve();
                } catch(e) {
                    this.setState({[collection]: prevCollection});
                    if(!isSecondTry) {
                        // Try again after refreshing user claims.
                        await this.userRefreshClaims();
                        return inner(true);
                    }
                    console.error(e.message);
                    return reject(e.message);
                }
            };

            return inner();
        });
    }
    getRef(collection, args) {
        let refStr = `this.db.collection(${collection})`;
        let ref = this.db.collection(collection);
        if(args.id || args.ids) {
            const key = args.id != null ? 'id' : 'ids';
            const id = Array.isArray(args[key]) ? args[key][0] : args[key];
            ref = ref.doc(id);
            refStr += `.doc(${id})`;
        } else {
            if(args.where && Array.isArray(args.where) && args.where.length) {
                if(Array.isArray(args.where[0])) {
                    for(const query of args.where) {
                        ref = ref.where(query[0], query[1], query[2]);
                        refStr += `.where(${query[0]}, ${query[1]}, ${query[2]})`;
                    }
                } else {
                    ref = ref.where(args.where[0], args.where[1], args.where[2]);
                    refStr += `.where(${args.where[0]}, ${args.where[1]}, ${args.where[2]})`;
                }
            }
            if(args.orderBy) {
                if(Array.isArray(args.orderBy)) {
                    for(const order of args.orderBy) {
                        ref = ref.orderBy(order, args.orderByDir || "asc");
                        refStr += `.orderBy(${order}, ${args.orderByDir || "asc"})`;
                    }
                } else {
                    ref = ref.orderBy(args.orderBy, args.orderByDir || "desc");
                    refStr += `.orderBy(${args.orderBy}, ${args.orderByDir || "asc"})`;
                }
            }
            if(args.limit) {
                ref = ref.limit(args.limit);
                refStr += `.limit(${args.limit})`;
            }
        }
        if(this.debug) console.log('Created ref:', refStr);
        return ref;
    }
    render() {
        const state = {...this.state};
        return (
            <DataContext.Provider value={state}>
                {React.Children.only(this.props.children)}
            </DataContext.Provider>
        )
    }
}

export function withData(WrappedComponent) {
    return class extends React.Component {
        render() {
            return (
                <DataContext.Consumer>
                    {data => <WrappedComponent {...data} {...this.props} />}
                </DataContext.Consumer>
            )
        }
    }
}

withData.contextType = DataContext;

DataProvider.propTypes = {
    saveLocally: PropTypes.bool,
    storageKey: PropTypes.string,
    load: PropTypes.array,
};