import React from 'react';

class Status extends React.Component {
    render() {
        return (
            <div className={`pl-2 flex items-start ${this.props.className || ''}`}>
                <div className={`${this.props.isGreen ? 'bg-green-400 dark:bg-green-600 border-green-200 dark:border-green-800' : (this.props.isGray ? 'bg-gray-400 dark:bg-gray-600 border-gray-200 dark:border-gray-800' : 'bg-red-400 dark:bg-red-600 border-red-200 dark:border-red-800')} w-2 h-2 mt-1.5 flex-none border-2 rounded-full mr-3`} />
                <div className='text-sm'>{this.props.children}</div>
            </div>
        );
    }
}

export default Status;