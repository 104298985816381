import React from 'react';
import Icon from "./Icon";
import Shortcut from "./Shortcut";
import {isActive, formatShortcut} from '../functions';
import {NavLink, withRouter} from "react-router-dom";
import {motion} from "framer-motion";

class SidebarItem extends React.Component {
    render() {
        const active = isActive(this.props.location.pathname, this.props.to, this.props.exact, this.props.alsoActive);
        const shortcut = this.props.shortcut && formatShortcut(this.props.shortcut, true);
        return (
            <NavLink isActive={() => active} to={this.props.to} onClick={() => this.props.onOpen ? this.props.onOpen() : null} exact={this.props.exact} className={`py-2.5 px-4 select-none text-sm flex relative flex-none items-center rounded-lg ${this.props.disabled ? 'opacity-50 pointer-events-none' : ''} ${this.props.className || ''}`} activeClassName={this.props.isInline ? `bg-${window.appColor} text-white` : ''}>
                {active && !this.props.isInline ? <motion.div layoutId='active' className={`absolute inset-0 rounded-lg bg-gray-200 dark:bg-gray-700`} /> : null}
                {this.props.icon ? <Icon icon={this.props.icon} color={this.props.isInline && active ? 'white' : (this.props.color || window.appColor)} size={14} className='mr-3 relative z-10' /> : null}
                <div className='font-medium relative z-10 truncate flex mr-auto'>{this.props.children}</div>
                {shortcut && <Shortcut alsoWorksWhenInputInFocus press={this.props.shortcut} onPress={() => this.props.history.push(this.props.to)} />}
                {shortcut && shortcut.map((key, i) => <div key={i} className={`h-5 ${key.length > 1 ? 'px-1' : ''} text-xs ml-1 relative z-10 pt-0.5 text-gray-500 flex items-center justify-center border rounded`} style={{minWidth: '1.25rem'}}>{key}</div>)}
                {this.props.count != null && <div style={{top: 7}} className={`absolute z-10 ${this.props.count > 0 ? `bg-${window.appColor} dark:bg-${window.appColor} text-white` : 'bg-gray-200 dark:bg-gray-700'} pt-0.5 w-6 h-6 flex items-center justify-center right-3 rounded-full font-medium text-xs`}>{this.props.count}</div>}
                {this.props.sub != null && <div className={`absolute right-4 top-2 ${active ? 'text-white opacity-70' : 'text-gray-500'}`}>{this.props.sub}</div>}
            </NavLink>
        );
    }
}

export default withRouter(SidebarItem);