import React from 'react';
import DropdownItem from "./DropdownItem";
import Dropdown from "./Dropdown";
import Shortcut from "./Shortcut";
import State from "./State";
import 'pikaday/css/pikaday.css';
import PropTypes from 'prop-types';

class InputDocumentDropdown extends React.Component {
    constructor(props) {
        super(props);
        const hasDesc = this.props.options.some(x => x.desc);
        const approxHeight = this.props.maxSuggestions * (hasDesc ? 50 : 32) + 30;
        this.state = {
            index: this.props.value ? 0 : -1,
            approxHeight,
        };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.value && this.props.value && this.state.index === -1) {
            this.setState({index: 0});
        } else if(prevProps.value && !this.props.value && this.state.index > -1) {
            this.setState({index: -1});
        } else if(prevProps.value !== this.props.value) {
            this.setState({index: 0});
        }
    }
    setDocument(id, forceBlur = false) {
        if(this.props.multiple) {
            this.props.onSelect(id || null);
            this.setState({index: -1});
            if(!forceBlur) this.props.onBlur(null);
        } else {
            this.props.onBlur(id || null, forceBlur);
        }
    }
    handleArrow(e, direction, options) {
        e.preventDefault();
        let index;
        if(this.state.index === -1) {
            if(direction === 'up') {
                index = options.length - 1;
            } else if(direction === 'down') {
                index = 0;
            }
        } else {
            if(direction === 'up') {
                index = Math.max(this.props.value ? 0 : -1, this.state.index - 1);
            } else if(direction === 'down') {
                if(!this.props.value && this.state.index + 1 === options.length) {
                    index = -1;
                } else {
                    index = Math.min(options.length - 1, this.state.index + 1);
                }
            }
        }
        this.setState({index});
    }
    render() {
        const value = this.props.value.toLowerCase();
        const options = (this.props.options || []).filter(x => {
            const name = x.name ? x.name.toLowerCase() : '';
            const desc = x.desc ? x.desc.toLowerCase() : '';
            const isActive = this.props.valueIds.indexOf(x.id) > -1;
            return !isActive && (name.includes(value) || desc.includes(value));
        });
        options.slice(0, this.props.maxSuggestions);
        const numOptions = (this.props.options || []).length - this.props.valueIds.length;

        return (
            <div className={`${!options.length && !this.props.value ? 'opacity-0' : 'opacity-1'}`}>
                <Dropdown approxHeight={this.state.approxHeight} isWithInput arrowPosition={this.props.arrowPosition || 'left'} offset={this.props.offset} onOpen={() => options[this.state.index] ? this.setDocument(options[this.state.index].id, false) : this.props.onClose()} width={258} hasPadding={false} isOpen style={{position: 'absolute', left: 0, bottom: 0}}>
                    <Shortcut alsoWorksWhenInputInFocus press='ArrowUp' onPress={(e) => this.handleArrow(e, 'up', options)} />
                    <Shortcut alsoWorksWhenInputInFocus press='ArrowDown' onPress={(e) => this.handleArrow(e, 'down', options)} />
                    <Shortcut alsoWorksWhenInputInFocus press='Enter' onPress={() => options[this.state.index] ? this.setDocument(options[this.state.index].id, true) : this.props.onClose()} />
                    <Shortcut alsoWorksWhenInputInFocus press='Tab' onPress={() => options[this.state.index] ? this.setDocument(options[this.state.index].id, false) : this.props.onClose()} />
                    {options.length ? (
                        <div className='p-2'>
                            {options.map((x,i) => {
                                return <DropdownItem desc={x.desc} onClick={() => this.setDocument(x.id, true, numOptions)} sub={x.sub} autoClose={false} key={x.id} isHover={this.state.index === i} icon={x.icon}>{x.name || 'Unnamed'}</DropdownItem>
                            })}
                        </div>
                    ) : <div className='h-32'><State iconSize={20} icon='Alerts.InterfaceAlertWarningCircle'>
                        <p className='sub text-center mt-3 px-5'>{this.props.valueIds.length === this.props.options.length ? 'No more options' : 'No results'}</p>
                    </State></div>}
                </Dropdown>
            </div>
        );
    }
}

InputDocumentDropdown.propTypes = {
    onBlur: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    maxSuggestions: PropTypes.number,
}

InputDocumentDropdown.defaultProps = {
    maxSuggestions: 5,
}

export default InputDocumentDropdown;