import React from 'react';
import Icon from "./Icon";

class Step extends React.Component {
    render() {
        const isActive = this.props.isActive;
        return (
            <div className={`relative rounded-lg flex-auto`}>
                <div className={`h-1 rounded-lg ${this.props.isDone ? 'bg-gray-100 dark:bg-gray-700' : 'bg-gray-200 dark:bg-gray-600'}`}>
                    {isActive ? <div className={`tr h-1 rounded-lg bg-${window.appColor}`} style={{width: `${this.props.percentage == null ? 100 : this.props.percentage}%`}} /> : null}
                </div>
                <h6 className={`mt-3 mb-2 ${this.props.isDone ? 'text-gray-400 dark:text-gray-500' : ''}`}>Step {this.props.number}{isActive && this.props.desc ? <span className={`text-${window.appColor}`}> · {this.props.desc}</span> : ''}</h6>
                <div className='flex items-center'>
                    <h5 className={this.props.isDone ? 'text-gray-400 dark:text-gray-500' : ''}>{this.props.children}</h5>
                    {this.props.isDone ? <Icon icon='Validation.InterfaceValidationCheck' size={12} color='gray-400' colorDark='gray-600' className='ml-2' /> : null}
                </div>
            </div>
        );
    }
}

export default Step;