import React from 'react';
import Input from "./Input";
import PropTypes from "prop-types";
import {capitalize, getSemiUniqueKey} from "../functions";

class InputGroup extends React.Component {
    constructor(props) {
        super(props);
        this.id = getSemiUniqueKey();
    }
    render() {
        return (<div className={`${this.props.hasBorder ? 'border rounded-lg' : ''} ${this.props.className || ''}`}>
            {this.props.fields.filter(x => x).map((field, i) => {
                if(!field) return null;
                let className = 'bg-white dark:bg-gray-800 flex relative items-start';
                let classNameFocus = this.props.hasBorder ? `z-10 ring-1 ring-${window.appColor}` : `border-${window.appColor} dark:border-${window.appColor}`;
                if(i === 0) className += ' rounded-t-lg';
                if(i > 0 && this.props.hasBorder) className += ' border-t border-light dark:border-gray-700';
                if(!this.props.hasBorder) className += ' border-b border-light dark:border-gray-700';
                if(i === this.props.fields.length-1 && this.props.hasBorder) className += ' rounded-b-lg';
                if(field.className) className += ` ${field.className}`;
                const key = typeof field === 'object' ? field.key : field;
                return <Input
                    key={`inputgroup-${this.id}-${i}`}
                    isGroup
                    align={field.align}
                    classNameInput={field.classNameInput}
                    className={className}
                    classNameFocus={classNameFocus}
                    noStyling
                    min={field.min}
                    max={field.max}
                    noSidePadding={!this.props.hasBorder}
                    noPadding={this.props.noPadding || field.noPadding}
                    type={field.type}
                    onChange={data => this.props.onChange ? this.props.onChange({[key]: data}) : null}
                    onBlur={data => this.props.onBlur ? this.props.onBlur({[key]: data}) : null}
                    defaultValue={(this.props.doc || {})[key] || field.defaultValue || (this.props.doc ? this.props.doc[key] : '')}
                    value={field.type === 'boolean' ? (this.props.doc ? this.props.doc[key] : undefined) : undefined}
                    after={field.after}
                    button={field.button}
                    canClear={field.canClear == null ? this.props.canClear : field.canClear}
                    defaultIds={field.defaultIds}
                    isBeforeGray={this.props.isBeforeGray}
                    before={field.before || ((field.hideBefore || this.props.hideBefore) ? null : capitalize(key))}
                    beforeWidth={field.beforeWidth || this.props.beforeWidth}
                    onButtonClick={field.onButtonClick}
                    hasAll={field.hasAll}
                    noWeights={field.noWeights}
                    valueOnFocus={field.valueOnFocus}
                    onEnter={field.onEnter || this.props.onEnter}
                    hasGrayscale={field.hasGrayscale}
                    multiple={field.multiple}
                    autoFocus={field.autoFocus}
                    autosize={field.autosize}
                    rows={field.rows}
                    format={field.format || this.props.format}
                    showCalendar={field.showCalendar}
                    options={field.options}
                    required={field.required}
                    disabled={field.disabled || this.props.disabled}
                    placeholder={field.placeholder}
                    positionTop={field.positionTop || this.props.positionTop}
                    arrowPosition={field.arrowPosition || this.props.arrowPosition}
                    round={field.round}
                    readOnly={field.readOnly || this.props.readOnly}
                    shortcut={field.shortcut}
                />;
            })}
        </div>);
    }
}

InputGroup.propTypes = {
    doc: PropTypes.object,
    fields: PropTypes.array,
    className: PropTypes.string,
    onBlur: PropTypes.func,
    hideBefore: PropTypes.bool,
    positionTop: PropTypes.bool,
    beforeWidth: PropTypes.number,
};

export default InputGroup;