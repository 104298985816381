import React from 'react';
import PropTypes from 'prop-types';
import Button from "./Button";

class ButtonFile extends React.Component {
    constructor(props) {
        super(props);
        this.input = React.createRef();
    }
    handleChange(e) {
        this.props.onChange(this.props.multiple ? e.target.files : e.target.files[0]);
        e.target.value = ''; // Clear input so that it won't use this file next time.
        if(typeof this.props.closeDropdown === 'function') this.props.closeDropdown();
    }
    render() {
        const {multiple, accept, onChange, ...buttonProps} = this.props;
        return <>
            <input ref={this.input} type="file" multiple={multiple} accept={accept} style={{display: 'none'}} onChange={this.handleChange.bind(this)} />
            <Button {...buttonProps} onClick={() => this.input.current.click()} />
        </>
    }
}

ButtonFile.propTypes = {
    ...Button.propTypes,
    accept: PropTypes.string,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
}

export default ButtonFile;