import React from 'react';
import PropTypes from 'prop-types';
import Icon from "./Icon";
import {motion} from "framer-motion";

class Accordion extends React.Component {
    render() {
        return (
            <motion.div layout style={{transition: 'background-color 0.2s ease-in-out'}} className={`mt-2 w-full pb-1 border overflow-hidden ${this.props.isOpen ? 'bg-white dark:bg-gray-800' : 'h-14 border-transparent bg-gray-100 dark:bg-gray-700'} rounded-lg`}>
                <motion.div layout onClick={this.props.onClick} className='select-none cursor-pointer flex px-6 py-4'>
                    {this.props.icon && (
                        <motion.div layout className='self-center mr-4'>
                            <Icon icon={this.props.icon} size={16} />
                        </motion.div>
                    )}
                    <motion.h5 layout className='mr-auto truncate'>{this.props.title}</motion.h5>
                    <motion.div layout style={{height: 14}} className={`tr transform mt-1 ${this.props.isOpen ? 'rotate-180' : ''}`}>
                        <Icon icon='chevron-down' size={14} />
                    </motion.div>
                </motion.div>
                <motion.div layout='position' className='px-6 pb-3'>
                    {this.props.children}
                </motion.div>
            </motion.div>
        );
    }
}

Accordion.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default Accordion;