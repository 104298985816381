import React from 'react';
import PropTypes from "prop-types";

class Loader extends React.Component {
    render() {
        return (
            <div className={`${this.props.fullScreen || this.props.center ? `flex items-center justify-center ${this.props.fullScreen ? 'h-full' : ''}` : ''} ${this.props.className || ''}`}>
                <div className="loader">
                    <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg"
                         width={this.props.size} height={this.props.size} viewBox="0 0 50 50" style={{enableBackground: 'new 0 0 50 50'}}>
                        <path fill="currentColor" className={this.props.color ? `text-${this.props.color} dark:text-${this.props.colorDark}` : (this.props.fullScreen || this.props.isColor ? `text-${window.appColor || 'gray-300 dark:text-gray-500'}` : 'text-gray-800 dark:text-gray-300')}
                              d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
                            <animateTransform attributeType="xml"
                                              attributeName="transform"
                                              type="rotate"
                                              from="0 25 25"
                                              to="360 25 25"
                                              dur="0.6s"
                                              repeatCount="indefinite"/>
                        </path>
                    </svg>
                </div>
            </div>
        );
    }
}

Loader.propTypes = {
    className: PropTypes.string,
    color: PropTypes.any,
    colorDark: PropTypes.string,
    center: PropTypes.bool,
    fullScreen: PropTypes.bool,
    size: PropTypes.number,
};

Loader.defaultProps = {
    size: 56,
};

export default Loader;