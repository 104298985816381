import React from 'react';
import {Page, Icon, withData} from 'lego';

class RootError extends React.Component {
    render() {
        return (
            <Page
                title='Please check your link'
                noBar
            >
                <div className='h-full flex items-center text-center justify-center'>
                    <div className='animate-open-bottom'>
                        <Icon icon='Edit.InterfaceEditAttachment1' color='indigo-500' />
                        <h1 className='mt-5 h3'>Please check your link</h1>
                        <p className='mt-5 max-w-xs'>It looks like you forgot to include the last part of the link, containing the form code.</p>
                    </div>
                </div>
            </Page>
        );
    }
}

export default withData(RootError);