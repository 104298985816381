import React from 'react';
import Button from "../Button";
import Input from "../Input";
import InputGroup from "../InputGroup";
import Page from "../Page";
import {withData} from "../../data";
import {withNotify} from "../../notify";
import firebase from 'firebase/app';
import 'firebase/auth';

class PopoverUpdatePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPass: '',
            newPass1: '',
            newPass2: '',
        };
    }
    async finalize() {
        if(this.state.currentPass.length === 0) {
            return this.props.error('Please enter your current password');
        }
        if(this.state.newPass1.length === 0) {
            return this.props.error('Please enter a new password');
        }
        if(this.state.newPass1.length < 6) {
            return this.props.error('New password is too short', 'Please set a password with more characters.');
        }
        if(this.state.newPass2.length === 0) {
            return this.props.error('Please enter your new password in both fields.');
        }
        if(this.state.newPass1 !== this.state.newPass2) {
            return this.props.error('New passwords do not match', 'Please enter the same password in both fields.');
        }
        this.setState({isLoading: true});
        const user = firebase.auth().currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(
            user.email,
            this.state.currentPass
        );
        // Try to reauthenticate
        try {
            await user.reauthenticateWithCredential(credential);
        } catch(e) {
            this.props.error('Current password is incorrect', 'Please try again.');
            return this.setState({isLoading: false});
        }
        // If that worked, update password.
        try {
            await user.updatePassword(this.state.newPass1);
        } catch(e) {
            this.props.error('Could not update password', e.message);
            return this.setState({isLoading: false});
        }
        this.props.success('Password updated');
        this.props.history.push(this.props.backTo);
    }
    render() {
        return (
            <Page
                isPopup
                width='md'
                icon='LockUnlock.InterfaceLockCircle'
                footerGrid={2}
                backTo={this.props.backTo}
                footer={<>
                    <Button isLoading={this.state.isLoading} primary onClick={this.finalize.bind(this)}>Change</Button>
                    <Button secondary to={this.props.backTo}>Cancel</Button>
                </>}
                hasPadding
                title='Change your password'
            >
                <h6 className='pb-2'>Current password</h6>
                <Input hasBorder autoFocus type='password' onEnter={this.finalize.bind(this)} value={this.state.currentPass} onChange={currentPass => this.setState({currentPass})} />
                <h6 className='mt-5 pb-2'>New password</h6>
                <InputGroup
                    doc={this.state}
                    onChange={data => this.setState(data)}
                    hasBorder
                    hideBefore
                    onEnter={this.finalize.bind(this)}
                    fields={[
                        {key: 'newPass1', type: 'password', placeholder: 'Type new password...'},
                        {key: 'newPass2', type: 'password', placeholder: 'Type new password again...'},
                    ]}
                />
            </Page>
        );
    }
}

export default withData(withNotify(PopoverUpdatePassword));