import React from 'react';
import Dropdown from "./Dropdown";
import DropdownItem from "./DropdownItem";
import PropTypes from 'prop-types';
import Checkbox from "./Checkbox";
import Button from "./Button";
import Icon from "./Icon";
import Label from "./Label";
import {toggleInArray} from "../functions";

class Labels extends React.Component {
    render() {
        const labels = this.props.labels || [];
        const existingLabelIds = (this.props.labelIds || []).filter(labelId => this.props.labels.some(x => x.id === labelId));
        const numOfLabels = existingLabelIds.length;
        const isEditable = !!this.props.onChange;
        if(numOfLabels === 0 && !isEditable) return null;
        const labelIds = existingLabelIds.sort((a,b) => {
            const labelA = labels.findIndex(x => x.id === a) || 0;
            const labelB = labels.findIndex(x => x.id === b) || 0;
            return labelA > labelB ? 1 : (labelA < labelB ? -1 : 0);
        });
        const labelComponents = labelIds.map((labelId, i) => {
            const label = this.props.labels.find(x => x.id === labelId);
            const isLast = i === labelIds.length - 1;
            if(!label) return null;
            return (
                <Label isSmall={this.props.isSmall} key={labelId} className={`${isLast ? '' : (this.props.isSmall ? 'mr-1' : 'mr-2')} ${this.props.multiline ? 'mb-2' : 'truncate'}`} color={label.color || 'gray'}>{label.name}</Label>
            );
        });
        return <div className={`${this.props.multiline ? '' : 'overflow-hidden whitespace-nowrap'} ${this.props.align === 'right' ? 'text-right' : ''} ${this.props.className || ''}`}>
            {isEditable ? <Dropdown isWithInput offset={-72} arrowPosition={this.props.arrowPosition} button={<div style={{padding: 4}} className='flex rounded-full hover:border-gray-100 dark:hover:border-gray-700 border-transparent border pushable-sm'>
                {numOfLabels === 0 && <Button title='Select labels' secondary icon='add' className={`rounded-full ${numOfLabels > 0 ? 'mr-2' : ''}`} style={{top: 1}} isSmall>Add label</Button>}
                {numOfLabels > 0 && <Icon icon='label' size={12} color='gray-600' colorDark='gray-400' className='ml-2 mr-3 self-center' />}
                {numOfLabels > 0 ? labelComponents : (isEditable || !this.props.showDashIfEmpty ? null : <span className='text-gray-300 dark:text-gray-500'>—</span>)}
            </div>}>
                {labels.length ? labels.map(label => (
                    <Checkbox color={label.color} key={`edit-${label.id}`} onChange={x => this.props.onChange(toggleInArray(label.id, this.props.labelIds, x))} isActive={(this.props.labelIds || []).includes(label.id)} classNameChildren='flex items-center'>{label.name}</Checkbox>
                )) : <DropdownItem disabled>No labels</DropdownItem>}
                {!!this.props.onNew ? <DropdownItem icon='add' borderTop onClick={() => this.props.onNew()}>New label</DropdownItem> : null}
                {this.props.toEdit ? <DropdownItem icon='edit' borderTop={!this.props.onNew} to={this.props.toEdit}>Edit labels</DropdownItem> : null}
            </Dropdown> : (numOfLabels > 0 ? labelComponents : isEditable || !this.props.showDashIfEmpty ? null : <span className='text-gray-300 dark:text-gray-500'>—</span>)}
        </div>
    }
}

Labels.defaultProps = {
    arrowPosition: 'center',
};

Labels.propTypes = {
    onChange: PropTypes.func,
    toEdit: PropTypes.string,
    multiline: PropTypes.bool,
    isSmall: PropTypes.bool,
    showDashIfEmpty: PropTypes.bool,
    labels: PropTypes.array,
    labelIds: PropTypes.array,
    arrowPosition: PropTypes.string,
}

/*
recalcTags() {
        if(this.props.children.length) {
            setTimeout(() => {
                const tagRefs = this.tagRefs.reverse();
                const fullWidth = parseInt(this.tagRefs.map(x => x.width).reduce((sum, x) => sum + x + 4, 0) - 4);
                const actualWidth = this.ref.current.clientWidth;
                let difference = fullWidth - actualWidth;
                let numberOfTagsToShow = this.props.children.length;
                if(difference > 0) {
                    difference += 24; // To make room for +1 after
                    for(const tagRef of tagRefs) {
                        difference -= tagRef.width;
                        numberOfTagsToShow--;
                        if(difference <= 0) break;
                    }
                }
                this.setState({numberOfTagsToShow, isLoaded: true});
            }, 50);
        }
    }
 */

export default Labels;