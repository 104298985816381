import React from 'react';
import PropTypes from 'prop-types';
import {format, secondsToTime, formatBytes} from '../functions';

class Meter extends React.Component {
    render() {
        const {isTime, isSize} = this.props;
        const percentage = parseFloat(this.props.current || 0) / parseFloat(this.props.max || 1) * 100;
        return (
            <div className='mt-2'>
                <div className='h-2 flex-1 overflow-hidden relative rounded bg-gray-100 dark:bg-gray-900'>
                    <div className={`bg-${this.props.color}-500 absolute h-2 rounded`} style={{width: `${percentage}%`}} />
                </div>
                <div className='flex mt-2'>
                    <p className={`text-sm text-gray-500`}>{isTime ? secondsToTime(this.props.current || 0, true) : (isSize ? formatBytes(this.props.current || 0) : (this.props.current || 0).toFixed(this.props.digits || 0))} of {isTime ? secondsToTime(this.props.max || 0, true) : (isSize ? formatBytes(this.props.max || 0) : format('number', this.props.max || 0))} {this.props.after}</p>
                </div>
            </div>
        );
    }
}

Meter.propTypes = {
    color: PropTypes.string,
    current: PropTypes.number,
    max: PropTypes.number,
    after: PropTypes.string,
    isTime: PropTypes.bool,
}

export default Meter;