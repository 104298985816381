import React from 'react';
import Button from "../Button";
import Callout from "../Callout";
import Icon from "../Icon";
import Input from "../Input";
import Page from "../Page";
import Tabs from "../Tabs";
import Tab from "../Tab";

class PopoverImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isImageLoading: false,
            isImageTooSmall: false,
            tab: 'unsplash',
            unsplashValue: '',
            linkValue: '',
        };
    }
    async findImage(e) {
        e.preventDefault();
        this.setState({isImageLoading: true});
        const res = await fetch(`https://source.unsplash.com/1600x900/?${this.state.unsplashValue.split(' ').join(',')}`);
        if(res.url) {
            this.props.onChange(res.url);
        }
        this.setState({isImageLoading: false, isImageTooSmall: false});
    }
    setLink(e) {
        e.preventDefault();
        const link = this.state.linkValue;
        if(!link) {
            this.props.onChange(null);
            this.setState({isError: false, isImageTooSmall: false});
            return;
        }
        if(this.props.minWidth && this.props.minHeight) {
            const img = new Image();
            const that = this;
            img.onload = function() {
                that.setState({isError: false, isImageTooSmall: this.width < that.props.minWidth || this.height < that.props.minHeight});
                that.props.onChange(link);
            }
            img.onerror = () => {
                this.setState({isError: true, isImageTooSmall: false});
                this.props.onChange(null);
            }
            img.src = link;
        } else {
            this.props.onChange(link);
        }
    }
    render() {
        return (
            <Page
                isPopup
                width='xl'
                title='Add image'
                height={585}
                backTo={this.props.backTo}
            >
                <div className='flex flex-col h-full p-10 pt-0'>
                    <div className='flex-1'>
                        <div className='bg-gray-100 dark:bg-gray-700 mb-6 h-80 rounded-lg flex items-center justify-center bg-center bg-cover' style={{backgroundImage: `url(${this.props.image})`}}>
                            {this.props.image ? null : <Icon icon='Pictures.ImagePictureGallery' color='gray-400' />}
                        </div>
                        <Tabs marginSide='0' margin='6' className='mb-5'>
                            <Tab isActive={this.state.tab === 'unsplash'} onClick={() => this.setState({tab: 'unsplash'})}>Find on Unsplash</Tab>
                            <Tab isActive={this.state.tab === 'link'} onClick={() => this.setState({tab: 'link'})}>From link</Tab>
                        </Tabs>

                        {this.state.tab === 'unsplash' ? <>
                            <form key='unsplash' onSubmit={this.findImage.bind(this)} className='flex'>
                                <Input className='flex-1 mr-2' icon='Search.InterfaceSearch' hasBorder placeholder='Search on Unsplash.com...' value={this.state.unsplashValue} onChange={x => this.setState({unsplashValue: x})} />
                                <Button type='submit' disabled={!this.state.unsplashValue} primary>Search</Button>
                            </form>
                            <p className='sub mt-3'>You can search multiple times to get different images.</p>
                        </> : <>
                            <form key='link' onSubmit={this.setLink.bind(this)} className='flex'>
                                <Input className='flex-1 mr-2' defaultValue={this.props.image && (this.props.image.includes('unsplash') || this.props.image.includes('firebasestorage')) ? '' : this.props.image} icon='LinkUnlink.InterfaceLink' value={this.state.linkValue} onChange={x => this.setState({linkValue: x})} hasBorder placeholder='Paste an image link...' />
                                <Button type='submit' disabled={!this.state.linkValue} primary>Confirm</Button>
                            </form>
                            {this.state.isError ? <Callout isWarning className='mt-2'>This link is invalid.</Callout> : (this.state.isImageTooSmall ? <Callout isWarning className='mt-2'>Use an image of at least 1280 x 960 pixels for the best results.</Callout> : null)}
                        </>}
                    </div>
                    <Button className='w-full flex-none' secondary to={this.props.backTo}>Done</Button>
                </div>
            </Page>
        );
    }
}

export default PopoverImage;