import React from 'react';
import {withData} from "../data";
import {stripCharacters} from "../functions";
import Icon from './Icon';
import {motion} from 'framer-motion';

class SidebarGroup extends React.Component {
    render() {
        const key = this.props.title ? `hideChildren${encodeURI(stripCharacters(this.props.title))}` : false;
        const showChildren = key ? !this.props.local[key] : true;
        const isOpen = this.props.isOpen;
        return this.props.isLayered ? (
            <motion.div onClick={() => this.props.onOpen()} layout='position' className={`${isOpen ? 'bg-gray-100 dark:bg-gray-800 my-4' : `${this.props.marginTop ? 'mt-2.5' : ''}`} cursor-pointer rounded-lg`}>
                {isOpen ? this.props.children : this.props.children[0]}
            </motion.div>
        ) : (
            <>
                <div className='flex flex-none select-none relative z-10 pt-6 pb-2 px-3 items-center group'>
                    <h6 className='tr inline-block truncate'>{this.props.title}</h6>
                    {this.props.icon && <Icon title={this.props.iconTitle} icon={this.props.icon} size={12} color='gray-500' className='ml-2' />}
                    <div onClick={() => key ? this.props.setLocal({[key]: !this.props.local[key]}) : null} className='font-medium ml-auto text-gray-500 text-sm -mt-px hover:text-gray-700 opacity-0 tr group-hover:opacity-100 cursor-pointer'>{showChildren ? 'Hide' : 'Show'}</div>
                </div>
                {showChildren ? this.props.children : null}
            </>
        );
    }
}

export default withData(SidebarGroup);