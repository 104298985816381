import React from 'react';
import Icon from "./Icon";
import {withRouter} from 'react-router-dom';

class ButtonLink extends React.Component {
    render() {
        return (
            <div title={this.props.name} onClick={this.props.to ? () => this.props.history.push(this.props.to) : this.props.onClick} className={`h-16 rounded-lg pushable-sm self-start max-w-full cursor-pointer flex items-center border ${this.props.className || ''}`}>
                <div className='px-4'>
                    <Icon icon={this.props.icon} color={window.appColor} size={16} />
                </div>
                <div className='pr-4 font-medium text-sm truncate'>
                    {this.props.collection}
                    <div className='text-gray-500'>{this.props.name}</div>
                </div>
                <div className='ml-auto px-4'>
                    <Icon icon='Arrows.InterfaceArrowsButtonRight' size={16} color='gray-400' colorDark='gray-600' />
                </div>
            </div>
        );
    }
}


ButtonLink.defaultProps = {
    icon: 'LinkUnlink.InterfaceLink',
};

export default withRouter(ButtonLink);