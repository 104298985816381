import React from 'react';
const NotifyContext = React.createContext();

export class NotifyProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notify: this.notify.bind(this),
            success: this.success.bind(this),
            confirm: this.confirm.bind(this),
            error: this.error.bind(this),
            closeNotification: this.closeNotification.bind(this),
            warn: this.warn.bind(this),
            openModal: this.openModal.bind(this),
            closeModal: this.closeModal.bind(this),
            modalIsOpen: false,
            modalIcon: null,
            modalTitle: null,
            modalDesc: null,
            modalChild: null,
            modalAction: null,
            modalCancel: null,
            modalColor: null,
            modalOnAction: null,
            notification: '',
            notificationIcon: false,
            notificationIsVisible: false,
            notificationColor: null,
            notificationTimeout: 0,
            notificationDesc: '',
        };
    }

    // Notification

    notify({title, desc, color, icon} = {}) {
        if(this.state.notificationTimeout) clearTimeout(this.state.notificationTimeout);
        const notificationTimeout = setTimeout(() => {
            this.setState({
                notificationIsVisible: false,
                notificationTimeout: 0,
            });
        }, desc ? 7000 : 4500);
        this.setState({
            notification: title,
            notificationDesc: desc,
            notificationColor: color,
            notificationIcon: icon,
            notificationIsVisible: true,
            notificationTimeout,
        });
    }
    closeNotification() {
        if(this.state.notificationTimeout) clearTimeout(this.state.notificationTimeout);
        this.setState({notificationIsVisible: false});
    }
    success(title, desc) {
        this.notify({title, desc, icon: 'check', color: 'green-500'});
    }
    error(title, desc) {
        this.notify({title: title || 'Something went wrong', desc: desc === true ? this.props.defaultError : desc, icon: 'cross', color: 'red-500'});
    }

    // Modal

    openModal({title, desc, icon, action, color, child, cancel} = {}) {
        return new Promise( (resolve) => {
            this.setState({
                modalIsOpen: true,
                modalIcon: icon,
                modalTitle: title,
                modalDesc: desc,
                modalChild: child,
                modalAction: action,
                modalCancel: cancel || (action ? (action === 'Cancel' ? 'Dismiss' : 'Cancel') : 'OK'),
                modalColor: color || 'gray-100',
                modalOnAction: (isConfirm = false) => {
                    this.closeModal();
                    resolve(isConfirm);
                },
            });
        });
    }
    closeModal() {
        this.setState({modalIsOpen: false});
    }
    confirm(title, desc, action) {
        // Default is warning about deletion
        action = action || 'Confirm';
        title = title || 'Are you sure?';
        return this.openModal({title, desc, action});
    }
    warn(title, desc, action) {
        // Default is warning about deletion
        action = title ? (action || 'Confirm') : 'Delete forever';
        desc = title ? desc : 'Deleting this item is permanent and cannot be undone.';
        title = title || 'Are you sure?';
        return this.openModal({title, desc, action, icon: 'Alerts.InterfaceAlertWarningCircle', color: 'red-500'});
    }
    render() {
        const data = {...this.state};
        delete data.notificationTimeout;
        delete data.doNotify;
        return (
            <NotifyContext.Provider value={data}>
                {React.Children.only(this.props.children)}
            </NotifyContext.Provider>
        )
    }
}

export function withNotify(WrappedComponent) {
    return class extends React.Component {
        render() {
            return (
                <NotifyContext.Consumer>
                    {data => {
                        data = {
                            notify: data.notify,
                            success: data.success,
                            confirm: data.confirm,
                            error: data.error,
                            ask: data.ask,
                            warn: data.warn,
                            openModal: data.openModal,
                            closeModal: data.closeModal,
                        };
                        return <WrappedComponent {...data} {...this.props} />;
                    }}
                </NotifyContext.Consumer>
            )
        }
    }
}

export function NotifyRoot(WrappedComponent) {
    return class extends React.Component {
        render() {
            return (
                <NotifyContext.Consumer>
                    {data => <WrappedComponent {...data} {...this.props} />}
                </NotifyContext.Consumer>
            )
        }
    }
}

withNotify.contextType = NotifyContext;