import React from 'react';
import PropTypes from "prop-types";

class Steps extends React.Component {
    render() {
        let activeStep = this.props.activeStep;
        if(Array.isArray(activeStep)) {
            // activeStep can be an array of booleans. Find the first "false".
            activeStep = activeStep.indexOf(false);
        } else {
            // activeStep can also be a number. Turn it into an index.
            activeStep = activeStep - 1;
        }
        return (
            <div className={`grid h-20 items-center gap-4 ${this.props.className || ''}`} style={{gridTemplateColumns: `repeat(${this.props.children ? this.props.children.length : 1}, 1fr)`}}>
                {React.Children.map(this.props.children, (step, i) =>
                    React.cloneElement(step, {
                        number: i + 1,
                        percentage: this.props.percentage,
                        isDone: i < activeStep,
                        isActive: i === activeStep,
                    })
                )}
            </div>
        );
    }
}

Steps.propTypes = {
    green: PropTypes.string,
    inset: PropTypes.number,
    percentage: PropTypes.number,
    bg: PropTypes.string,
};

Steps.defaultProps = {
    inset: 50,
};

export default Steps;