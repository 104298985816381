import React from 'react';
import {parseRoute, formatData, filterData} from "../functions";
import {NavLink} from "react-router-dom";

class List extends React.Component {
    render() {
        const data = filterData(this.props.searchQuery, [], this.props.fields, this.props.data);
        if(!data.length) return <div />; // Hide component when there's no data (but keep div to not mess up grid layout)
        return (
            <div className={`overflow-scroll ${this.props.hasBorder ? `border border-light rounded-xl` : ''} ${this.props.className || ''}`}>
                {data.map((dataItem, i) => {
                    const to = parseRoute(this.props.to, dataItem);
                    const {isWidget} = this.props;
                    const fieldP = this.props.fields.find(x => x && x.key === this.props.keyPrimary) || {};
                    const primary = this.props.keyPrimary ? formatData(dataItem, fieldP, false).val : false;
                    const fieldS = this.props.fields.find(x => x && x.key === this.props.keySecondary) || {};
                    const secondary = this.props.keySecondary ? formatData(dataItem, fieldS, false).val : false;
                    const fieldT = this.props.fields.find(x => x && x.key === this.props.keyTertiary) || {};
                    const tertiary = this.props.keyTertiary ? formatData(dataItem, fieldT, false).val : false;
                    const after = typeof this.props.after === 'function' ? this.props.after(dataItem) : null;
                    const right = this.props.keyRight ? formatData(dataItem, this.props.fields.find(x => x && x.key === this.props.keyRight), true).val : false;
                    const content = <>
                        {this.props.keyPrimary ? <div className={`${fieldP.className || ''} leading-normal flex ${isWidget ? 'text-sm' : ''}`}>
                            {this.props.isWidget ? (
                                <p className={`truncate`}>{primary || `New ${this.props.singular || 'item'}`}</p>
                            ) : (
                                <h5 className={`truncate text-current`}>{primary || `New ${this.props.singular || 'item'}`}</h5>
                            )}
                            {after}
                            <p className='truncate ml-auto opacity-75 tabular'>{right}</p>
                        </div> : null}
                        {this.props.keySecondary ? <p className={`${fieldS.className || ''} leading-normal truncate ${isWidget ? 'text-sm' : ''} ${this.props.keyPrimary ? 'mt-1' : ''}`}>{secondary || (this.props.keyPrimary ? '—' : `New ${this.props.singular || 'item'}`)}</p> : null}
                        {this.props.keyTertiary ? <p className={`${fieldT.className || ''} leading-normal opacity-75 ${this.props.isSmall || this.props.isWidget ? 'text-sm' : ''} truncate ${this.props.keySecondary || this.props.keyPrimary ? 'mt-1' : ''}`}>{tertiary || (this.props.keySecondary || this.props.keyPrimary ? '—' : `New ${this.props.singular || 'item'}`)}</p> : null}
                        {i === this.props.data.length - 1 ? null : <div className={`h-px bg-gray-100 dark:bg-gray-700 inset-x-0 ${this.props.hasBorder ? '' : (this.props.isWidget ? 'mx-3 bottom-0.5' : 'mx-2')} absolute bottom-0 -mb-px`} />}
                    </>;

                    const className = `${this.props.to ? 'select-none' : ''} no-br block ${this.props.isWidget ? (this.props.isSmall ? 'p-3 mx-3' : 'p-4 px-4 mx-2') : (this.props.isSmall ? 'p-3' : 'p-4 px-4')} rounded-lg relative`;

                    return this.props.to && !this.props.disabled ? (
                        <NavLink key={dataItem.id} to={{pathname: to, state: this.props.toData}} className={`${className} ${this.props.isWidget ? 'hover:bg-gray-100 dark:hover:bg-gray-700 tr' : ''}`} activeClassName={`bg-${window.appColor} text-white`}>
                            {content}
                        </NavLink>
                    ) : (
                        <div key={dataItem.id} className={`${className} ${this.props.disabled ? 'text-gray-500' : ''} ${this.props.isActive ? `bg-${window.appColor} text-white` : ''}`}>
                            {content}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default List;