import React from 'react';
import DropdownItem from "./DropdownItem";
import Dropdown from "./Dropdown";
import Shortcut from "./Shortcut";
import State from "./State";
import 'pikaday/css/pikaday.css';
import PropTypes from 'prop-types';
import moment from 'moment';

class InputTimeDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.value !== prevProps.value) {
            this.setState({index: 0});
        }
    }
    setTime(time, forceBlur) {
        this.props.onBlur(time || null, forceBlur);
    }
    handleArrow(e, direction, options) {
        e.preventDefault();
        let index;
        if(this.state.index === -1) {
            if(direction === 'up') {
                index = options.length - 1;
            } else if(direction === 'down') {
                index = 0;
            }
        } else {
            if(direction === 'up') {
                index = Math.max(this.props.value ? 0 : -1, this.state.index - 1);
            } else if(direction === 'down') {
                if(!this.props.value && this.state.index + 1 === options.length) {
                    index = -1;
                } else {
                    index = Math.min(options.length - 1, this.state.index + 1);
                }
            }
        }
        this.setState({index});
    }
    render() {
        const value = this.props.value.toLowerCase();
        const words = value.split(' ');
        const tF = window.appTimeFormat || 'H:mm';
        const is12 = tF === 'h:mm a';
        const hF = is12 ? 'h' : 'H';
        const Hmm = is12 ? 'hmm' : 'Hmm';
        const wordMeridiem = is12 ? words.find(x => ['a', 'p', 'am', 'pm'].includes(x)) : '';

        const options = [];

        if(value.startsWith('no'.substring(0, value.length))) {
            const mNoon = moment().hours(12).minutes(0);
            if(value.startsWith('now'.substring(0,value.length))) options.push({text: 'Now', sub: moment().format(tF), time: moment().startOf('minute').format(tF)})
            if(value.startsWith('noon'.substring(0,value.length))) options.push({text: 'Noon', sub: mNoon.format(tF), time: mNoon.startOf('minute').format(tF)})
        } else if(value.startsWith('in')) {
            const time = words[1] || '1';
            const wordPeriod = words[2] || '';
            const mMin = moment().startOf('minute').add(time, 'minutes');
            const mHour = moment().startOf('hour').add(time, 'hours');
            if(wordPeriod.startsWith('minutes'.substring(0,wordPeriod.length))) options.push({text: `In ${time} minute${time === '1' ? '' : 's'}`, sub: mMin.format(tF), time: mMin.startOf('minute').format(tF)});
            if(wordPeriod.startsWith('hours'.substring(0,wordPeriod.length))) options.push({text: `In ${time} hour${time === '1' ? '' : 's'}`, sub: mHour.format(tF), time: mHour.startOf('minute').format(tF)});
        } else {
            if(is12) {
                if(wordMeridiem) {
                    options.push({time: moment(value, tF).format(tF)});
                } else if(value.length < 3) {
                    const m = moment(value, hF);
                    const m2 = moment(value, Hmm);
                    const m3 = moment(value + '0', Hmm);
                    if(m.isValid()) {
                        options.push({time: m.format(tF)});
                        options.push({time: m.add(12, 'hours').format(tF)});
                    } else if(m2.isValid()) {
                        options.push({time: m2.format(tF)});
                        options.push({time: m2.add(12, 'hours').format(tF)});
                    } else if(m3.isValid()) {
                        options.push({time: m3.format(tF)});
                        options.push({time: m3.add(12, 'hours').format(tF)});
                    }
                } else if(words.length === 1) {
                    if(value.endsWith('am') || value.endsWith('a')) {
                        options.push({time: moment(value, 'h:mma').format(tF)});
                    } else if(value.endsWith('pm') || value.endsWith('p')) {
                        options.push({time: moment(value, 'h:mma').format(tF)});
                    }
                }
            }
            if(!options.length) {
                if(value.length === 1) {
                    if(moment(value, hF).isValid()) {
                        options.push({time: moment(value, hF).format(tF)});
                        options.push({time: moment(value, hF).add(1, 'hour').format(tF)});
                        options.push({time: moment(value, hF).add(2, 'hour').format(tF)});
                        options.push({time: moment(value, hF).add(3, 'hour').format(tF)});
                    }
                } else if(value.length === 2) {
                    if(moment(value, hF).isValid()) {
                        options.push({time: moment(value, hF).format(tF)});
                        options.push({time: moment(value, hF).add(15, 'minutes').format(tF)});
                        options.push({time: moment(value, hF).add(30, 'minutes').format(tF)});
                        options.push({time: moment(value, hF).add(45, 'minutes').format(tF)});
                    } else if(moment(value + '0', Hmm).isValid()) {
                        options.push({time: moment(value + '0', Hmm).format(tF)});
                    }
                } else if(value.length === 3 && !value.includes(':')) {
                    if(moment(value, Hmm).isValid()) {
                        options.push({time: moment(value, Hmm).format(tF)});
                        if(is12) options.push({time: moment(value, Hmm).add(12, 'hours').format(tF)});
                    } else if(moment(value + '0', Hmm).isValid()) {
                        options.push({time: moment(value + '0', Hmm).format(tF)});
                    }
                } else if(value.length <= 4) {
                    const m1 = moment(value + '0', tF);
                    const m2 = moment(value, tF);
                    if(m1.isValid()) {
                        options.push({time: m1.format(tF)});
                        if(is12 && m1.hour() < 12) options.push({time: m1.add(12, 'hours').format(tF)});
                    } else if(m2.isValid()) {
                        options.push({time: m2.format(tF)});
                        if(is12 && m2.hour() < 12) options.push({time: m2.add(12, 'hours').format(tF)});
                    }
                } else if(value.length <= 5) {
                    const m = moment(value, tF);
                    if(m.isValid()) {
                        options.push({time: m.format(tF)});
                        if(is12 && m.hour() < 12) options.push({time: m.add(12, 'hours').format(tF)});
                    }
                }
            }
        }
        console.log(options[this.state.index]);
        return (
            <Dropdown approxHeight={150} isWithInput positionTop={this.props.positionTop} arrowPosition={this.props.arrowPosition || 'left'} offset={this.props.offset} onOpen={this.props.onClose} width={200} hasPadding={false} isOpen style={{position: 'absolute', left: 0, bottom: 0}}>
                <Shortcut alsoWorksWhenInputInFocus press='ArrowUp' onPress={(e) => this.handleArrow(e, 'up', options)} />
                <Shortcut alsoWorksWhenInputInFocus press='ArrowDown' onPress={(e) => this.handleArrow(e, 'down', options)} />
                <Shortcut alsoWorksWhenInputInFocus press='Enter' onPress={() => options[this.state.index] ? this.setTime(options[this.state.index].time, true) : null} />
                <Shortcut alsoWorksWhenInputInFocus press='Tab' onPress={() => options[this.state.index] ? this.setTime(options[this.state.index].time) : null} />
                {options.length ? (
                    <div className='p-2'>
                        {options.map((x,i) => {
                            return <DropdownItem onClick={() => this.setTime(x.time, true)} autoClose={false} key={x.time} sub={x.sub} isHover={this.state.index === i}>{x.text || x.time}</DropdownItem>
                        })}
                    </div>
                ) : <div className='h-32'><State iconSize={20} icon='Alerts.InterfaceAlertWarningCircle'>
                    <p className='sub text-center mt-3 px-5'>Type something like <span className='text-gray-800 dark:text-gray-300 font-medium'>{is12 ? '2:15 pm' : '14:15'}</span>, <span className='text-gray-800 dark:text-gray-300 font-medium'>now</span> or <span className='text-gray-800 dark:text-gray-300 font-medium'>in 10 minutes</span>.</p>
                </State></div>}
            </Dropdown>
        );
    }
}

InputTimeDropdown.propTypes = {
    onBlur: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default InputTimeDropdown;