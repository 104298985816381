import React from 'react';
import {isActive, fName} from '../functions';
import {withData} from "../data";
import Avatar from "./Avatar";
import {NavLink, withRouter} from "react-router-dom";
import {motion} from "framer-motion";

class SidebarItemUser extends React.Component {
    render() {
        const active = isActive(this.props.location.pathname, this.props.to, this.props.exact, this.props.alsoActive);
        const user = this.props.getUser();
        return (
            <NavLink isActive={() => active} to={this.props.to} onClick={() => this.props.onOpen ? this.props.onOpen() : null} exact={this.props.exact} className={`py-2 pl-3 pr-4 select-none text-sm flex relative flex-none items-center rounded-lg ${this.props.disabled ? 'opacity-50 pointer-events-none' : ''} ${this.props.className || ''}`} activeClassName={this.props.isInline ? `bg-${window.appColorBase}-500 text-white` : ''}>
                {active && !this.props.isInline ? <motion.div layoutId='active' className={`absolute inset-0 rounded-lg bg-gray-200 dark:bg-gray-700`} /> : null}
                <Avatar size={6} id={this.props.userId} className='mr-2 z-10 relative' />
                <div className='font-medium relative z-10 truncate flex mr-auto'>{fName(user.name) || 'User'}</div>
            </NavLink>
        );
    }
}

export default withRouter(withData(SidebarItemUser));