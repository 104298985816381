import React from 'react';
import {getSemiUniqueKey, parseRoute, toggleInArray} from "../functions";
import ContextMenu from "./ContextMenu";
import SelectionArea from "./SelectionArea";
import {withRouter} from 'react-router-dom';

class ContextBoard extends React.Component {
    constructor(props) {
        super(props);
        this.id = `context-board-${getSemiUniqueKey()}`; // Create unique ID so that multiple context boards in one view will still work.
        const mosaicCols = this.props.mosaicCols > 1 && this.props.data.length > this.props.mosaicCols ? this.props.mosaicCols : false;
        this.state = {
            selectedIds: [],
            mosaicCols,
        }
        this.wrapper = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.mosaicCols !== this.props.mosaicCols || prevProps.data.length !== this.props.data.length) {
            const mosaicCols = this.props.mosaicCols > 1 && this.props.data.length > this.props.mosaicCols ? this.props.mosaicCols : false;
            this.setState({mosaicCols}, () => {
                if(this.selection) this.selection.destroy();
                this.setSelection();
            });
        }
    }
    componentDidMount() {
        window.addEventListener('mousedown', this.handleClickOutside);
        this.setSelection();
    }
    setSelection() {
        if(!this.props.canDrag) return false;

        this.selection = new SelectionArea({
            selectables: [`#${this.id} > div > div${this.state.mosaicCols ? (this.props.groupBy ? ' > div > div > div' : ' > div') : (this.props.groupBy ? ' > div > div' : '')}`],
            boundaries: [`#${this.id}`],
            singleTap: {allow: false},
        }).on('move', ({store}) => {
            if(this.props.disabled) return false;
            let selectedIds = [...this.state.selectedIds];
            for(const el of store.changed.added) {
                const id = el.dataset.id;
                if(!id) continue;
                selectedIds = toggleInArray(id, selectedIds);
            }
            for(const el of store.changed.removed) {
                const id = el.dataset.id;
                if(!id) continue;
                selectedIds = toggleInArray(id, selectedIds);
            }
            this.setSelectedIds(selectedIds);
        });
    }
    componentWillUnmount() {
        if(this.selection) this.selection.destroy();
        window.removeEventListener('mousedown', this.handleClickOutside);
    }
    setSelectedIds(ids = []) {
        if(this.props.disabled) return false;
        this.setState({selectedIds: ids});
        if(this.props.onSelect) this.props.onSelect(ids);
    }
    handleMouseDown(event) {
        if(event.shiftKey) {
            // Prevent text selection
            event.preventDefault();
        }
    }
    handleClick(id, data, event) {
        if(this.props.groupBy && this.state.mosaicCols) {
            const newData = [];
            const flat = data.flat();
            let row = 0;
            while(newData.length < flat.length) {
                for(const colArr of data) {
                    newData.push(colArr[row]);
                }
                row++;
            }
            data = newData;
        }
        if(event.shiftKey) {
            const selectedIndexes = this.state.selectedIds.map(id => data.findIndex(x => x.id === id));
            const indexOfCurrentSelected = data.findIndex(x => x.id === id);
            selectedIndexes.push(indexOfCurrentSelected);
            const selectedIds = data.slice(Math.min(...selectedIndexes), Math.max(...selectedIndexes) + 1).map(x => x.id);
            this.setSelectedIds(selectedIds);
        } else if(event.metaKey) {
            const selectedIds = toggleInArray(id, this.state.selectedIds);
            this.setSelectedIds(selectedIds);
        } else {
            this.setSelectedIds([id]);
        }
    }
    handleClickOutside(event) {
        const check = event.target.classList.contains('outside-context') || !this.wrapper.current.contains(event.target);
        if(!event.shiftKey && this.wrapper.current && check) {
            this.setSelectedIds();
        }
    }
    handleDoubleClick(dataObj) {
        if(this.props.disabled) return false;
        if(this.props.to) this.props.history.push(parseRoute(this.props.to, dataObj));
    }
    render() {
        let data = (this.props.data || []);
        if(this.props.groupBy) {
            const field = this.props.fields.find(x => x.key === this.props.groupBy);
            const newData = {};
            const noValues = [];
            for(const dataItem of data) {
                let value = !!field.calc ? field.calc(dataItem[this.props.groupBy], dataItem) : dataItem[this.props.groupBy];
                if(value) {
                    if(!newData[value]) newData[value] = [];
                    newData[value].push(dataItem);
                } else {
                    noValues.push(dataItem);
                }
            }
            if(noValues.length) newData[`No ${field.name || field.key}`] = noValues;
            data = newData;

            if(this.state.mosaicCols) {
                for(const key of Object.keys(data)) {
                    let col = 0;
                    const newDataItem = [];
                    for(const dataItem of data[key]) {
                        if(!newDataItem[col]) newDataItem[col] = [];
                        newDataItem[col].push(dataItem);
                        col++;
                        if(col === this.state.mosaicCols) col = 0;
                    }
                    data[key] = newDataItem;
                }
            }

        } else if(this.state.mosaicCols) {
            const newData = [];
            let col = 0;
            for(const dataItem of data) {
                if(!newData[col]) newData[col] = [];
                newData[col].push(dataItem);
                col++;
                if(col === this.state.mosaicCols) col = 0;
            }
            data = newData;
        }

        const inner = (items, prevIndex, key) => (
            React.Children.map(items.map((x,i) => this.props.mapData(x, i + prevIndex, this.state.selectedIds.includes(x.id))), (child, i) =>
                <ContextMenu
                    className={this.state.mosaicCols ? 'min-w-0' : 'h-full'}
                    id={items[i].id}
                    singular={this.props.singular}
                    plural={this.props.plural}
                    desc={typeof this.props.desc === 'function' ? this.props.desc(this.state.selectedIds) : this.props.desc}
                    numSelected={this.state.selectedIds.length}
                    menu={typeof this.props.contextMenu === 'function' ? this.props.contextMenu(this.state.selectedIds) : this.props.contextMenu}
                >
                    {React.cloneElement(child, {
                        ...child.props,
                        onMouseDown: e => this.handleMouseDown(e),
                        onClick: e => this.handleClick(items[i].id, (key ? data[key] : data), e),
                        onDoubleClick: () => this.handleDoubleClick(items[i]),
                        onContextMenu: e => this.state.selectedIds.length > 1 ? null : this.handleClick(items[i].id, (key ? data[key] : data), e),
                    })}
                </ContextMenu>
            )
        );
        return (
            <div id={this.id} onContextMenu={e => e.preventDefault()} className={`outside-context h-full overflow-scroll ${this.props.className || ''}`}>
                <div ref={this.wrapper} className={`outside-context ${this.props.classNameChild || ''}`}>
                    {this.props.groupBy ? Object.keys(data).sort((a,b) => {
                        // Sort group titles
                        if(a && !b) return this.props.groupByDesc ? 1 : -1;
                        if(!a && b) return this.props.groupByDesc ? -1 : 1;
                        else return this.props.groupByDesc ? b.localeCompare(a) : a.localeCompare(b);
                    }).map((key, i) => (
                        <div key={key} className={`${i > 0 ? 'pt-10 mt-10 border-t border-light' : 'pt-4'} px-10 col-span-${this.props.cols} outside-context`}>
                            <h5 className={`outside-context pb-5`}>{key}</h5>
                            <div className={`grid gap-5 grid-cols-${this.props.cols} outside-context items-start`}>
                                {this.state.mosaicCols ? [...Array(this.state.mosaicCols)].map((_, i) => {
                                    let prevIndex = 0;
                                    for(let j = 0; j < i; j++) {
                                        if(data[key][j]) prevIndex += data[key][j].length;
                                    }
                                    if(!data[key][i]) return false;
                                    return <div key={`col-${i}`} className='grid gap-5'>{inner(data[key][i], prevIndex, key)}</div>;
                                }).filter(x => x) : inner(data[key], 0, key)}
                            </div>
                        </div>
                    )) : null}

                    {this.state.mosaicCols && !this.props.groupBy ? [...Array(this.state.mosaicCols)].map((_, i) => {
                        let prevIndex = 0;
                        for(let j = 0; j < i; j++) {
                            if(data[j]) prevIndex += data[j].length;
                        }
                        if(!data[i]) return false;
                        return <div key={`col-${i}`} className='grid gap-5'>{inner(data[i], prevIndex)}</div>;
                    }).filter(x => x) : null}

                    {!this.state.mosaicCols && !this.props.groupBy ? (
                        inner(data, 0)
                    ) : null}
                </div>
            </div>
        );
    }
}

export default withRouter(ContextBoard);