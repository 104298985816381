import React from 'react';
import PropTypes from 'prop-types';
import {AnimateSharedLayout} from "framer-motion";
import queryString from "query-string";
import {withRouter} from 'react-router-dom';
import {motion} from "framer-motion";

class Accordions extends React.Component {
    constructor(props) {
        super(props);
        const qs = queryString.parse(this.props.location.search);
        this.state = {
            openId: this.props.urlKey ? (qs[this.props.urlKey] || '') : '',
        };
    }
    render() {
        return (
            <AnimateSharedLayout>
                {React.Children.map(this.props.children, (child) =>
                    !child ? null : (typeof child.type === 'function' ? React.cloneElement(child, {
                        ...child.props,
                        onClick: () => {
                            const openId = this.state.openId === child.props.id ? '' : child.props.id;
                            if(this.props.urlKey) this.props.history.replace({
                                pathname: this.props.location.pathname,
                                search: openId ? `${this.props.urlKey}=${openId}` : '',
                            });
                            this.setState({openId});
                        },
                        isOpen: this.state.openId === child.props.id,
                    }) : (child.type === 'h6' ? <motion.h6 layout className={child.props.className || ''}>{child.props.children}</motion.h6> : (
                        child.type === 'p' ? <motion.p layout className={child.props.className}>{child.props.children}</motion.p> : child
                    ))),
                )}
            </AnimateSharedLayout>
        );
    }
}

Accordions.propTypes = {
    
}

export default withRouter(Accordions);