import React from 'react';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import {format} from '../functions';

class Calendar extends React.Component {
    // NOTE: This component cannot be wrapped in HOC's like withData() - this will break navigation.
    componentDidMount() {
        this.updateTitle();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.view !== this.props.view) {
            this.api.changeView(this.getView());
            this.updateTitle();
        }
    }
    updateTitle() {
        if(!this.api) return false;

        const title = moment(this.api.getDate()).format(this.props.view === 'day' ? `${window.appDateFormat === 'M D, Y' ? 'MMMM D,' : 'D MMMM'} YYYY` : 'MMMM YYYY');
        if(title && this.props.onTitleChange) this.props.onTitleChange(title);
    }
    getView() {
        return this.props.view === 'day' ? 'timeGridDay' : (this.props.view === 'month' ? 'dayGridMonth' : 'timeGridWeek');
    }
    render() {
        const events = (this.props.events || []).map(x => {
            if(!x) return null;
            if(!x.date) throw Error(`Event in calendar does not have valid date (id: ${x.id})`);
            const start = moment(x.date, 'X');
            if(!start.isValid()) throw Error(`Event in calendar does not have valid date (id: ${x.id})`);
            if(!x.duration) throw Error(`Event in calendar does not have duration (id: ${x.id})`);
            let end = moment(x.date, 'X').add(x.duration, 'minutes');
            const obj = {
                id: x.id,
                title: x.title,
                allDay: x.isAllDay,
                start: start.toDate(),
                end: end.toDate(),
            };
            if(x.to) obj.url = x.to;
            if(x.color) obj.color = x.color;
            return obj;
        }).filter(x => x);

        const timeFormat = window.appTimeFormat || 'H:mm';

        const timeFormatObj = {
            hour: timeFormat === 'H:mm' ? '2-digit' : 'numeric',
            minute: '2-digit',
            omitZeroMinute: timeFormat !== 'H:mm',
            hour12: timeFormat !== 'H:mm',
        };

        return (
            <div className={`overflow-hidden -mr-px h-full ${this.props.isInline ? 'fcx-no-head' : 'pt-4'} ${this.props.className || ''}`}>
                <FullCalendar
                    allDaySlot={this.props.showAllDay == null ? !this.props.isInline : this.props.showAllDay}
                    ref={x => {
                        if(x) {
                            this.ref = x;
                            this.api = x.getApi();
                            this.gotoDate = (date) => {
                                x.getApi().gotoDate(date);
                                this.updateTitle();
                            }
                            this.today = () => {
                                x.getApi().today();
                                this.updateTitle();
                            }
                            this.prev = () => {
                                x.getApi().prev();
                                this.updateTitle();
                            }
                            this.next = () => {
                                x.getApi().next();
                                this.updateTitle();
                            }
                            this.getDate = () => {
                                return x.getApi().getDate();
                            }
                        }
                    }}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView={this.getView()}
                    allDayText=""
                    events={events}
                    initialDate={this.props.initialDate ? format('date', this.props.initialDate, 'moment').toDate() : null}
                    headerToolbar={false}
                    firstDay={1} // Monday
                    nowIndicator
                    selectable={!!this.props.onCreate}
                    selectMirror={!!this.props.onCreate}
                    selectMinDistance={10}
                    eventClick={x => {
                        x.jsEvent.preventDefault();
                        if(this.props.onClick && x.event.url) this.props.onClick(x.event.url);
                    }}
                    eventTimeFormat={timeFormatObj}
                    slotLabelFormat={timeFormatObj}
                    slotMinTime={`${this.props.minHour || 0}:00:00`}
                    slotMaxTime={`${this.props.maxHour ? this.props.maxHour + 1 : 24}:00:00`}
                    slotDuration='01:00:00'
                    snapDuration='00:05:00'
                    select={x => {
                        if(!this.props.onCreate) return false;
                        const start = moment(x.startStr).unix();
                        const end = moment(x.endStr).unix();
                        this.props.onCreate({start, end});
                    }}
                    height='calc(100% + 1px)'
                    expandRows
                    eventColor={this.props.eventColor}
                    views={{
                        timeGridDay: {
                            dayHeaderFormat: {
                                weekday: 'long',
                            },
                        },
                        timeGridWeek: {
                            dayHeaderFormat: {
                                weekday: 'short',
                                day: 'numeric',
                            },
                        },
                        dayGridMonth: {
                            dayHeaderFormat: {
                                weekday: 'short',
                            },
                        },
                    }}
                    weekends={this.props.showWeekends == null ? true : !!this.props.showWeekends}
                />
            </div>
        );
    }
}

Calendar.defaultProps = {
    defaultMinutes: 60,
    eventColor: '#4299E1',
};

export default Calendar;