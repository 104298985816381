import React from 'react';
import Input from "./Input";
import {deleteObjectInArray, formatCurrency, getSemiUniqueKey, capitalize, sortData, updateObjectInArray} from "../functions";
import {withData} from "../data";
import Icon from "./Icon";
import PropTypes from 'prop-types';
import ContextMenu from "./ContextMenu";
import DropdownItem from "./DropdownItem";
import State from "./State";
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

class Sheet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortBy: this.props.defaultSortBy || false, // By default, sort as array (by date added)
            sortDesc: this.props.defaultSortDesc || false,
        };
        this.id = getSemiUniqueKey();
    }
    handleSort(key) {
        if(!this.props.canSort) return false;
        const isActiveSort = this.state.sortBy === key;
        const sortDesc = isActiveSort ? !this.state.sortDesc : false;
        this.setState({
            sortBy: key,
            sortDesc,
        });
    }
    reorder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }
    onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        const data = this.reorder(this.props.data, result.source.index, result.destination.index);
        this.props.onUpdate(data);
    }
    render() {
        let fields = this.props.fields || [];
        const data = this.props.data || [];
        const sortByField = (fields.find(x => x.key === this.state.sortBy) || {});
        if(this.state.sortBy) data.sort((a,b) => sortData(a, b, sortByField, this.state.sortDesc));
        if(!fields.length) fields = [{key: 'value'}];
        let gridTemplateColumns = fields.map(x => x.size || (x.type === 'boolean' || x.type === 'time' ? '50px' : (x.type === 'labels' ? '2fr' : '1fr'))).join(' ');
        const hasTotals = this.props.showTotals && data.length && fields.some(x => x.type === 'number' || x.type === 'currency');

        const isColBefore = this.props.isNumbered || this.props.canReorder;
        if(isColBefore) gridTemplateColumns = '40px ' + gridTemplateColumns;
        if(this.props.buttons) gridTemplateColumns += ' auto';
        return (
            <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                <div className={this.props.className} style={this.props.style}>
                    {!this.props.noHead && (
                        <div className={`grid gap-px px-px flex-none tr sticky z-10 h-10 justify-between items-center`} style={{gridTemplateColumns}}>
                            {fields.map((field, colIndex) => {
                                const isActiveSort = this.state.sortBy === field.key;
                                return (<div className={`${field.align === 'right' ? 'text-right' : ''} ${isColBefore && colIndex === 0 ? 'col-span-2' : ''}`} key={field.key}>
                                    <h6 onClick={() => this.handleSort(field.key)} key={field.key} className={`whitespace-nowrap inline-block ${colIndex > 0 ? 'px-3' : 'pr-3'} ${this.props.canSort ? 'hover:text-gray-700 dark:hover:text-gray-400 cursor-pointer' : ''} select-none`} title={this.props.canSort ? `Sort by ${field.name || field.key}` : null}>
                                        <span className='truncate'>{field.name || capitalize(field.key)}</span>
                                        {this.props.canSort && isActiveSort ? <Icon icon={this.state.sortDesc ? 'Arrows.InterfaceArrowsButtonUp' : 'Arrows.InterfaceArrowsButtonDown'} size={10} className='ml-2 mt-px' /> : null}
                                    </h6>
                                </div>);
                            })}
                        </div>
                    )}
                    <Droppable isDropDisabled={!this.props.canReorder} droppableId={`droppable-${this.id}`}>
                        {(provided, snapshot) => (
                            <div className={`border relative z-30 rounded-lg ${data.length ? 'bg-gray-100 dark:bg-gray-900' : ''}`} style={{height: data.length ? `calc(${2.5 * data.length}rem + 2px${hasTotals ? ` + 2.5rem` : ''})` : 'auto'}} ref={provided.innerRef} {...provided.droppableProps}>
                                {data.length ? data.map((dataItem, rowIndex) => {
                                    if(typeof dataItem === 'string') dataItem = {id: rowIndex, value: dataItem};
                                    const buttons = this.props.buttons ? this.props.buttons(dataItem, rowIndex) : false;
                                    const isNumbered = this.props.isNumbered === true || (typeof this.props.isNumbered === 'number' && this.props.isNumbered > rowIndex);
                                    return (
                                        <Draggable key={dataItem.id} isDragDisabled={!this.props.canReorder} index={rowIndex} draggableId={`draggable-${dataItem.id}`}>
                                            {(provided, snapshot) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps}>
                                                    <ContextMenu title={capitalize(this.props.singular) || 'row'} menu={<DropdownItem isRed onClick={() => this.props.onUpdate(deleteObjectInArray(dataItem.id, data))} disabled={this.props.isDemo} icon='AddRemoveDelete.InterfaceDeleteBin2'>Delete</DropdownItem>}>
                                                        <div className={`bg-white ${rowIndex === 0 ? 'rounded-t-lg' : ''} ${rowIndex === data.length - 1 ? 'rounded-b-lg' : ''} dark:bg-gray-800 grid ring-1 ring-gray-200 dark:ring-gray-700`} style={{gridTemplateColumns}}>
                                                            {isColBefore ? <div {...provided.dragHandleProps} tabIndex="-1" className='flex outline-none items-center justify-center text-gray-500'>{isNumbered ? rowIndex + 1 : <Icon icon='Setting.InterfaceSettingMenu1' size={12} />}</div> : null}
                                                            {fields.map((field, colIndex) => {
                                                                const val = field.calc ? field.calc(dataItem[field.key], dataItem, colIndex) : dataItem[field.key];
                                                                const otherRowsEmpty = fields.reduce((count, x) => dataItem[x.key] ? count + 1 : count, 0) <= 1;
                                                                const isFirstCol = !isColBefore && colIndex === 0;
                                                                const isLastCol = colIndex === fields.length - 1;
                                                                const input = <Input
                                                                    isGroup
                                                                    after={field.after}
                                                                    arrowPosition={field.arrowPosition}
                                                                    before={field.before}
                                                                    canClear={field.canClear}
                                                                    className={`flex-1 ${isFirstCol ? '' : 'border-l'} ${field.type === 'dropdown' ? 'pr-3' : ''} ${field.type === 'document' && field.multiple ? 'px-2' : ''}`}
                                                                    classNameInput={`px-3 h-10 pb-px ${field.align === 'right' ? 'text-right' : ''} ${val ? '' : 'text-gray-500'}`}
                                                                    classNameFocus={`relative ${rowIndex === 0 && isFirstCol ? 'rounded-tl-lg' : ''} ${rowIndex === 0 && isLastCol && !buttons ? 'rounded-tr-lg' : ''} ${!this.props.showTotals && rowIndex === data.length-1 && isFirstCol ? 'rounded-bl-lg' : ''} ${!this.props.showTotals && rowIndex === data.length-1 && colIndex === fields.length-1 && !buttons ? 'rounded-br-lg' : ''} z-30 ${!buttons ? `ring-1 ring-${window.appColor}` : ''}`}
                                                                    defaultIds={field.defaultIds}
                                                                    defaultValue={!!field.calc ? null : val}
                                                                    disabled={!!field.calc || field.disabled || this.props.disabled}
                                                                    format={field.format || this.props.format}
                                                                    multiple={field.multiple}
                                                                    noStyling
                                                                    noInputStyling
                                                                    hasAll={field.hasAll}
                                                                    noWeights={field.noWeights}
                                                                    hasGrayscale={field.hasGrayscale}
                                                                    key={`${field.key}-${rowIndex}`}
                                                                    onTab={() => colIndex === fields.length - 1 && rowIndex === data.length - 1 && !!this.props.onCreate ? this.props.onCreate() : null}
                                                                    onEnter={() => document.activeElement.blur()}
                                                                    onKeyDown={(val,e) => e.key === 'Backspace' && val === '' && otherRowsEmpty ? this.props.onUpdate(deleteObjectInArray(dataItem.id, data)) : null}
                                                                    onBlur={val => {
                                                                        if(typeof data[0] === 'string') {
                                                                            let arr = [...data];
                                                                            arr[rowIndex] = val;
                                                                            this.props.onUpdate(arr);
                                                                        } else {
                                                                            this.props.onUpdate(updateObjectInArray(dataItem.id, {[field.key]: val}, data));
                                                                        }
                                                                    }}
                                                                    placeholder={field.placeholder}
                                                                    options={field.options}
                                                                    readOnly={field.readOnly || this.props.readOnly}
                                                                    value={!!field.calc ? val : null}
                                                                    type={field.type}
                                                                />;
                                                                return !!buttons && isLastCol ? (
                                                                    <div key={`${field.key}-${rowIndex}`} className='flex'>
                                                                        {input}
                                                                        <div className='p-1'>{buttons}</div>
                                                                    </div>
                                                                ) : input;
                                                            })}
                                                        </div>
                                                    </ContextMenu>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                }) : (this.props.emptyMessage ? <div className='h-20 flex items-center justify-center sub'>{this.props.emptyMessage}</div> : <div className='h-20'><State icon={this.props.icon} iconSize={20} /></div>)}
                                {hasTotals ? (
                                    <div className={`flex-none grid h-10 justify-between`} style={{gridTemplateColumns}}>
                                        {fields.map((field, colIndex) => {
                                            let sum = field.type === 'number' || field.type === 'currency' ? data.reduce((sum, obj) => sum + (field.calc ? field.calc(obj[field.key], obj) : obj[field.key]), 0) : null;
                                            if(field.type === 'currency') sum = formatCurrency(sum);
                                            if(!sum || field.showTotals === false) sum = null;
                                            return (
                                                <div key={`${field.key}-total`} className={`px-3 text-gray-500 flex whitespace-nowrap ${field.align === 'right' ? 'text-right' : ''} tabular truncate items-center ${colIndex > 0 ? 'border-l' : ''}`}>
                                                    {sum ? field.before : null}<span className='flex-1'>{sum}</span>{sum ? field.after : null}
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>
        );
    }
}

Sheet.propTypes = {
    className: PropTypes.string,
    noHead: PropTypes.bool,
    cols: PropTypes.string,
    singular: PropTypes.string,
    style: PropTypes.object,
};

Sheet.defaultProps = {
    canSort: true,
};

export default withData(Sheet);