import React from 'react';
import Icon from "./Icon";
import Button from "./Button";
import {Link} from 'react-router-dom';
import {withNotify} from "../notify";
import {withData} from "../data";

class DemoBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMounted: false,
        };
    }
    componentDidMount() {
        this.timeout = window.setTimeout(() => {
            this.setState({isMounted: true});
        }, 1000);
    }
    componentWillUnmount() {
        if(this.timeout) window.clearTimeout(this.timeout);
    }
    render() {
        if(!this.state.isMounted) return null;
        return (
            <div key='demobox-open' className={`${this.props.isLeft ? 'left-10' : (this.props.isRight ? 'right-10' : 'inset-x-0 mx-auto')} demobox animate-open pointer-events-none max-w-3xl w-full flex items-center justify-center bg-white fixed bottom-0 z-40`}>
                <div className='bg-indigo-500 shadow-2xl relative rounded-3xl my-28 pointer-events-auto p-6 pb-5 max-w-md w-full'>
                    <div className='transform select-none -rotate-6 block font-medium absolute flex items-center justify-center -left-4 -top-5 w-24 shadow h-8 text-center rounded-full bg-yellow-400 text-yellow-800'>
                        <Icon icon='Cursor.InterfaceCursorArrow1' size={14} className='mr-1.5' />
                        Demo
                    </div>
                    <p className='h5 text-white'>
                        {this.props.primary}
                        {this.props.secondary && <span className='opacity-70'> {this.props.secondary}</span>}
                    </p>
                    <div className='flex items-center mt-5'>
                        <Button isSmall noHover color='white' disabled={!this.props.back} to={this.props.back || ''} toData={this.props.backData}>&larr; Back</Button>
                        {this.props.to && <Button isSmall primary color='indigo-600' className='ml-1' to={this.props.to || ''} toData={this.props.toData}>Continue &rarr;</Button>}
                        <div style={{border: '1px solid #A5B4FC', padding: 4}} className={`rounded-xl ${this.props.to ? 'ml-auto' : 'flex-1 ml-4'}`}>
                            <Button isSmall isWhite className='shadow-sm' icon='key' full to='/buy'>Get InterviewKit</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNotify(withData(DemoBox));