import React from 'react';

class Navigation extends React.Component {
    render() {
        return (
            <div className={`h-20 flex select-none ${this.props.className || ''}`}>
                {React.Children.map(this.props.children, (navigationItem, i) =>
                    React.cloneElement(navigationItem, {
                        shortcut: this.props.shortcuts ? (i + 1).toString() : navigationItem.props.shortcut,
                    })
                )}
            </div>
        );
    }
}

export default Navigation;