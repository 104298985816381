import React from 'react';
import PropTypes from 'prop-types';
import Icon from "./Icon";

class State extends React.Component {
    render() {
        // If it has children, make it the app color, otherwise keep it gray.
        const hasChildren = this.props.title || this.props.children;
        const color = hasChildren ? window.appColor : (this.props.color || 'gray-300');
        const colorDark = hasChildren ? window.appColor : (this.props.colorDark || 'gray-600');
        return (
            <div className={`flex items-center justify-center h-full ${this.props.className}`}>
                <div className='flex flex-col items-center justify-center w-full' style={{maxWidth: this.props.maxWidth}}>
                    {this.props.img && <img src={this.props.img} className='w-full mb-2 mx-auto max-w-md' />}
                    {this.props.icon && !this.props.img ? (
                        <Icon size={this.props.iconSize} icon={this.props.icon} color={color} colorDark={colorDark} bg={this.props.bg} bgDark={this.props.bgDark} />
                    ) : null}
                    {this.props.title ? <h4 className='mt-5 mb-3 leading-snug text-center'>{this.props.title}</h4> : null}
                    {this.props.children}
                </div>
            </div>
        );
    }
}

State.propTypes = {
    color: PropTypes.string,
    colorDark: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
};

State.defaultProps = {
    icon: 'Inbox.MailInbox',
};

export default State;