import React from 'react';
import PropTypes from "prop-types";

class Radio extends React.Component {
    constructor(props) {
        super(props);
        let value = this.props.value;
        if(value == null) value = this.props.defaultValue;
        this.state = {
            value,
        };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.value !== this.props.value) {
            this.setState({value: this.props.value});
        }
    }
    render() {
        return <div className={this.props.className}>
            {React.Children.map(this.props.children, (child, i) =>
                typeof child.type === 'function' ? React.cloneElement(child, {
                    isActive: child.props.value === this.state.value,
                    disabled: this.props.disabled,
                    isSmall: this.props.isSmall,
                    hasBorder: this.props.hasBorder,
                    onClick: value => {
                        if(this.props.canClear && value === this.state.value) {
                            this.setState({value: null});
                            if(this.props.onChange) this.props.onChange(null);
                        } else {
                            this.setState({value});
                            if(this.props.onChange) this.props.onChange(value);
                        }
                    },
                }) : child,
            )}
        </div>
    }
}

Radio.propTypes = {
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    isSmall: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.any,
};

export default Radio;