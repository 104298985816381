import React from 'react';
import PropTypes from 'prop-types';
import Icon from "./Icon";
import Loader from "./Loader";

class Label extends React.Component {
    render() {
        const {color} = this.props;
        return (
            <div onClick={this.props.onClick} title={this.props.title} className={`${color ? `border-transparent text-${color}-600 dark:text-${color}-200 bg-${color}-50 dark:bg-${color}-900` : 'text-gray-800 dark:text-gray-300 bg-white dark:bg-gray-800'} ${this.props.onClick ? 'pushable' : ''} border ${this.props.isSmall ? 'px-1.5 py-0.5 rounded-lg' : 'px-4 h-8 rounded-full'} select-none text-sm font-medium relative overflow-hidden whitespace-nowrap items-center inline-flex ${this.props.className || ''}`}>
                {this.props.icon ? <Icon className={this.props.children ? (this.props.isSmall ? 'mr-1.5' : 'mr-3') : ''} icon={this.props.icon} size={12} color={color} /> : null}
                {this.props.isLoading && <Loader size={18} color='gray-600' className={this.props.children ? (this.props.isSmall ? '-ml-2 mr-1.5' : '-ml-2 mr-3') : '-mx-2'} />}
                {this.props.children}
            </div>
        );
    }
}

Label.propTypes = {
    
}

export default Label;