import React from 'react';
import PropTypes from 'prop-types';
import DropdownItem from "../DropdownItem";

class DropdownItemFile extends React.Component {
    constructor(props) {
        super(props);
        this.input = React.createRef();
    }
    handleChange(e) {
        if(this.props.disabled) return false;
        this.props.onChange(this.props.multiple ? e.target.files : e.target.files[0]);
        e.target.value = ''; // Clear input so that it won't use this file next time.
        if(typeof this.props.closeDropdown === 'function') this.props.closeDropdown();
    }
    render() {
        const {multiple, accept, disabled} = this.props;
        return <>
            <input ref={this.input} type="file" multiple={multiple} accept={accept} style={{display: 'none'}} onChange={this.handleChange.bind(this)} />
            <DropdownItem disabled={disabled} icon='Clouds.ProgrammingCloudDownload' onClick={() => this.input.current.click()}>{this.props.children}</DropdownItem>
        </>
    }
}

DropdownItemFile.propTypes = {
    ...DropdownItem.propTypes,
    accept: PropTypes.string,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
}

export default DropdownItemFile;