import React from 'react';
import Icon from "./Icon";

class SidebarMore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowing: false,
        };
    }
    render() {
        return <>
            {this.state.isShowing ? React.Children.map(this.props.children, (sidebarItem) =>
                React.cloneElement(sidebarItem, {
                    iconColor: sidebarItem.iconColor || this.props.iconColor,
                    iconColorDark: sidebarItem.iconColorDark || this.props.iconColorDark,
                })
            ) : null}
            <div onClick={() => this.setState({isShowing: !this.state.isShowing})} className={`py-3 relative z-10 cursor-pointer px-4 select-none text-sm flex flex-none items-center ${this.props.className || ''}`}>
                <Icon icon={`chevron-${this.state.isShowing ? 'up' : 'down'}`} size={14} color={window.appColor} className='mr-3' />
                <div className='font-medium'>{this.state.isShowing ? (this.props.less || 'Less') : (this.props.more || 'More')}</div>
            </div>
        </>;
    }
}

export default SidebarMore;