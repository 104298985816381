import React from 'react';
import Icon from './Icon';
import {NavLink} from "react-router-dom";
import {isActive} from "../functions";
import {motion} from "framer-motion";

class Tab extends React.Component {
    render() {
        const active = this.props.to ? isActive(window.location.pathname, this.props.to, this.props.exact, this.props.alsoActive) : this.props.isActive;
        const isOnlyIcon = this.props.icon && !this.props.children;
        const margin = isOnlyIcon ? 0 : this.props.margin;
        return (
            <div className={`${this.props.disabled ? 'pointer-events-none opacity-70' : ''} ${margin === 'auto' ? 'flex-auto' : `pr-${margin}`} ${this.props.className || ''}`}>
                {this.props.to ? (
                    <NavLink isActive={() => active} to={this.props.to} exact={this.props.exact} className={`relative h-${this.props.height} z-10 ${isOnlyIcon ? 'px-4' : ''} flex items-center ${this.props.center ? 'justify-center' : ''} ${active ? 'text-black dark:text-white' : 'text-gray-500'} tr`}>
                        {this.props.icon && <Icon icon={this.props.icon} size={14} color={active ? window.appColor : 'gray-500'} className={`transition-all ${this.props.children ? 'mr-2' : ''}`} />}
                        <span className='mt-px'>{this.props.children}</span>
                        {active && <motion.span layoutId={this.props.layoutId} className={`inset-x-0 absolute z-10 bottom-0 h-px bg-${window.appColor}`}/>}
                        {this.props.badge != null ? <span className='w-6 h-6 rounded-full mt-px bg-gray-100 dark:bg-gray-700 font-medium text-sm ml-2 flex items-center justify-center'>{Math.min(this.props.badge, 99)}</span> : null}
                    </NavLink>
                ) : (
                    <div onClick={this.props.onClick} className={`relative cursor-pointer h-${this.props.height} z-10 ${isOnlyIcon ? 'px-4' : ''} flex items-center ${this.props.center ? 'justify-center' : ''} ${active ? 'text-black dark:text-white' : 'text-gray-500'} tr`}>
                        {this.props.icon && <Icon icon={this.props.icon} size={14} color={active ? window.appColor : 'gray-500'} className={`transition-all ${this.props.children ? 'mr-2' : ''}`} />}
                        <span className='mt-px'>{this.props.children}</span>
                        {active && <motion.span layoutId={this.props.layoutId} className={`inset-x-0 absolute z-10 bottom-0 h-px bg-${window.appColor}`}/>}
                        {this.props.badge != null ? <span className='w-6 h-6 rounded-full mt-px bg-gray-100 dark:bg-gray-700 font-medium text-sm ml-2 flex items-center justify-center'>{Math.min(this.props.badge, 99)}</span> : null}
                    </div>
                )}
            </div>
        );
    }
}

export default Tab;