import React from 'react';
import {Route, Switch} from "react-router-dom";
import {Root} from 'lego';
import './index.css';
import RootError from "./routes/RootError";
import Form from "./routes/Form";

class App extends React.Component {
	componentDidMount() {
		window.onerror = async function () {
			// Error is likely caused by a mismatch in client cache and the updated form.
			window.localStorage.clear();
			window.sessionStorage.clear();
			window.location.reload();
		};
	}
	render() {
		return (
			<Root isMobileAllowed name='InterviewKit Form' color='indigo-500'>
				<Switch>
					<Route exact path='/' component={RootError} />
					<Route exact path='/:formId' component={Form} />
				</Switch>
			</Root>
		);
	}
}

export default App;