import React from 'react';
import Icon from "./Icon";
import Shortcut from "./Shortcut";
import {NavLink, withRouter} from "react-router-dom";

class NavigationItem extends React.Component {
    render() {
        return (
            <NavLink to={this.props.to} exact={this.props.exact} className={`text-gray-500 whitespace-nowrap flex items-center justify-center border-b flex-auto`} activeClassName='font-medium border-gray-600' activeStyle={{color: '#111111'}}>
                {this.props.icon ? <Icon size={12} icon={this.props.icon} className={`${this.props.children ? 'mr-3' : ''} -mt-px`} /> : null}
                {this.props.children}
                {this.props.shortcut ? <Shortcut press={this.props.shortcut} onPress={() => this.props.history.push(this.props.to)} /> : null}
            </NavLink>
        );
    }
}

export default withRouter(NavigationItem);