import React from 'react';
import moment from "moment";
import {withData} from './data';
const ShareContext = React.createContext();

class ShareProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pings: {},
            onlineIds: [],
        };
    }
    async componentDidMount() {
        const {project} = this.props;
        if(!project) return false;
        this.fetchUsers(project);
        if(project.memberIds.length > 1) {
            const pings = await this.props.getOnce(`projects/${this.props.project.id}/pings`);
            this.pingListener = this.props.listenTo(`projects/${this.props.project.id}/pings`, 'child_changed', false, this.updatePings.bind(this));
            this.interval = window.setInterval(() => {
                this.checkPings()
            }, 60000);
            this.setState({pings}, () => {
                this.checkPings();
            });
        }
    }
    componentWillUnmount() {
        if(this.pingListener) this.pingListener.stop();
        if(this.interval) window.clearInterval(this.interval);
    }
    updatePings(timestamp, userId) {
        const pings = {...this.state.pings};
        const onlineIds = [...this.state.onlineIds];
        pings[userId] = timestamp;
        if(!onlineIds.includes(userId)) onlineIds.push(userId);
        this.setState({pings, onlineIds});
    }
    checkPings() {
        // Check which users have not pinged in the last minute (offline)
        const onlineIds = [];
        for(const userId in this.state.pings) {
            const timestamp = this.state.pings[userId];
            const isOnline = moment(timestamp, 'X').isSameOrAfter(moment().subtract(70, 'seconds')); // 10 sec margin
            if(isOnline) {
                onlineIds.push(userId);
            }
        }
        if(!onlineIds.includes(this.props.userId)) onlineIds.push(this.props.userId); // Add self as a back-up when not added by pings
        this.setState({onlineIds});
    }
    fetchUsers(project) {
        if(project) for(const userId of project.memberIds) {
            if(!this.props.usersPublic.some(x => x.id === userId)) {
                this.props.fetch({collection: 'usersPublic', id: userId});
            }
        }
    }
    render() {
        const onlineIds = [...this.state.onlineIds];
        return (
            <ShareContext.Provider value={{onlineIds}}>
                {this.props.children}
            </ShareContext.Provider>
        )
    }
}

function withShare(WrappedComponent) {
    return class extends React.Component {
        render() {
            return (
                <ShareContext.Consumer>
                    {data => {
                        return <WrappedComponent {...data} {...this.props} />;
                    }}
                </ShareContext.Consumer>
            )
        }
    }
}


withShare.contextType = ShareContext;
ShareProvider = withData(ShareProvider);

export {withShare, ShareProvider};