import React from 'react';
import Icon from "./Icon";

class Callout extends React.Component {
    getContent() {
        if(this.props.isSuccess) {
            return {
                icon: 'Validation.InterfaceValidationCheck',
                color: 'green',

            };
        } else if(this.props.isTip) {
            return {
                icon: 'Flash.ImageFlash2',
                color: 'blue',
            };
        } else if(this.props.isWarning) {
            return {
                icon: 'Alerts.InterfaceAlertWarningCircle',
                color: 'orange',
            };
        } else if(this.props.isError) {
            return {
                icon: 'Alerts.InterfaceAlertWarningCircle',
                color: 'red',
            };
        } else {
            // Default is info
            return {
                icon: 'Help.InterfaceHelpQuestionCircle',
                // color: gray (default)
            };
        }
    }
    render() {
        // Note: using the isPulse prop, you can change the icon for a dot. Only the isSuccess callout has a pulse animation.
        const {color, icon} = this.getContent();
        return (
            <div className={`px-4 ${this.props.noBg ? '' : 'py-3'} rounded-lg flex ${color ? `text-${color}-${this.props.noBg ? 600 : 700} dark:text-${color}-${this.props.noBg ? 400 : 300} ${this.props.noBg ? '' : `bg-${color}-100 dark:bg-${color}-900`}` : (this.props.noBg ? '' : 'bg-gray-100 dark:bg-gray-700')} ${this.props.className || ''}`}>
                {this.props.isPulse ? (
                    <div style={{width: 14, height: 14}} className='flex items-center justify-center flex-none'>
                        {this.props.isSuccess ? <div className='bg-green-500 border-green-200 dark:border-green-800 flex-none animate-pulse w-2 h-2 mt-1.5 border-2 rounded-full' /> : <div className='bg-orange-600 dark:bg-orange-400 border-orange-200 dark:border-orange-800 flex-none w-2 h-2 mt-1.5 border-2 rounded-full' />}
                    </div>
                ) : (
                    <Icon icon={icon} size={this.props.isSuccess ? 12 : 14} className={`${this.props.isSuccess ? 'px-px' : ''} mt-px`} />
                )}
                <p className='ml-3 text-sm font-medium'>{this.props.children}</p>
            </div>
        );
    }
}

export default Callout;