import React from 'react';
import Icon from "./Icon";
import Button from "./Button";
import Input from "./Input";
import PropTypes from 'prop-types';
import {withNotify} from "../notify";
import {withData} from "../data";
import Shortcut from "./Shortcut";

class MessageBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isLoading: false,
            message: '',
        };
    }
    async handleSubmit() {
        if(this.state.message) {
            this.setState({isLoading: true});
            const user = (this.props.usersPublic || []).find(x => x.id === this.props.userId) || {};
            try {
                await this.props.create('messages', {
                    createdByEmail: this.props.userEmail || null,
                    createdByName: user.name || null,
                    text: this.state.message,
                    pathname: window.location.pathname,
                    userAgent: navigator.userAgent || null,
                });
                this.props.success("Sent! We'll get back to you quickly.", `You will receive a reply via email at ${this.props.userEmail}.`);
                this.setState({message: '', isOpen: false, isLoading: false});
            } catch(e) {
                this.setState({isLoading: false});
                this.props.error('Could not send message', 'Please try again or contact us at support@interviewkit.co');
            }
        } else {
            this.props.error('Please enter your message.');
        }
    }
    render() {
        const {isOpen} = this.state;
        return isOpen ? (
            <div key='message-open' className='animate-open fixed right-5 bottom-5 rounded-2xl p-5 w-72 bg-white dark:bg-gray-700 shadow-lg border border-light' style={{zIndex: 9999}}>
                <Input value={this.state.message} onChange={message => this.setState({message})} rows={6} placeholder='Anything we can improve or help you with?' noStyling noInputStyling classNameInput='bg-transparent resize-none h-full leading-relaxed' autoFocus />
                <div className='flex items-center justify-between mt-2'>
                    <Button isLoading={this.state.isLoading} isSmall primary onClick={this.handleSubmit.bind(this)}>Send ⏎</Button>
                    <Button isSmall onClick={() => this.setState({isOpen: false})}>Cancel</Button>
                    <Shortcut press='Enter' onPress={this.handleSubmit.bind(this)} alsoWorksWhenInputInFocus />
                    <Shortcut press='Escape' onPress={() => this.setState({isOpen: false})} alsoWorksWhenInputInFocus />
                </div>
            </div>
        ) : (
            <div key='message-closed' onClick={() => this.setState({isOpen: true})} className={`animate-open fixed flex right-5 bottom-5 rounded-full py-4 pl-5 pr-4 pushable bg-indigo-500 w-52`} style={{zIndex: 9999, boxShadow: '0 0 10px rgba(0,0,0,0.2)'}}>
                <Icon icon='ChatBubble.MailChatBubbleTypingOval' size={16} color='white' />
                <div className='h5 text-white ml-3 mr-auto mt-0.5'>Help & feedback</div>
            </div>
        );
    }
}

MessageBox.propTypes = {
    
}

export default withNotify(withData(MessageBox));