import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {DataProvider, NotifyProvider} from 'lego';
import {BrowserRouter} from "react-router-dom";
import firebase from "firebase/app"
import * as serviceWorker from './serviceWorker';

const firebaseConfig = {
    apiKey: "AIzaSyDmOv-Ulwx-z9BEUCXE0wfOx3aqSU43hlY",
    authDomain: "interviewkit-eu.firebaseapp.com",
    databaseURL: "https://interviewkit-live.europe-west1.firebasedatabase.app",
    projectId: "interviewkit-eu",
    storageBucket: "interviewkit-eu.appspot.com",
    messagingSenderId: "354177028352",
    appId: "1:354177028352:web:ec4d812a64eb7f4ed1a3e5"
};
firebase.initializeApp(firebaseConfig);

ReactDOM.render((
    <BrowserRouter>
        <NotifyProvider>
            <DataProvider _dangerouslyUseProduction storageKey='interviewkit-form'>
                <App />
            </DataProvider>
        </NotifyProvider>
    </BrowserRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
