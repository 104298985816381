import React from 'react';
import {
    Page,
    withData,
    capitalize,
    Icon,
    State,
    Loader,
    isLocalhost,
    withNotify,
    Button,
    Input,
    Radio,
    RadioItem,
    Checkbox,
    getSemiUniqueKey,
    Shortcut,
    toggleInArray,
    format,
} from 'lego';
import moment from 'moment';

const ReactMarkdown = require('react-markdown')

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.content = React.createRef();
        this.state = {
            isStarted: false,
            isReview: false,
            isLoading: true,
            isSent: false,
            isSubmitting: false,
            startedOn: moment().unix(),
            questionIndex: 0,
            answers: [],
            image: '',
            value: '',
            ...(this.props.session || [])
        };
    }
    async componentDidMount() {
        try {
            if (this.props.match.params.formId === 'demo-form') {
                this.form = {
                    id: "demo-form",
                    hideFields: ["other-0", "other-1", "job", "gender", "email", "education", "phone"],
                    emailCreatorOnSubmit: true,
                    createdOn: moment().subtract(1, 'days').set('hours', 9).set('minutes', 54).unix(),
                    duration: 45,
                    timeFormat: "H:mm",
                    questions: [
                        {title: "What is your name?", desc: "", type: "name", id: "1592220405592-5921"},
                        {type: "email", title: "Wat is your email address?", desc: "", id: "1592220406615-25929"},
                        {title: "How old are you?", type: "age", id: "1592220407525-78868"},
                        {type: "phone", title: "What is your phone number?", id: "1592220409332-44978"},
                        {
                            title: "How often do you drink coffee?",
                            options: ['Less than daily', 'About 1 cup a day', 'About 2 cups a day', '3 or more cups a day'],
                            id: "1592220411356-28043",
                            type: "single-choice"
                        },
                        {
                            title: "What types of coffee do you drink?",
                            options: ['Espresso', 'Doppio', 'Lungo', 'Americano', 'Cappuccino', 'Latte Macchiato'],
                            id: "1592220411356-28044",
                            type: "multiple-choice"
                        },
                        {type: "availability", title: "When are you available?", id: "1592220427190-43810"}
                    ],
                    dateFormat: "D M Y",
                    image: 'https://images.unsplash.com/photo-1500353391678-d7b57979d6d2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=60',
                    projectId: "demo-project",
                    confirmationMessage: "We have received your response!",
                    createdBy: "demo-user",
                    accentColor: "yellow",
                    isLive: true,
                    desc: "We have produced some new coffees and can't wait to share them with you! ☕️\n\nIn a 30-minute coffee tasting, we will ask you to taste our different types of coffee and rate them on a few characteristics.\n\nIf you'd like to participate, please fill in this short form. We will get back to you within 24 hours to confirm the date and time of your tasting.\n\nThanks in advance!\n\n**Denise Demo**\n*Barista at Lungo Forte Inc.*",
                    title: "Taste our coffees!",
                    dates: [
                        moment().startOf('day').unix(),
                        moment().startOf('day').add(1, 'day').unix(),
                    ],
                    hasDesc: false,
                };
            } else {
                await this.props.fetch({collection: 'forms', id: this.props.match.params.formId, forceAnonymous: true});
                this.form = (this.props.forms || []).find(x => x.id === this.props.match.params.formId);
            }
            const form = this.form;
            if (!form || !form.questions || !form.questions.length) this.setState({isLoading: false});

            const image = form?.image || 'https://images.unsplash.com/photo-1508614999368-9260051292e5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1280&q=80';
            const bgImage = new Image();
            bgImage.onload = () => {
                this.setState({isLoading: false, image});
            }
            bgImage.src = image;

            window.appTimeFormat = form?.timeFormat || 'H:mm';
            window.appDateFormat = form?.dateFormat || 'D M Y';
        } catch (e) {
            if(isLocalhost()) console.error(e);
            this.setState({isLoading: false});
        }
    }

    updateSession() {
        if (!this.state.isSent) {
            this.props.setSession({
                isStarted: this.state.isStarted,
                isReview: this.state.isReview,
                questionIndex: this.state.questionIndex,
                answers: this.state.answers,
                value: this.state.value,
            });
        }
    }

    goToIndex(questionIndex) {
        const value = this.state.answers[questionIndex] || '';
        this.setState({value, questionIndex, isStarted: true, isReview: false});
    }

    prev() {
        let questionIndex = this.state.questionIndex;
        let isStarted = this.state.isStarted;
        let isReview = this.state.isReview;
        if(isReview) {
            isReview = false;
            questionIndex = this.state.answers.length - 1;
        } else if (questionIndex === 0) {
            isStarted = false;
        } else {
            questionIndex -= 1;
        }
        const value = this.state.answers[questionIndex] || '';
        this.setState({value, questionIndex, isStarted, isReview}, () => this.updateSession());
    }

    async submit(form) {
        const data = {
            formId: form.id,
            projectId: form.projectId,
            createdOn: moment().unix(),
            startedOn: this.state.startedOn,
        };

        return new Promise(async (resolve, reject) => {
            this.setState({isSubmitting: true});

            const nameIndex = form.questions.findIndex(x => x.type === 'name');
            if (nameIndex > -1) data.name = this.state.answers[nameIndex];

            const ageIndex = form.questions.findIndex(x => x.type === 'age');
            if (ageIndex > -1) data.age = parseInt(this.state.answers[ageIndex]);

            const phoneIndex = form.questions.findIndex(x => x.type === 'phone');
            if (phoneIndex > -1) data.phone = this.state.answers[phoneIndex];

            const genderIndex = form.questions.findIndex(x => x.type === 'gender');
            if (genderIndex > -1) data.gender = this.state.answers[genderIndex];

            const emailIndex = form.questions.findIndex(x => x.type === 'email');
            if (emailIndex > -1) data.email = this.state.answers[emailIndex];

            const jobIndex = form.questions.findIndex(x => x.type === 'job');
            if (jobIndex > -1) data.job = this.state.answers[jobIndex];

            const educationIndex = form.questions.findIndex(x => x.type === 'education');
            if (educationIndex > -1) data.education = this.state.answers[educationIndex];

            const availabilityIndex = form.questions.findIndex(x => x.type === 'availability');
            if (availabilityIndex > -1) data.availability = this.state.answers[availabilityIndex].map(x => ({
                id: x.id,
                from: moment(x.date, 'X').hours(x.from.substring(0,2)).minutes(x.from.substring(3,5)).unix(),
                until: moment(x.date, 'X').hours(x.until.substring(0,2)).minutes(x.until.substring(3,5)).unix(),
            }));

            const dataAnswers = [];
            const others = form.questions.filter(x => !['name', 'age', 'phone', 'gender', 'email', 'availability'].includes(x.type));
            if (others.length) {
                for (const other of others) {
                    const index = form.questions.findIndex(x => x.id === other.id);
                    dataAnswers.push({...other, answer: this.state.answers[index]});
                }
                data.questions = dataAnswers;
            }

            try {
                if(this.props.match.params.formId !== 'demo-form') await this.props.create('formResponses', data, false, true);
                this.props.clearSession(true);
                this.setState({isSubmitting: false});
                resolve();
            } catch (e) {
                this.setState({isSubmitting: false});
                reject();
            }
        })
    }

    async next(e) {
        if (e && e.preventDefault) e.preventDefault();

        const form = this.form;
        const question = form.questions[this.state.questionIndex];

        // Set questionIndex
        let questionIndex = this.state.questionIndex;
        let isStarted = this.state.isStarted;
        let isReview = this.state.isReview;
        let isSent = this.state.isSent;

        // Block if answer is required
        if (isStarted && !isReview && !this.state.value && !['multiple-choice', 'availability'].includes(question.type)) return false;

        if (!isStarted) {
            isStarted = true;
        } else if (isReview) {
            // Submission process
            try {
                await this.submit(form);
                isSent = true;
            } catch (e) {
                this.props.error('Could not submit', 'The form is not available anymore.');
                return false;
            }
        } else if (questionIndex === form.questions.length - 1) {
            isReview = true;
        } else {
            questionIndex += 1;
        }

        // Save answer
        const answers = [...this.state.answers];
        if (this.state.isStarted && !this.state.isReview) {
            answers[this.state.questionIndex] = this.state.value;
        }

        // Set default value
        let value = this.state.answers[questionIndex] || '';
        if (isStarted && !isReview && !value && form.questions[questionIndex].type === 'availability') {
            value = form.dates.map(date => ({date, id: getSemiUniqueKey(), from: '08:00:00', until: '18:00:00'}));
        }
        this.setState({
            value,
            questionIndex,
            answers,
            isReview,
            isStarted,
            isSent,
            isSubmitting: false
        }, () => this.updateSession());
    }

    render() {
        const form = this.form;
        if (this.state.isLoading) return <div className='h-screen'><Loader fullScreen /></div>;
        if (!form) return <State icon='Alerts.InterfaceAlertWarningCircle'
                                 title='This form is not available anymore.'/>;
        window.appColor = `${form.accentColor || 'indigo'}-500`;
        window.appColorDarker = `${form.accentColor || 'indigo'}-600`;
        const isMobile = window.innerWidth < 640;
        let content;

        if (!this.state.isStarted) {
            content = <>
                <div ref={this.content} className='p-10 pt-8 flex-1 overflow-scroll whitespace-pre-wrap'>
                    <div className='h6'>Form <span className='mx-1'>&middot;</span> {form.questions.length} question{form.questions.length === 1 ? '' : 's'}</div>
                    <h1 className='h3 mt-2 mb-6 leading-snug'>{form.title}</h1>
                    {isMobile && form.showImageOnMobile ? (
                        <div className='bg-cover bg-center rounded-xl my-8 -mx-5'
                             style={{height: 260, backgroundImage: `url(${this.state.image})`}}/>
                    ) : null}
                    <div className='form-desc'>
                        <ReactMarkdown skipHtml linkTarget='_blank'
                                       allowedTypes={['root', 'text', 'paragraph', 'emphasis', 'strong', 'link']}>{form.desc}</ReactMarkdown>
                    </div>
                    {isMobile && <div className='flex-none mt-10'>
                        <Button key='submit-btn' type='submit' primary className='w-full'>Start &rarr;</Button>
                    </div>}
                </div>
                {!isMobile && <div className='flex-none p-5 border-t'>
                    <Button key='submit-btn' type='submit' primary className='w-full'>Start &rarr;</Button>
                </div>}
                <Shortcut press='Enter' onPress={this.next.bind(this)}/>
            </>;
        } else if (this.state.isSent) {
            content = <>
                <div ref={this.content}
                     className='p-10 pt-8 pb-4 flex-1 flex flex-col items-center text-center justify-center whitespace-pre-wrap'>
                    <div className={`border-2 border-${window.appColor} h-14 w-14 rounded-full p-3 pt-4`}>
                        <Icon icon='check' color={window.appColor} size={20}/>
                    </div>
                    <p className='mt-6 h5'>{form.confirmationMessage}</p>
                    <p className='mt-2 sub'>You can now close this window.</p>
                </div>
            </>;
        } else if (this.state.isReview) {
            content = <>
                <div ref={this.content}
                     className={`p-10 pt-8 pb-4 flex-1 overflow-scroll whitespace-pre-wrap ${this.state.isSubmitting ? 'pointer-events-none' : ''}`}>
                    <h6>Review</h6>
                    <h1 className='h3 mt-3.5 mb-8'>Is this correct?</h1>
                    {this.state.answers.map((answer, i) => (
                        <div className='flex mb-5' key={'answer-' + i}>
                            <div className='flex-1 leading-relaxed'>
                                <h5 className='leading-snug'>{form.questions[i] ? form.questions[i].title : 'No title'}</h5>
                                <div
                                    className='whitespace-pre-line'>{Array.isArray(answer) ? (answer.length ? answer.sort((a, b) => {
                                        if(form.questions[i].type === 'availability') {
                                            const aHours = a.from.split(':');
                                            const bHours = b.from.split(':');
                                            const aDate = moment(a.date, 'X').hours(aHours[0]).minutes(aHours[1]);
                                            const bDate = moment(b.date, 'X').hours(bHours[0]).minutes(bHours[1]);
                                            return aDate - bDate;
                                        } else {
                                            return a - b;
                                        }
                                    }).map(x => '• ' + (form.questions[i].type === 'availability' ? `${format('date', x.date, {day: 'short'})} from ${moment(x.from, 'HH:mm:ss').format('H:mm')} until ${moment(x.until, 'HH:mm:ss').format('H:mm')}` : x)).join("\n") :
                                    <span className='text-gray-600'>No answer</span>) : (capitalize(answer) ||
                                    <span className='text-gray-600'>No answer</span>)}</div>
                            </div>
                            <Button isSmall onClick={() => this.goToIndex(i)} icon='Edit.InterfaceEditPencil'/>
                        </div>
                    ))}
                    {isMobile && <div className='flex mt-10'>
                        <Button onClick={this.prev.bind(this)} secondary className='flex-1 mr-1'>&larr; Back</Button>
                        <Button isLoading={this.state.isSubmitting} key='submit-btn' primary type='submit'
                                className='ml-1 flex-1'>Submit</Button>
                    </div>}
                </div>
                {!isMobile && <div className='flex p-5 border-t'>
                    <Button onClick={this.prev.bind(this)} className='flex-1 mr-1'>&larr; Back</Button>
                    <Button isLoading={this.state.isSubmitting} key='submit-btn' primary type='submit'
                            className='ml-1 flex-1'>Submit</Button>
                </div>}
                <Shortcut press='Enter' onPress={this.next.bind(this)}/>
            </>;
        } else {
            const question = form.questions[this.state.questionIndex];
            const className = `border py-3 px-3.5 rounded-xl appearance-none outline-none focus:ring-1 ring-${form.accentColor}-500`;
            let input;
            if (question.type === 'name') {
                input = <input key={question.id} value={this.state.value} onChange={e => this.setState({value: e.target.value})}
                               className={className} autoFocus placeholder='Enter your name...'/>;
            } else if (question.type === 'email') {
                input = <input key={question.id} value={this.state.value} onChange={e => this.setState({value: e.target.value})}
                               type='email' className={className} autoFocus placeholder='Enter your email address...'/>;
            } else if (question.type === 'phone') {
                input = <input key={question.id} value={this.state.value} onChange={e => this.setState({value: e.target.value})}
                               type='tel' className={className} autoFocus placeholder='Enter your phone number...'/>;
            } else if (question.type === 'age') {
                input = <input key={question.id} value={this.state.value} onChange={e => this.setState({value: e.target.value})}
                               type='number' className={className} autoFocus placeholder='Enter your age...'/>;
            } else if (question.type === 'education') {
                input = <input key={question.id} value={this.state.value} onChange={e => this.setState({value: e.target.value})}
                               className={className} autoFocus placeholder='Enter your education...'/>;
            } else if (question.type === 'job') {
                input = <input key={question.id} value={this.state.value} onChange={e => this.setState({value: e.target.value})}
                               className={className} autoFocus placeholder='Enter your job...'/>;
            } else if (question.type === 'text') {
                input = <Input onEnter={this.next.bind(this)} autosize hasBorder className='-mt-px' key={question.id}
                               value={this.state.value} onChange={value => this.setState({value})} autoFocus
                               placeholder='Enter your answer...'/>;
            } else if (question.type === 'gender') {
                input = <>
                    <Radio value={this.state.value} onChange={value => this.setState({value})}>
                        <RadioItem shortcut='m' value='male'>Male</RadioItem>
                        <RadioItem shortcut='f' value='female'>Female</RadioItem>
                        <RadioItem shortcut='o' value='other'>Other</RadioItem>
                    </Radio>
                    <Shortcut press='Enter' onPress={this.next.bind(this)}/>
                </>;
            } else if (question.type === 'single-choice') {
                input = <>
                    <Radio value={this.state.value} onChange={value => this.setState({value})}>
                        {question.options.map((x, i) => (
                            <RadioItem key={x} shortcut={i + 1} value={x}>{x}</RadioItem>
                        ))}
                    </Radio>
                    <Shortcut press='Enter' onPress={this.next.bind(this)}/>
                </>;
            } else if (question.type === 'multiple-choice') {
                input = <>
                    {question.options.map((x, i) => (
                        <Checkbox key={x} isActive={Array.isArray(this.state.value) && this.state.value.includes(x)}
                                  onChange={() => this.setState({value: toggleInArray(x, this.state.value)})}>{x}</Checkbox>
                    ))}
                    <Shortcut press='Enter' onPress={this.next.bind(this)}/>
                </>;
            } else if (question.type === 'availability') {
                input = <>
                    {form.dates && form.dates.length ? form.dates.map(date => {
                        const isActive = Array.isArray(this.state.value) && this.state.value.some(x => x.date === date);
                        const availabilityToday = isActive ? this.state.value.filter(x => x.date === date) : false;
                        const last = isActive ? availabilityToday[availabilityToday.length - 1] : false;
                        const lastUntil = isActive ? moment(last.until, 'HH:mm:ss') : false;
                        const lastDisabled = !lastUntil || lastUntil.hour() >= 22;
                        return (
                            <div key={date} className='mt-3'>
                                <h5>{format('date', date, {day: 'full'})}</h5>
                                <div className='mt-1 mb-3 flex items-start py-2'>
                                    <Checkbox className='mr-3 -mt-0.5' onChange={x => {
                                        const value = [...(this.state.value || [])];
                                        if (x) {
                                            value.push({
                                                date,
                                                from: '08:00:00',
                                                until: '18:00:00',
                                                id: getSemiUniqueKey(),
                                            });
                                        } else {
                                            const availabilityOnDay = value.filter(x => x.date === date);
                                            for (const a of availabilityOnDay) {
                                                value.splice(value.findIndex(y => y.id === a.id), 1);
                                            }
                                        }
                                        this.setState({value})
                                    }} isActive={isActive}/>
                                    <div className='w-full'>
                                        {isActive ? <>
                                            {(availabilityToday.map((interval, intervalIndex) => {
                                                return (
                                                    <div key={interval.id}
                                                         className={`flex w-full items-center ${intervalIndex > 0 ? 'mt-2' : ''}`}>
                                                        {isMobile ? <>
                                                            <input type='time' defaultValue={interval.from} onBlur={e => {
                                                                const from = e.target.value + ':00';
                                                                const value = [...(this.state.value || [])];
                                                                const index = value.findIndex(x => x.id === interval.id);
                                                                value[index] = {...value[index], from};
                                                                this.setState({value});
                                                            }} className='p-2 border rounded w-full' />
                                                            <div className='h-10 flex items-center ml-2'>–</div>
                                                            <input type='time' defaultValue={interval.until} onBlur={e => {
                                                                const until = e.target.value + ':00';
                                                                const value = [...(this.state.value || [])];
                                                                const index = value.findIndex(x => x.id === interval.id);
                                                                value[index] = {...value[index], until};
                                                                this.setState({value});
                                                            }} className='p-2 border rounded ml-2 w-full' />
                                                        </> : <>
                                                            <Input type='time' defaultValue={interval.from} onBlur={from => {
                                                                const value = [...(this.state.value || [])];
                                                                const index = value.findIndex(x => x.id === interval.id);
                                                                value[index] = {...value[index], from};
                                                                this.setState({value});
                                                            }} className='p-2 border rounded w-full' />
                                                            <div className='h-10 flex items-center ml-2'>–</div>
                                                            <Input type='time' defaultValue={interval.until} onBlur={until => {
                                                                const value = [...(this.state.value || [])];
                                                                const index = value.findIndex(x => x.id === interval.id);
                                                                value[index] = {...value[index], until};
                                                                this.setState({value});
                                                            }} className='p-2 border rounded ml-2 w-full' />
                                                        </>}
                                                        {availabilityToday.length === 1 ? null :
                                                            <Button isSmall onClick={() => {
                                                                const value = [...(this.state.value || [])];
                                                                value.splice(value.findIndex(x => x.id === interval.id), 1);
                                                                this.setState({value});
                                                            }} className='ml-2 flex-none' icon='cross' />}
                                                    </div>
                                                );
                                            }))}
                                            {isActive && !lastDisabled ? (
                                                <div className='mt-2 ml-2 justify-end flex'>
                                                    <Button isSmall onClick={() => {
                                                        const value = [...(this.state.value || [])];
                                                        value.push({
                                                            date,
                                                            from: `${lastUntil.hour() + 1}:00:00`,
                                                            until: `${lastUntil.hour() + 2}:00:00`,
                                                            id: getSemiUniqueKey(),
                                                        });
                                                        value.sort((a, b) => {
                                                            const aHours = a.from.split(':');
                                                            const bHours = b.from.split(':');
                                                            const aDate = moment(a.date, 'X').hours(aHours[0]).minutes(aHours[1]);
                                                            const bDate = moment(b.date, 'X').hours(bHours[0]).minutes(bHours[1]);
                                                            return aDate - bDate;
                                                        });
                                                        this.setState({value});
                                                    }}>Add another interval</Button>
                                                </div>
                                            ) : null}
                                        </> : <div className='text-gray-600 h-10 flex cursor-pointer items-center pt-1'
                                                   onClick={() => {
                                                       const value = [...(this.state.value || [])];
                                                       value.push({
                                                           date,
                                                           from: '08:00:00',
                                                           until: '18:00:00',
                                                           id: getSemiUniqueKey(),
                                                       });
                                                       this.setState({value})
                                                   }}>Add availability</div>}
                                    </div>
                                </div>
                            </div>
                        );
                    }) : (
                        <div className='text-gray-600 mt-3'>No dates available.</div>
                    )}
                    <Shortcut press='Enter' onPress={this.next.bind(this)}/>
                </>;
            }
            content = (
                <>
                    <div ref={this.content} className='p-10 pt-8 pb-4 flex-1 flex flex-col overflow-scroll whitespace-pre-wrap'>
                        <h6 className='tabular-nums'>Question {this.state.questionIndex + 1} of {form.questions.length}</h6>
                        <h1 className={`h3 leading-snug mt-2 ${question.desc ? '' : 'mb-6'}`}>{question.title}</h1>
                        {form.showDesc && question.desc ?
                            <p className='mt-3 mb-6 text-gray-600'>{question.desc}</p> : null}
                        {input}
                        {isMobile && <div className='flex mt-10'>
                            <Button onClick={this.prev.bind(this)} secondary className='flex-1 mr-1'>&larr; Back</Button>
                            <Button key='submit-btn'
                                    disabled={!this.state.value && !['multiple-choice', 'availability'].includes(question.type)}
                                    primary type='submit'
                                    className='ml-1 flex-1'>{this.state.questionIndex === form.questions.length - 1 ? 'Review' : 'Next'} &rarr;</Button>
                        </div>}
                    </div>
                    {!isMobile && <div className='flex p-5 border-t'>
                        <Button onClick={this.prev.bind(this)} className='flex-1 mr-1'>&larr; Back</Button>
                        <Button key='submit-btn'
                                disabled={!this.state.value && !['multiple-choice', 'availability'].includes(question.type)}
                                primary type='submit'
                                className='ml-1 flex-1'>{this.state.questionIndex === form.questions.length - 1 ? 'Review' : 'Next'} &rarr;</Button>
                    </div>}
                </>
            );
        }
        return (
            <Page
                isLoading={this.state.isLoading}
                title={form.title}
                noBar
            >
                {isMobile ? null : <>
                    <div className={`absolute inset-0 bg-cover pointer-events-none animate-fade-in bg-center`}
                         style={{backgroundImage: `url(${this.state.image})`}}/>
                    <div className='dark:opacity-50 pointer-events-none dark:bg-gray-800 absolute inset-0 z-10'/>
                    <div className='fixed left-0 z-30 pointer-events-none bottom-0 pt-40 pr-72'
                         style={{background: 'linear-gradient(25deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 50%)'}}>
                        <a target='_blank' href='//interviewkit.co' rel='noopener noreferrer'
                           className='pushable flex items-center p-5'>
                            <div className='h-7 w-7 mr-2 bg-yellow-400 rounded-full shadow-sm'
                                 style={{padding: 6}}>
                                <Icon icon='VoiceMail.ComputerVoiceMail' size={13} color='yellow-800'/>
                            </div>
                            <div className='-mt-px'>
                                <div className='text-white opacity-75 text-xs'>Managed with</div>
                                <div className='font-medium -mt-px text-sm text-white'>InterviewKit</div>
                            </div>
                        </a>
                    </div>
                </>}
                <div className={`h-full flex relative z-20 ${isMobile ? '' : 'items-center justify-center'}`}>
                    <div
                        className={`bg-white dark:bg-gray-800 h-full w-full ${isMobile ? '' : 'rounded-3xl overflow-hidden shadow-xl'}`}
                        style={isMobile ? null : {maxWidth: 450, maxHeight: 574}}>
                        <form onSubmit={this.next.bind(this)} className='flex flex-col h-full'>
                            {this.state.isSent ? null : (
                                <div
                                    className={`flex-none ${isMobile ? 'm-5' : 'm-10'} mb-0 rounded-full h-2 bg-${form.accentColor || 'indigo'}-100 dark:bg-${form.accentColor || 'indigo'}-900`}>
                                    <div className={`rounded-full tr h-2 bg-${window.appColor}`}
                                         style={{width: `${(this.state.questionIndex + (this.state.isStarted ? 1 : 0) + (this.state.isReview ? 1 : 0)) / (form.questions.length + 1) * 100}%`}}/>
                                </div>
                            )}
                            {content}
                        </form>
                    </div>
                </div>
            </Page>
        );
    }
}

export default withData(withNotify(Form));