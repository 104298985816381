import React from 'react';
import PropTypes from "prop-types";
import {recursiveMap} from "../functions";
import Shortcut from "./Shortcut";

class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: this.props.isOpen === true,
            timeout: 0,
            width: this.props.width,
            isTop: this.props.positionTop,
        };
        this.ref = React.createRef();
    }
    componentDidMount() {
        this.setPosition();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.approxHeight !== this.props.approxHeight || prevState.isOpen !== this.state.isOpen || prevProps.isOpen !== this.props.isOpen) {
            this.setPosition(true);
        }
    }
    componentWillUnmount() {
        window.clearTimeout(this.state.timeout);
    }
    setPosition(isUpdate) {
        if(this.props.positionTop) return; // Override calculation by providing prop
        const approxHeight = this.props.approxHeight || 260;
        const closestScroll = this.ref.current ? this.ref.current.closest('.overflow-scroll') || {} : {};
        const scrollOffset = isUpdate ? 0 : closestScroll.scrollTop || 0;
        const roomBelow = window.innerHeight - (this.ref.current ? this.ref.current.getBoundingClientRect().top : 0) + scrollOffset;
        const isTop = roomBelow < approxHeight;
        if(isTop !== this.state.isTop) {
            this.setState({isTop});
        }
    }
    setOpen(forceValue) {
        const value = forceValue == null ? !this.state.isOpen : forceValue;
        const disabled = this.props.button && this.props.button.props.disabled;
        if(disabled) return false;
        this.setState({isOpen: value});
        if(this.props.onOpen) this.props.onOpen(value);
    }
    handleSubmit(e) {
        e.preventDefault();
        if(this.props.onSubmit) this.props.onSubmit(e);
        this.setOpen();
    }
    render() {
        const isOpen = this.props.isOpen === undefined ? this.state.isOpen : this.props.isOpen;

        // Calculate offset so that arrow is centered with button icon
        let offset = 0;
        const buttonWidth = this.props.buttonWidth || ((this.props.button && this.props.button.props.children) ? this.props.button.props.children.length * 9 + 30 : 44); // Hacky way to calculate button width.
        if(this.props.arrowPosition === 'center') offset = this.state.width * -1 / 2 + buttonWidth / 2;
        if(this.props.arrowPosition === 'right') offset = this.state.width * -1 + buttonWidth;
        if(this.props.offset != null) offset = this.props.offset;

        // <div className={isOpen ? 'z-50 select-none relative' : ''} onClick={() => this.setOpen()}>{this.props.button}</div>
        const scrollOffset = this.ref.current && this.ref.current.closest('.overflow-scroll') ? this.ref.current.closest('.overflow-scroll').scrollTop || 0 : 0;
        const spaceBelow = this.ref.current && (this.state.isTop ? this.ref.current.getBoundingClientRect().top - 70 : window.innerHeight - this.ref.current.getBoundingClientRect().top - 32);
        const maxHeight = this.ref.current ? Math.min(70, Math.floor(spaceBelow / window.innerHeight * 100)) : 70;
        return (
            <>
                <div className={`relative inline-block select-none min-w-0 ${this.props.className || ''}`} style={this.props.style}>
                    {isOpen ? <div className='fixed select-none inset-0' style={{zIndex: 90}} onClick={() => this.setOpen(false)} /> : null}
                    {this.props.button ? React.cloneElement(this.props.button, {...this.props.button.props, onClick: () => this.setOpen(true), selected: isOpen}) : null}

                        <div className={`fixed ${isOpen ? '' : 'pointer-events-none'}`} ref={this.ref} style={{marginTop: scrollOffset * -1, zIndex: 100}}>
                            {isOpen ? <form onSubmit={this.handleSubmit.bind(this)} className={`animate-dropdown-${this.state.isTop ? 'above' : `below`}${this.props.isWithInput ? '-input' : ''} bg-white dark:bg-gray-800 border rounded-lg shadow-lg absolute`} style={{
                                width: this.state.width,
                                zIndex: 999,
                                left: offset || 0,
                                marginTop: this.state.isTop ? null : (this.props.isWithInput ? '0.9rem' : '1.5rem'), // minimize offset for input fields
                                bottom: this.state.isTop ? (this.props.isWithInput ? '3.2rem' : '4rem') : null,
                            }}>
                                <Shortcut alsoWorksWhenInputInFocus press='Escape' onPress={() => this.setOpen(false)} />
                                <div className={`overflow-scroll text-gray-800 dark:text-gray-200 font-normal ${this.props.hasPadding ? `p-${this.props.isInfo ? 5 : 2}` : ''}`} style={{maxHeight: `${maxHeight}vh`}}>
                                    {recursiveMap(this.props.children, child => (
                                        typeof child.type === 'function' ? (
                                            React.cloneElement(child, {
                                                ...(child.props || {}),
                                                isSmall: true,
                                                isDropdownPadding: true,
                                                closeDropdown: () => this.setOpen(false),
                                                onClick: () => {
                                                    if(child.props) {
                                                        // Handle optional onClick event.
                                                        if(child.props.onClick) child.props.onClick();
                                                        // Close dropdown if autoClose is true.
                                                        if(this.props.autoClose || child.props.autoClose !== false) this.setOpen(false);
                                                    }
                                                },
                                            })
                                        ) : child
                                    ))}
                                    {this.props.desc && <p className='px-3 pt-2 pb-1 sub whitespace-pre-wrap'>{this.props.desc}</p>}
                                </div>
                                <div className='dropdown-tip-border' style={{
                                    bottom: this.state.isTop ? null : 'calc(100% + 1px)',
                                    top: this.state.isTop ? 'calc(100% + 1px)' : null,
                                    left: this.props.arrowPosition === 'left' ? 21 : (this.props.arrowPosition === 'right' ? null : '50%'),
                                    right: this.props.arrowPosition === 'right' ? 10 : null,
                                    border: 'solid transparent',
                                    height: 0,
                                    width: 0,
                                    position: 'absolute',
                                    pointerEvents: 'none',
                                    borderColor: 'rgba(255, 255, 255, 0)',
                                    borderBottomColor: this.state.isTop ? 'transparent' : null,
                                    borderTopColor: this.state.isTop ? null : 'transparent',
                                    borderWidth: 11,
                                    marginLeft: -11,
                                    zIndex: 15,
                                }} />
                                <div className='dropdown-tip-bg' style={{
                                    bottom: this.state.isTop ? null : '100%',
                                    top: this.state.isTop ? '100%' : null,
                                    left: this.props.arrowPosition === 'left' ? 21 : (this.props.arrowPosition === 'right' ? null : '50%'),
                                    right: this.props.arrowPosition === 'right' ? 11 : null,
                                    border: 'solid transparent',
                                    height: 0,
                                    width: 0,
                                    position: 'absolute',
                                    pointerEvents: 'none',
                                    borderColor: 'rgba(255, 255, 255, 0)',
                                    borderBottomColor: this.state.isTop ? 'transparent' : null,
                                    borderTopColor: this.state.isTop ? null : 'transparent',
                                    borderWidth: 10,
                                    marginLeft: -10,
                                    zIndex: 20,
                                }} />
                            </form> : null}
                        </div>
                </div>
                {this.props.borderRight ? <div className='h-8 border-l mr-2' /> : null}
            </>
        );
    }
}

Dropdown.propTypes = {
    arrowPosition: PropTypes.string,
    button: PropTypes.object,
    className: PropTypes.string,
    hasPadding: PropTypes.bool,
    isOpen: PropTypes.bool,
    onOpen: PropTypes.func,
    positionTop: PropTypes.bool,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
};

Dropdown.defaultProps = {
    arrowPosition: 'left',
    hasPadding: true,
    width: 180,
};

export default Dropdown;