import React from 'react';
import PropTypes from 'prop-types';
import Button from "./Button";
import {withData} from "../data";
import {withNotify} from "../notify";
import {withShare} from "../share";

class ButtonInvite extends React.Component {
    render() {
        const {project, onlineIds} = this.props;
        const userIds = project.memberIds || [];
        return userIds.length > 1 && (
            <div className='relative'>
                <Button to={this.props.to} title={`${userIds.length} people`} icon='users' />
                {onlineIds.length > 1 && <div className='bg-green-500 w-2.5 h-2.5 absolute top-1 right-1 z-30 rounded' />}
            </div>
        );
    }
}

ButtonInvite.propTypes = {
    project: PropTypes.object.isRequired,
    to: PropTypes.string.isRequired,
}

export default withData(withNotify(withShare(ButtonInvite)));