import React from 'react';
import Button from "./Button";
import State from "./State";
import PropTypes from 'prop-types';
import {withData} from "../data";
import {capitalize} from "../functions";

class Error extends React.Component {
    render() {
        const isLoggedIn = !!this.props.userId && !this.props.isDemo;
        const defaults = {
            '404': {
                title: `${this.props.singular ? `${capitalize(this.props.singular)} not` : 'Not'} found`,
                code: 404,
                message: `Please make sure you have the correct link${isLoggedIn ? ", or contact us if you need help" : ''}.`,
                icon: 'Smiley.MailSmileySadFace',
                textWidth: isLoggedIn ? 'xs' : 'sm',
            },
            noPermission: {
                title: <>You do not have permission <br />to view this{this.props.singular ? ` ${this.props.singular}` : ''}.</>,
                message: isLoggedIn ? 'If you believe this is an error, please let us know by using the help & feedback button.' : 'Please log in and try again.',
                icon: 'User.InterfaceUserBlock',
            },
            other: {
                title: 'Something went wrong.',
                icon: 'Alerts.InterfaceAlertWarningCircle',
                errorMessage: this.props.message,
                tryAgain: this.props.tryAgain !== false,
            },
        };
        const {title, icon, errorMessage, textWidth, message, tryAgain} = defaults[this.props.type || 'other'];

        return (
            <State color='red-400' icon={icon} maxWidth={400} title={title}>
                {message ? <p className={`text-center max-w-${textWidth}`}>{message}</p> : null}
                {errorMessage ? <code className='my-5 text-sm bg-gray-100 dark:bg-gray-900 p-4 rounded-lg' style={{maxWidth: 360}}>{errorMessage}</code> : null}
                <div className='flex mt-5'>
                    {tryAgain ? <Button onClick={() => window.location.reload()} primary>Try again</Button> : null}
                </div>
            </State>
        );
    }
}

Error.propTypes = {
    message: PropTypes.string,
    type: PropTypes.string,
    code: PropTypes.any,
};

export default withData(Error);