import React from 'react';

class ContextMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false,
            yPos: 0,
            xPos: 0,
        };
        this.wrapper = React.createRef();
        this.menu = React.createRef();
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
        document.addEventListener("contextmenu", this.handleContextMenu);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
        document.removeEventListener("contextmenu", this.handleContextMenu);
    }

    handleClick = (e) => {
        if(e.which === 1) {
            // If left click
            const clickedInside = this.menu?.current?.contains(e.target);
            if(this.state.showMenu && !clickedInside) {
                this.setState({showMenu: false});
            }
        }
    }

    getMenu(e) {
        let selectedText;
        if(e) {
            selectedText = window.getSelection().toString();
        }
        return typeof this.props.menu === 'function' ? this.props.menu(selectedText, e) : this.props.menu;
    }

    handleContextMenu = (e) => {
        this.menuProp = this.getMenu(e);
        if(!this.menuProp) return;
        const clickedInside = this.wrapper.current && this.wrapper.current.contains(e.target);
        if(clickedInside) {
            e.preventDefault();
            this.setState({
                xPos: e.pageX,
                yPos: e.pageY,
                showMenu: true,
            }, () => {
                // Correct bottom overflow
                let xPos = e.pageX;
                let yPos = e.pageY;
                const menuHeight = this.menu?.current?.offsetHeight;
                if(!menuHeight) return null;
                const availableHeight = window.innerHeight - e.pageY;
                if(availableHeight < menuHeight) {
                    yPos += (availableHeight - menuHeight) - 10; // 10px margin to bottom
                }
                // Correct right overflow
                const menuWidth = this.menu.current.offsetWidth;
                const availableWidth = window.innerWidth - e.pageX;
                if(availableWidth < menuWidth) {
                    xPos -= menuWidth // Flip menu to other side
                }
                xPos = Math.max(0, xPos);
                yPos = Math.max(0, yPos);
                this.setState({xPos, yPos});
            });
        } else if(this.state.showMenu) {
            this.menu = null;
            this.setState({showMenu: false});
        }
    }
    render() {
        const hasDynamicTitle = this.props.numSelected && this.props.singular && this.props.plural;
        const title = this.props.title || (hasDynamicTitle ? this.props.numSelected === 1 ? `1 ${this.props.singular}` : `${this.props.numSelected} ${this.props.plural}` : false);
        return (
            <div ref={this.wrapper} data-id={this.props.id} className={this.props.className} style={this.props.style}>
                {this.state.showMenu ? (
                    <div className='fixed pointer-events-none overflow-hidden inset-0 p-6 z-50'>
                        <div
                            ref={this.menu}
                            className='absolute pointer-events-auto bg-white max-h-full overflow-scroll dark:bg-gray-800 border rounded-lg shadow-lg p-2'
                            style={{
                                minWidth: 150,
                                maxWidth: 220,
                                top: this.state.yPos,
                                left: this.state.xPos,
                            }}
                        >
                            {title ? <h6 className='pf'>{title}</h6> : null}
                            <div onClick={() => this.setState({showMenu: false})}>{this.menuProp}</div>
                            {this.props.desc ? <p className='px-3 pt-2 pb-1 sub whitespace-pre-wrap'>{this.props.desc}</p> : null}
                        </div>
                    </div>
                ) : null}
                {this.props.children}
            </div>
        );
    }
}

export default ContextMenu;