import React from 'react';
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';

class Previews extends React.Component {
    render() {
        const value = queryString.parse(this.props.location.search).p;
        return React.Children.toArray(this.props.children).filter(x => x.props.value === value);
    }
}

export default withRouter(Previews);