import React from 'react';
import Input from "./Input";
import Icon from "./Icon";
import {
    formatData,
    format,
    sortData, filterData, capitalize,
} from "../functions";
import {withData} from "../data";
import State from "./State";
import Shortcut from "./Shortcut";
import ContextBoard from "./ContextBoard";
import {PropTypes} from "prop-types";
import {motion} from "framer-motion";

class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortBy: this.props.local[`${this.props.plural}SortBy`] || this.props.defaultSortBy || false, // If no default, sort it the way we receive it.
            sortDesc: this.props.local[`${this.props.plural}SortDesc`] || this.props.defaultSortDesc || false,
            editId: false,
            editKey: false,
            hideIdsSearch: [],
            hideIdsFilter: [],
            selectedIds: [],
            editKeyVal: {},
            search: '',
            newFilterKey: false,
            newFilterComparison: false,
            newFilterValue: '',
        };
        this.fieldWidths = [];
        this.buttonWidths = [];
        this.data = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside(event) {
        if(this.props.isInlineEditable) return false;
        if(this.data.current && !this.data.current.contains(event.target)) {
            this.setState({selectedIds: []});
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const newState = {};
        // When exiting edit mode, remove selection
        if(prevProps.isInlineEditable && !this.props.isInlineEditable) {
            newState.selectedIds = [];
            newState.editKeyVal = {};
        }
        if(prevProps.local[`${this.props.plural}SortBy`] !== this.props.local[`${this.props.plural}SortBy`]) {
            if(this.props.local[`${this.props.plural}SortBy`] !== this.state.sortBy) {
                newState.sortBy = this.props.local[`${this.props.plural}SortBy`];
            }
        }
        if(prevProps.local[`${this.props.plural}SortDesc`] !== this.props.local[`${this.props.plural}SortDesc`]) {
            if(this.props.local[`${this.props.plural}SortDesc`] !== this.state.sortDesc) {
                newState.sortDesc = this.props.local[`${this.props.plural}SortDesc`];
            }
        }
        // Update state when any adjustments were made in this function
        if(Object.keys(newState).length) this.setState(newState);
    }
    handleEdit() {
        const obj = {};
        for(const id of this.state.selectedIds) {
            obj[id] = {...this.state.editKeyVal}
        }
        this.props.onUpdate(obj);
    }
    handleSort(key) {
        if(!this.props.canSort) return false;
        const isActiveSort = this.state.sortBy === key;
        const sortDesc = isActiveSort ? !this.state.sortDesc : false;
        this.setState({
            sortBy: key,
            sortDesc,
        });
        this.props.setLocal({
            [`${this.props.plural}SortBy`]: key,
            [`${this.props.plural}SortDesc`]: sortDesc,
        });
    }
    render() {
        const singular = this.props.singular || 'item';
        const plural = this.props.plural || 'items';
        let fields = this.props.fields.filter(x => x && !x.hidden && x.canShow !== false && !(this.props.hideFields || []).includes(x.key)) || [];
        const data = filterData(this.props.searchQuery, this.props.hideFields, fields, this.props.data);
        const sortByField = fields.find(x => x.key === this.state.sortBy);
        if(sortByField) data.sort((a,b) => sortData(a, b, sortByField, this.state.sortDesc));
        let gridTemplateColumns = fields.map((x,i) => {
            if(!!this.props.badges && i === fields.length - 1) return x.size && x.size.endsWith('fr') && parseFloat(x.size) > 1 ? x.size : 'minmax(280px, 1fr)'; // Override default pixels below for badges
            if(x.size) return x.size;
            else if(x.type === 'boolean' || x.type === 'time' || x.type === 'number') return '70px';
            else if(x.type === 'labels') return 'minmax(300px, 1fr)';
            else return '230px'; // Default
        }).map(x => x.endsWith('px') || x.includes('minmax') ? x : `minmax(150px, ${x})`).join(' ');

        return (
            <motion.div layout='position' className={`flex-1 flex flex-col overflow-scroll items-start cursor-default select-none ${this.props.className || ''}`} style={this.props.style}>
                <div className={`grid flex-none tr sticky z-10 min-w-full gap-10 ${this.props.noPadding ? '' : 'pl-10'} ${this.props.noPadding ? '' : 'pr-10'} h-14 justify-between items-center`} style={{gridTemplateColumns: gridTemplateColumns}}>
                    <div className={`border-b ${this.props.noPadding ? '' : 'px-4'} absolute bottom-0 inset-x-0 border-light`} />
                    {fields.map((field, colIndex) => {
                        const isActiveSort = this.state.sortBy === field.key;
                        const canSort = this.props.canSort !== false && field.canSort !== false;
                        if(!this.fieldWidths[colIndex]) this.fieldWidths[colIndex] = [];
                        this.fieldWidths[colIndex][0] = React.createRef();
                        return (
                            <div className={`whitespace-nowrap overflow-hidden ${field.type === 'labels' && field.align === 'right' ? 'pr-2' : ''} text-${field.align || 'left'}`} ref={x => x ? this.fieldWidths[colIndex][0] = x : null} key={field.key}>
                                <h6 onClick={() => canSort ? this.handleSort(field.key) : null} key={field.key} className={`inline-flex items-center ${canSort ? 'hover:text-gray-700 dark:hover:text-gray-400 cursor-pointer' : ''} select-none`} title={field.name || capitalize(field.key)}>
                                    <span className='truncate' style={{paddingRight: (isActiveSort && canSort) || field.align === 'right' ? 0 : 17}}>{field.name || capitalize(field.key)}</span>
                                    {isActiveSort && canSort && <Icon icon={this.state.sortDesc ? 'Arrows.InterfaceArrowsButtonUp' : 'Arrows.InterfaceArrowsButtonDown'} size={10} className='ml-2 relative' style={{top: 1}} />}
                                </h6>
                            </div>
                        );
                    })}
                </div>
                <div ref={this.data} className='flex-1 min-h-0 min-w-full'>
                    {data.length ? <ContextBoard
                        canDrag
                        data={data}
                        desc={this.props.desc}
                        className={`overflow-scroll relative ${this.props.noPaddingBottom ? '' : 'pb-32'} py-2 ${this.props.noPadding ? '' : 'px-6'}`}
                        singular={singular}
                        plural={plural}
                        contextMenu={this.props.contextMenu}
                        to={this.props.to}
                        disabled={this.props.isInlineEditable}
                        mapData={(dataItem, rowIndex, isSelected) => {
                            const badges = typeof this.props.badges === 'function' ? this.props.badges(dataItem, rowIndex) : false;
                            return <div key={dataItem.id} className={`justify-between relative items-center h-12 grid tr gap-10 rounded-lg mb-px ${isSelected ? 'bg-gray-100 dark:bg-gray-700' : ''} ${this.props.noPadding ? '' : 'px-4'} ${this.props.isInlineEditable ? `select-none` : ''}`} style={{gridTemplateColumns}}>
                                {this.props.isNumbered && !this.props.image ? <div style={{left: '1rem'}} className={`absolute inset-y-0 text-gray-400 flex items-center mt-px dark:text-gray-500`}>{rowIndex + 1}</div> : null}
                                {this.props.image ? <div className='w-8 h-8 ml-3 rounded-full absolute inset-y-0 my-auto bg-cover bg-center' style={{backgroundImage: `url(${typeof this.props.image === 'function' ? this.props.image(dataItem) : dataItem[this.props.image]})`}} /> : null}
                                {fields.map((field, colIndex) => {
                                    const {rawVal, val} = formatData(dataItem, field, true);
                                    const content = val ? (field.type === 'labels' && rawVal.length === 0 ? '' : <>{field.before || ''}{val}{field.after || ''}</>) : (field.type === 'labels' ? '' : '—');
                                    const isTabular = ['currency', 'number', 'time'].includes(field.type);
                                    const isLastCol = colIndex === fields.length - 1;
                                    const padding = (this.props.isNumbered || this.props.isInlineEditable || this.props.image) && colIndex === 0;
                                    if(!this.fieldWidths[colIndex]) this.fieldWidths[colIndex] = [];
                                    this.fieldWidths[colIndex][rowIndex + 1] = React.createRef();
                                    let title = typeof field.title === "function" ? field.title(rawVal, dataItem) : field.title;
                                    const isEditing = this.state.editId === dataItem.id && this.state.editKey === field.key;
                                    const fieldDiv = <div title={title} ref={x => x ? this.fieldWidths[colIndex][rowIndex + 1] = x : null} key={`${field.key}-${dataItem.id}`} className={`flex-1 ${isEditing ? '' : 'truncate'} ${field.type === 'boolean' ? 'text-center' : ''} ${padding ? 'pl-10' : ''} ${isTabular ? 'tabular' : ''} ${field.align === 'right' ? 'text-right' : ''} ${val ? '' : 'text-gray-300 dark:text-gray-500'}`}>
                                        {isEditing ? (
                                            <div className='relative'>
                                                <Shortcut alsoWorksWhenInputInFocus press='Escape' onPress={x => document.activeElement.blur()} />
                                                <Shortcut alsoWorksWhenInputInFocus press='Enter' onPress={x => document.activeElement.blur()} />
                                                <Input hasBorder format={field.format} autoFocus defaultValue={rawVal} className='absolute bg-white dark:bg-gray-800 shadow-lg inset-0' style={{left: -15, right: -15, width: 'calc(100% + 30px)'}} type={field.type} onBlur={val => {this.props.onUpdate({[dataItem.id]: {[field.key]: val}}); this.setState({editId: false, editKey: false})}} />
                                            </div>
                                        ) : (
                                            <span onClick={this.props.isInlineEditable ? () => this.setState({editId: dataItem.id, editKey: field.key}) : null} className={field.className}>{content}</span>
                                        )}
                                    </div>;
                                    return !!badges && isLastCol ? (
                                        <div key={`${field.key}-${rowIndex}`} className='flex items-center'>
                                            {fieldDiv}
                                            <div className='ml-auto pl-2 overflow-hidden whitespace-nowrap flex space-x-2'>{badges}</div>
                                        </div>
                                    ) : fieldDiv;
                                })}
                            </div>
                        }}
                    /> : <State />}
                </div>
                {data.length && this.props.showTotals && fields.some(x => x.type === 'number' || x.type === 'currency') ? (
                    <div className={`flex-none grid gap-10 min-w-full -mt-px pb-px sticky bg-white dark:bg-gray-800 h-12 ${this.props.noPadding ? '' : 'px-10'} justify-between`} style={{gridTemplateColumns}}>
                        <div className={`absolute top-0 inset-x-0 border-t border-light`} />
                        {fields.map((field, colIndex) => {
                            const padding = (this.props.isNumbered || this.props.isInlineEditable || this.props.image) && colIndex === 0;
                            let sum = field.type === 'number' || field.type === 'currency' ? data.reduce((sum, obj) => sum + (field.calc ? field.calc(obj[field.key], obj) : obj[field.key]), 0) : null;
                            if(field.type === 'currency') sum = format('currency', sum);
                            else if(field.type === 'number' && !Number.isInteger(sum)) sum = sum.toFixed(1);
                            if(!sum || field.showTotals === false) sum = null;
                            return (
                                <div ref={x => x ? this.fieldWidths[colIndex][this.fieldWidths[colIndex].length] = x : null} key={field.key} className={`flex tabular truncate font-medium items-center ${padding ? 'pl-10' : ''}`}>
                                    <span className={`whitespace-nowrap w-full text-${field.align || 'left'}`}>{sum ? field.before : null}{sum}{sum ? field.after : null}</span>
                                </div>
                            );
                        })}
                    </div>
                ) : null}
            </motion.div>
        )
    }
}

Table.propTypes = {
    singular: PropTypes.string.isRequired,
    plural: PropTypes.string.isRequired,
}

/*
{this.props.filter ? (
                    <div className={`flex flex-wrap ${this.props.noPadding ? '' : 'px-10'} flex-none`}>
                        <div className='w-48 mb-2 mr-2 flex-none'><Input shortcut='f' onChange={this.handleSearch.bind(this)} icon='Search.InterfaceSearch' placeholder='Find...' /></div>
                        {filters.map(filter => {
                            const field = this.props.fields.find(x => x.key === filter.key);
                            const name = field ? capitalize(field.name || field.key) : false;
                            if(!name) return null;
                            return (
                                <div key={filter.id} onClick={() => this.removeFilter(filter.id)} className='h-10 whitespace-nowrap pushable group bg-gray-100 dark:bg-gray-700 px-3 relative flex items-center text-sm font-medium rounded-lg border cursor-pointer mr-2 mb-2'>
                                    <div className='group-hover:opacity-100 tr z0 opacity-0 absolute inset-0 flex items-center justify-center'>
                                        <div className='group-hover:opacity-75 opacity-0 absolute inset-0 rounded-lg bg-gray-100 dark:bg-gray-700' />
                                        <Icon icon='AddRemoveDelete.InterfaceDeleteCircle' size={16} className='relative z-10 -mt-px' />
                                    </div>
                                    <div>{name} <span className='text-gray-500'>{filter.comparison}</span> {['text', 'dropdown', 'array'].includes(field.type || 'text') ? `"${filter.value}"` : (field.type === 'currency' ? format('currency', filter.value, false, false) : (filter.type === 'date' ? format('date', filter.value, this.props.showDayInDates, this.props.showYearInDates) : filter.value))}</div>
                                </div>
                            );
                        })}
                        {this.props.filterMenus}
                        <Dropdown onSubmit={this.addFilter.bind(this)} onOpen={isOpen => isOpen ? null : this.setState({newFilterKey: false, newFilterComparison: false, newFilterValue: ''})} width={260} button={<Button icon='AddRemoveDelete.InterfaceAddCircle'>{filters.length ? null : 'Filter'}</Button>}>
                            {this.state.newFilterComparison ? (
                                <div className='py-2'>
                                    <h6 className='p-3 px-5'>{newFilterHead.name || newFilterHead.key} {this.state.newFilterComparison}</h6>
                                    <div className='px-3 pb-2'>
                                        <Input showDay={this.props.showDayInDates} showYear={this.props.showYearInDates} type={inputType} autoFocus onChange={newFilterValue => this.setState({newFilterValue})} />
                                        <Button disabled={!this.state.newFilterValue} primary className='mt-3 w-full'>Add filter</Button>
                                    </div>
                                </div>
                            ) : (this.state.newFilterKey ? (
                                    <div className='py-2'>
                                        <h6 className='p-3 px-5'>{newFilterHead.name || newFilterHead.key}</h6>
                                        <div className='px-1'>
                                            {(comparisons[newFilterHead.type || 'text']).map(comparison => {
                                                const isBoolean = comparison === 'is true' || comparison === 'is false';
                                                return (
                                                    <DropdownItem autoClose={isBoolean} key={`filter-${comparison}`} onClick={() => {
                                                        if(isBoolean) {
                                                            this.addFilter(null, this.state.newFilterKey, comparison);
                                                        } else {
                                                            this.setState({newFilterComparison: comparison});
                                                        }
                                                    }}>{comparison}</DropdownItem>
                                                );
                                            })}
                                        </div>
                                    </div>
                                ) : (
                                    <div className='py-2'>
                                        <h6 className='p-3 px-5'>Add filter</h6>
                                        <div className='px-1'>{this.props.fields.map((field, fieldIndex) => <DropdownItem key={`dropdown-${fieldIndex}`} onClick={() => this.setState({newFilterKey: field.key, newFilterTitle: capitalize(field.name || field.key)})}>{capitalize(field.name || field.key)}</DropdownItem>)}</div>
                                    </div>
                                )
                            )}
                        </Dropdown>
                    </div>
                ) : null}

                const newFilterHead = this.props.fields.find(field => field.key === this.state.newFilterKey);
        const inputType = newFilterHead ? (['array', 'dropdown'].includes(newFilterHead.type) ? 'text' : newFilterHead.type) : false;
        const filters = this.props.local[`${this.props.collection}Filters`] || [];

        handleFilter(filters) {
        if(!filters) {
            const key = `${this.props.collection}Filters`;
            filters = this.props.local[key] ? [...this.props.local[key]] : [];
        }
        const hideIdsFilter = this.props.data.filter(dataObj => {
            for(const filter of filters) {
                const x = dataObj[filter.key];
                if(filter.comparison === '=') return !(x != null && x === filter.value);
                if(filter.comparison === '≠') return !(x !== filter.value);
                if(filter.comparison === '>') return !(x != null && x > filter.value);
                if(filter.comparison === '<') return !(x != null && x < filter.value);
                if(filter.comparison === 'has') return !(x != null && x.includes(filter.value));
                if(filter.comparison === 'has not') return x && x.includes(filter.value);
                if(filter.comparison === 'is true') return !x;
                if(filter.comparison === 'is false') return x;
            }
        }).map(dataObj => dataObj.id);
        this.setState({hideIdsFilter});
    }
    addFilter(e, filterKey, comparison) {
        if(e) e.preventDefault();
        const key = `${this.props.collection}Filters`;
        const filters = this.props.local[key] ? [...this.props.local[key]] : [];
        // Get comparison
        comparison = comparison || this.state.newFilterComparison;
        if(['includes', 'include'].includes(comparison)) comparison = 'has';
        if(['does not include', 'do not include'].includes(comparison)) comparison = 'has not';
        if(['is on', 'is'].includes(comparison)) comparison = '=';
        if(comparison === 'is not') comparison = '≠';
        if(['is before', 'is smaller than'].includes(comparison)) comparison = '<';
        if(['is after', 'is bigger than'].includes(comparison)) comparison = '>';
        // Push filter
        filterKey = filterKey || this.state.newFilterKey;
        filters.push({id: getSemiUniqueKey(), key: filterKey, comparison, value: this.state.newFilterValue});
        this.setState({isNewFilterOpen: false, newFilterKey: false, newFilterComparison: false, newFilterValue: ''});
        this.props.setLocal({[key]: filters});
        this.handleFilter(filters);
    }
    removeFilter(id) {
        const key = `${this.props.collection}Filters`;
        const filters = this.props.local[key] ? [...this.props.local[key]] : [];
        const index = filters.indexOf(x => x.id === id);
        filters.splice(index, 1);
        this.props.setLocal({[key]: filters});
        this.handleFilter(filters);
    }
    handleSearch(search) {
        const searchWords = search.toLowerCase().split(' ');
        const hideIdsSearch = this.props.data.filter(dataObj => {
            const keys = Object.keys(dataObj);
            const headKeys = this.props.fields.map(h => h.key);
            const values = Object.values(dataObj).filter((x, i) => typeof x === 'string' && headKeys.includes(keys[i]) && !(this.props.hideFields || []).includes(keys[i]));
            return !searchWords.every(searchWord => values.some(val => val.toLowerCase().includes(searchWord)));
        }).map(dataObj => dataObj.id);
        this.setState({hideIdsSearch});
    }
    const comparisons = {
        array: ['include', 'do not include'],
        tags: ['include', 'do not include'],
        date: ['is on', 'is before', 'is after'],
        time: ['is on', 'is before', 'is after'],
        email: ['is', 'is not', 'includes', 'does not include'],
        text: ['is', 'is not', 'includes', 'does not include'],
        dropdown: ['is', 'is not', 'includes', 'does not include'],
        number: ['is', 'is not', 'is smaller than', 'is bigger than'],
        currency: ['is', 'is not', 'is smaller than', 'is bigger than'],
        boolean: ['is true', 'is false'],
    };
 */

Table.defaultProps = {
    canSort: true,
}

export default withData(Table);