import React from 'react';
import List from "./List";
import State from "./State";

class Widget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isScrolled: false,
        };
        this.onScrollBound = this.onScroll.bind(this);
    }
    componentDidMount() {
        if(this.ref) this.ref.addEventListener('scroll', this.onScrollBound);
    }
    componentWillUnmount() {
        if(this.ref) this.ref.removeEventListener('scroll', this.onScrollBound);
    }
    onScroll() {
        if(!this.state.isScrolled && this.ref.scrollTop > 0) this.setState({isScrolled: true});
        if(this.state.isScrolled && this.ref.scrollTop <= 0) this.setState({isScrolled: false});
    }
    render() {
        let data = this.props.data || [];
        const amount = data.length;
        data = this.props.limit ? data.slice(0, this.props.limit) : data;
        const diff = amount - (this.props.limit || 0);
        return (
            <div className='rounded-lg flex bg-white row-span-2 dark:bg-gray-800 flex-col overflow-hidden h-full shadow-sm' style={this.props.style}>
                <div className='p-6 flex items-center'>
                    <div className='truncate mr-auto'>
                        <h5>{this.props.title}</h5>
                        {this.props.afterSubtitle ? <p className='sub mt-1 truncate'>{amount === 0 ? 'No' : amount} {amount === 1 ? this.props.singular : this.props.plural} {this.props.afterSubtitle}</p> : null}
                    </div>
                    {this.props.right}
                </div>
                {this.props.children || (data.length ? <div ref={x => this.ref = x} className={`scroll border-t ${this.state.isScrolled ? 'border-light' : 'border-transparent'} tr`}>
                    <List
                        isWidget
                        isSmall={this.props.isSmall || this.props.keySecondary}
                        singular={this.props.singular}
                        plural={this.props.plural}
                        keyPrimary={this.props.keyPrimary}
                        keyTertiary={this.props.keySecondary}
                        keyRight={this.props.keyRight}
                        fields={this.props.fields}
                        data={data}
                        to={this.props.to}
                    />
                    {this.props.limit && diff > 0 ? <p className='sub pt-4 pb-6 border-t border-light mx-6'>{diff} more...</p> : null}
                </div> : <State icon={this.props.icon} />)}
            </div>
        );
    }
}

Widget.defaultProps = {
    limit: 5,
};

export default Widget;