import React from 'react';
import DropdownItem from "./DropdownItem";
import Dropdown from "./Dropdown";
import Shortcut from "./Shortcut";
import State from "./State";
import {capitalize, isInt} from "../functions";
import moment from 'moment';
import 'pikaday/css/pikaday.css';
import Pikaday from 'pikaday';
import PropTypes from 'prop-types';
import leftBlack from '../assets/left-black.svg';
import rightBlack from '../assets/right-black.svg';
import leftWhite from '../assets/left-white.svg';
import rightWhite from '../assets/right-white.svg';

class InputDateDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            showCalendar: false,
        };
    }
    componentDidMount() {
        // This used to be empty so that it would show the "Show calendar" option,
        // but I think it makes more sense to always show it
        this.showCalendar();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.value !== prevProps.value) {
            this.setState({index: 0});
        }
    }
    setDate(date, forceBlur = false) {
        this.props.onBlur(date || null, forceBlur);
    }
    showCalendar() {
        this.setState({showCalendar: true}, () => {
            const field = document.getElementById('datepicker');
            this.picker = new Pikaday({
                onSelect: (date) => {
                    this.setDate(date);
                }
            });
            field.parentNode.insertBefore(this.picker.el, field.nextSibling);
        });
    }
    componentWillUnmount() {
        if(this.picker) this.picker.destroy();
    }
    handleArrow(e, direction, options) {
        e.preventDefault();
        let index;
        if(this.state.index === -1) {
            if(direction === 'up') {
                index = options.length - 1;
            } else if(direction === 'down') {
                index = 0;
            }
        } else {
            if(direction === 'up') {
                index = Math.max(this.props.value ? 0 : -1, this.state.index - 1);
            } else if(direction === 'down') {
                if(!this.props.value && this.state.index + 1 === options.length) {
                    index = -1;
                } else {
                    index = Math.min(options.length - 1, this.state.index + 1);
                }
            }
        }
        this.setState({index});
    }
    render() {
        const value = this.props.value.toLowerCase().split('.').join(''); // Remove dots (like 1 jan. 2020)
        const words = value.split(' ');
        if(words.includes('this')) words.splice(words.indexOf('this'), 1);
        if(words.includes('next')) words.splice(words.indexOf('next'), 1);

        const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
        if(words.length > 1 && days.map(x => x.substring(0,3)).includes(words[0].substring(0,3))) words.shift(); // Strip days like 'Mon' before date

        const dF = window.appDateFormat === 'M D, Y' ? 'MMMM D,' : 'D MMMM';
        const dS = window.appDateFormat === 'M D, Y' ? 'MMM D,' : 'D MMM';

        let options = [];
        if(value.startsWith('today'.substring(0, value.length))) options.push({text: 'Today', icon: 'BookmarkFavorite.InterfaceFavoriteStar2', sub: value ? moment().format(dS) : false, date: moment().unix()});
        if(value.startsWith('tomorrow'.substring(0, value.length))) options.push({text: 'Tomorrow', icon: 'Arrows.InterfaceArrowsBendUpRight2', sub: value ? moment().add(1, 'day').format(dS) : false, date: moment().add(1, 'day').unix()});

        // Parse dates
        const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
        const periods = ['days', 'weeks', 'months', 'years'];
        let wordMonth = words.find(x => x.match(/[a-zA-Z]+/g));
        const wordDayNumber = words.find(x => isInt(x) && x <= 31);
        const wordYear = words.find(x => x !== wordDayNumber && (x.startsWith('20') || x.startsWith('19')));
        const currentYear = moment().year();
        const year = wordYear ? (wordYear.length === 4 ? wordYear : (wordYear.length === 3 ? `${wordYear}0` : currentYear)) : currentYear;
        const dayNumber = parseInt(wordDayNumber) || 1;

        const matchingDays = wordMonth ? days.filter(x => wordMonth.startsWith(x.substring(0, wordMonth.length))) : [];
        const matchingMonths = wordMonth ? months.filter(x => wordMonth.startsWith(x.substring(0, wordMonth.length))) : [];

        if(wordMonth) {
            const startsWithIn = wordMonth.startsWith('in'.substring(0, wordMonth.length));
            if(startsWithIn) wordMonth = words.filter(x => x.match(/[a-zA-Z]+/g))[1];
            const matchingPeriods = wordMonth ? periods.filter(x => wordMonth.startsWith(x.substring(0, wordMonth.length))) : [];

            if(!startsWithIn) {
                if(matchingMonths.length) {
                    for(const matchingMonth of matchingMonths) {
                        const m = moment(`${dayNumber} ${matchingMonth} ${year}`, 'D MMMM YYYY');
                        options.push({text: `${moment(m).format(`${dF} ${year === currentYear ? '' : 'YYYY'}`)}`, sub: m.format('ddd'), date: m.unix()});
                    }
                }
                if(matchingMonths.length === 1 && year === currentYear) {
                    const m1 = moment(`${dayNumber} ${matchingMonths[0]} ${moment(year, 'YYYY').add(1, 'year').year()}`, 'D MMMM YYYY');
                    const m2 = moment(`${dayNumber} ${matchingMonths[0]} ${moment(year, 'YYYY').add(2, 'year').year()}`, 'D MMMM YYYY');
                    options.push({text: `${m1.format(`${dF} YYYY`)}`, sub: m1.format('ddd'), date: m1.unix()});
                    options.push({text: `${m2.format(`${dF} YYYY`)}`, sub: m2.format('ddd'), date: m2.unix()});
                }
                if(value === 'next week') {
                    const m = moment().add(1, 'week');
                    options.push({text: 'In 1 week', sub: m.format(`ddd ${dS}`), date: m.unix()});
                }
                if(value === 'next month') {
                    const m = moment().add(1, 'month');
                    options.push({text: 'In 1 month', sub: m.format(`ddd ${dS}`), date: m.unix()});
                }
                if(value === 'next year') {
                    const m = moment().add(1, 'year');
                    options.push({text: 'In 1 year', sub: m.format(`ddd ${dS} YYYY`), date: m.unix()});
                }
            }
            if(startsWithIn || (wordDayNumber && matchingPeriods.length)) {
                if(!wordMonth) {
                    const mDay = moment().add(dayNumber, 'day');
                    const mWeek = moment().add(dayNumber, 'week');
                    const mMonth = moment().add(dayNumber, 'month');
                    options.push({text: `In ${dayNumber} ${dayNumber === 1 ? 'day' : 'days'}`, sub: mDay.format(`ddd ${mDay.isSame(moment(), 'isoWeek') ? '' : 'D MMM'} ${mDay.isSame(moment(), 'year') ? '' : 'YYYY'}`), date: mDay.unix()});
                    options.push({text: `In ${dayNumber} ${dayNumber === 1 ? 'week' : 'weeks'}`, sub: mWeek.format('ddd D MMM'), date: mWeek.unix()});
                    options.push({text: `In ${dayNumber} ${dayNumber === 1 ? 'month' : 'months'}`, sub: mMonth.format('ddd D MMM'), date: mMonth.unix()});
                } else if(matchingPeriods[0]) {
                    options = options.slice(0,2);
                    const m = moment().add(dayNumber, matchingPeriods[0]);
                    const period = dayNumber === 1 ? matchingPeriods[0].substring(0, matchingPeriods[0].length - 1) : matchingPeriods[0];
                    options.push({text: `In ${dayNumber} ${period}`, sub: m.format(`ddd ${m.isSame(moment(), 'isoWeek') ? '' : 'D MMM'} ${m.isSame(moment(), 'year') ? '' : 'YYYY'}`), date: m.unix()});
                }
            }
            if(matchingDays.length) {
                for(const matchingDay of matchingDays) {
                    options = options.slice(0,2);
                    const m = moment().startOf('isoWeek').add(days.indexOf(matchingDay), 'day');
                    if(moment().startOf('day').isAfter(m)) m.add(1, 'week');
                    const now = moment().startOf('day');
                    let sub = capitalize(m.from(now));
                    if(moment().startOf('day').isSame(m, 'day')) sub = 'Today';
                    if(moment().startOf('day').add(1, 'day').isSame(m, 'day')) sub = 'Tomorrow';
                    options.push({text: m.format(`ddd ${dS}`), sub, date: m.unix()});
                }
                if(matchingDays.length === 1) {
                    const date = moment().startOf('isoWeek').add(days.indexOf(matchingDays[0]), 'day');
                    const now = moment().startOf('day');
                    if(moment().startOf('day').isAfter(date)) date.add(1, 'week');
                    const m1 = moment(date).add(1, 'week');
                    const m2 = moment(date).add(2, 'week');
                    options.push({text: `${m1.format(`ddd ${dS}`)}`, sub: capitalize(m1.from(now)), date: m1.unix()});
                    options.push({text: `${m2.format(`ddd ${dS}`)}`, sub: capitalize(m2.from(now)), date: m2.unix()});
                }
            }
        } else if(wordDayNumber) {
            let m1 = moment(wordDayNumber, 'D');
            if(!m1.isValid()) m1 = moment().endOf('month'); // When typing 31 in a shorter month like April, it should suggest 30 April instead of Invalid Date.
            const m2 = moment().add(wordDayNumber, 'days');
            options.push({text: `${m1.format(dF)}`, sub: m1.format('ddd'), date: m1.unix()});
            options.push({text: `In ${wordDayNumber} day${wordDayNumber === '1' ? '' : 's'}`, sub: m2.format(`ddd ${moment().isSame(m2, 'isoWeek') ? '' : dS}`), date: m2.unix()});
        }

        options = options.slice(0,3);

        return (
            <Dropdown approxHeight={this.state.showCalendar ? 315 : 120} isWithInput arrowPosition={this.props.arrowPosition || 'left'} offset={this.props.offset} onOpen={this.props.onClose} positionTop={this.props.positionTop} width={258} hasPadding={false} isOpen style={{position: 'absolute', left: 0, bottom: 0}}>
                <Shortcut alsoWorksWhenInputInFocus press='ArrowUp' onPress={e => this.handleArrow(e, 'up', options)} />
                <Shortcut alsoWorksWhenInputInFocus press='ArrowDown' onPress={e => this.handleArrow(e, 'down', options)} />
                <Shortcut alsoWorksWhenInputInFocus press='Enter' onPress={() => options[this.state.index] ? this.setDate(options[this.state.index].date, true) : this.props.onClose()} />
                <Shortcut alsoWorksWhenInputInFocus press='Tab' onPress={() => options[this.state.index] ? this.setDate(options[this.state.index].date) : this.props.onClose()} />
                {options.length ? (
                    <div className='p-2'>
                        {options.map((x,i) => {
                            const text = x.text && x.text.trim().endsWith(',') ? x.text.trim().substring(0, x.text.trim().length-1) : x.text;
                            const sub = x.sub && x.sub.trim().endsWith(',') ? x.sub.trim().substring(0, x.sub.trim().length-1) : x.sub;
                            return <DropdownItem onClick={() => this.setDate(x.date, true)} sub={sub} autoClose={false} key={text} isHover={this.state.index === i} icon={x.icon}>{text}</DropdownItem>
                        })}
                    </div>
                ) : <div className='h-32'><State iconSize={20} icon='Alerts.InterfaceAlertWarningCircle'>
                    <p className='sub text-center mt-3 px-5'>Type something like <span className='text-gray-800 dark:text-gray-300 font-medium'>2 september</span>, <span className='text-gray-800 dark:text-gray-300 font-medium'>Tuesday</span> or <span className='text-gray-800 dark:text-gray-300 font-medium'>in 3 days</span>.</p>
                </State></div>}
                {this.state.showCalendar ? (
                    <div className={`${value ? 'hidden' : 'border-t'}`}>
                        <div id='datepicker' />
                    </div>
                ) : (value ? null : (
                    <div className='border-t p-2'><DropdownItem autoClose={false} onClick={() => this.showCalendar()} icon='Arrows.InterfaceArrowsButtonDown'>Show calendar...</DropdownItem></div>
                ))}
                <style>{`
                    .pika-prev { background-image: url('${leftBlack}') !important;}
                    .pika-next { background-image: url('${rightBlack}') !important;}
                    @media (prefers-color-scheme: dark) {
                        .pika-prev { background-image: url('${leftWhite}') !important;}
                        .pika-next { background-image: url('${rightWhite}') !important;}
                    }
                `}</style>
            </Dropdown>
        );
    }
}

InputDateDropdown.propTypes = {
    onBlur: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default InputDateDropdown;