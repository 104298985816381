import React from 'react';
import PropTypes from 'prop-types';
import StreamlineIcon from "@streamlinehq/streamline-icons-react";
import {AddRemoveDelete,Alerts,Align,Arrows,BookmarkFavorite,Calendar,Content,Cursor,Dashboard,Edit,FilesFolders,GeometricShape,Help,Hierarchy,Home,ID,Layouts,Lighting,LinkUnlink,LockUnlock,LoginLogout,PageController,Presentation,Search,Security,Setting,Share,Signal,TextFormatting,Time,TrophyAward,UploadDownload,User,Validation,Weather} from "@streamlinehq/streamline-micro-line/lib/interface-essential";
import {Airport,Hotel,Map,Places,Transportation,Wayfinder} from "@streamlinehq/streamline-micro-line/lib/maps-travel";
import {Drinks,Food,Kitchenware} from "@streamlinehq/streamline-micro-line/lib/food-drink";
import {Nature,Science} from "@streamlinehq/streamline-micro-line/lib/nature-ecology";
import {ChatBubble,Inbox,Send,Signs,Smiley} from "@streamlinehq/streamline-micro-line/lib/mail";
import {Browser,Bugs,Clouds,Modules,RSS,Script,Web} from "@streamlinehq/streamline-micro-line/lib/programming";
import {Battery,Chips,Computer,Connection,Controller,Database,Handheld,Keyboard,Logo,Mouse,Printer,Robot,Screens,Smartwatch,Storage,VirtualReality,VoiceMail,Webcam} from "@streamlinehq/streamline-micro-line/lib/computer-devices";
import {Camera as Camera2,Flash,Lenses,Photos,Pictures} from "@streamlinehq/streamline-micro-line/lib/images-photography";
import {ATM,Bags,Bank,BuildingStore,Cart,Cash,Cashier,Currency,GiftPresent,Graphs,Jewelry,Legal,Safe,Wallet} from "@streamlinehq/streamline-micro-line/lib/money-shopping";
import {Camera,ControlButtons,MusicNotes,News,Party,Playlist,RadioWalkman,Recording,Speakers,Tickets,VolumeControl} from "@streamlinehq/streamline-micro-line/lib/entertainment";
import {Contact,MobilePhone,Mode,Signal as Signal2} from "@streamlinehq/streamline-micro-line/lib/phone";
import {Box,Transfer} from "@streamlinehq/streamline-micro-line/lib/shipping";
import {Health} from "@streamlinehq/streamline-micro-line/lib/health";
import {Religion,Symbols} from "@streamlinehq/streamline-micro-line/lib/religion";

class Icon extends React.Component {
    constructor(props) {
        super(props);
        this.icon = {
            AddRemoveDelete,Alerts,Align,Arrows,BookmarkFavorite,Calendar,Content,Cursor,Dashboard,Edit,FilesFolders,GeometricShape,Help,Hierarchy,Home,ID,Layouts,Lighting,LinkUnlink,LockUnlock,LoginLogout,PageController,Presentation,Search,Security,Setting,Share,Signal,TextFormatting,Time,TrophyAward,UploadDownload,User,Validation,Weather,
            Airport,Hotel,Map,Places,Transportation,Wayfinder,
            Drinks,Food,Kitchenware,
            Nature,Science,
            ChatBubble,Inbox,Send,Signs,Smiley,
            Browser,Bugs,Clouds,Modules,RSS,Script,Web,
            Battery,Chips,Computer,Connection,Controller,Database,Handheld,Keyboard,Logo,Mouse,Printer,Robot,Screens,Smartwatch,Storage,VirtualReality,VoiceMail,Webcam,
            Camera2,Flash,Lenses,Photos,Pictures,
            ATM,Bags,Bank,BuildingStore,Cart,Cash,Cashier,Currency,GiftPresent,Graphs,Jewelry,Legal,Safe,Wallet,
            Camera,ControlButtons,MusicNotes,News,Party,Playlist,RadioWalkman,Recording,Speakers,Tickets,VolumeControl,
            Contact,MobilePhone,Mode,Signal2,
            Box,Transfer,
            Health,
            Religion,Symbols,
        };
    }
    render() {
        let iconStr = this.props.icon;

        // Shortcuts for often-used icons
        if(iconStr === 'add') iconStr = 'AddRemoveDelete.InterfaceAdd1';
        if(iconStr === 'add-circle') iconStr = 'AddRemoveDelete.InterfaceAddCircle';
        if(iconStr === 'arrow-up') iconStr = 'Arrows.InterfaceArrowsUp';
        if(iconStr === 'arrow-down') iconStr = 'Arrows.InterfaceArrowsDown';
        if(iconStr === 'arrow-left') iconStr = 'Arrows.InterfaceArrowsLeft';
        if(iconStr === 'arrow-right') iconStr = 'Arrows.InterfaceArrowsRight';
        if(iconStr === 'chart-pie') iconStr = 'Graphs.MoneyGraphPieChart';
        if(iconStr === 'chart-bar') iconStr = 'Graphs.MoneyGraphBar';
        if(iconStr === 'chart-line') iconStr = 'Graphs.MoneyGraph';
        if(iconStr === 'check') iconStr = 'Validation.InterfaceValidationCheck';
        if(iconStr === 'chevron-left') iconStr = 'Arrows.InterfaceArrowsButtonLeft';
        if(iconStr === 'chevron-right') iconStr = 'Arrows.InterfaceArrowsButtonRight';
        if(iconStr === 'chevron-up') iconStr = 'Arrows.InterfaceArrowsButtonUp';
        if(iconStr === 'chevron-down') iconStr = 'Arrows.InterfaceArrowsButtonDown';
        if(iconStr === 'clock') iconStr = 'Time.InterfaceTimeClockCircle';
        if(iconStr === 'cross') iconStr = 'AddRemoveDelete.InterfaceDelete1';
        if(iconStr === 'delete') iconStr = 'AddRemoveDelete.InterfaceDeleteBin2';
        if(iconStr === 'download') iconStr = 'UploadDownload.InterfaceDownloadCircle';
        if(iconStr === 'edit') iconStr = 'Edit.InterfaceEditPencil';
        if(iconStr === 'filter') iconStr = 'TextFormatting.InterfaceTextFormattingFilter1';
        if(iconStr === 'group') iconStr = 'User.InterfaceUserMultiple';
        if(iconStr === 'help') iconStr = 'Help.InterfaceHelpQuestionCircle';
        if(iconStr === 'info') iconStr = 'Alerts.InterfaceAlertInformationCircle';
        if(iconStr === 'image') iconStr = 'Pictures.ImagePictureGallery';
        if(iconStr === 'import') iconStr = 'UploadDownload.InterfaceDownloadButton2';
        if(iconStr === 'key') iconStr = 'LoginLogout.InterfaceLoginKey';
        if(iconStr === 'label') iconStr = 'BookmarkFavorite.InterfaceBookmarkTag';
        if(iconStr === 'layers') iconStr = 'Align.InterfaceAlignLayers1';
        if(iconStr === 'link') iconStr = 'LinkUnlink.InterfaceLink';
        if(iconStr === 'lock') iconStr = 'LockUnlock.InterfaceLock';
        if(iconStr === 'more') iconStr = 'Setting.InterfaceSettingMenuHorizontal';
        if(iconStr === 'refresh') iconStr = 'Arrows.InterfaceArrowsSynchronize';
        if(iconStr === 'user') iconStr = 'User.InterfaceUserSingle';
        if(iconStr === 'user-circle') iconStr = 'User.InterfaceUserCircle';
        if(iconStr === 'user-add') iconStr = 'User.InterfaceUserAdd';
        if(iconStr === 'users') iconStr = 'User.InterfaceUserMultiple';
        if(iconStr === 'search') iconStr = 'Search.InterfaceSearch';
        if(iconStr === 'settings') iconStr = 'Setting.InterfaceSettingCog';
        if(iconStr === 'star') iconStr = 'BookmarkFavorite.InterfaceFavoriteStar1';
        if(iconStr === 'subtract') iconStr = 'AddRemoveDelete.InterfaceRemove1';
        if(iconStr === 'unlock') iconStr = 'LockUnlock.InterfaceUnlock';
        if(iconStr === 'upload') iconStr = 'UploadDownload.InterfaceUploadCircle';
        if(iconStr === 'warning') iconStr = 'Alerts.InterfaceAlertWarningCircle';

        if(!iconStr) return <div className={`h-${this.props.size}`} />;

        const icon = eval(`this.icon.${iconStr}`);
        return (
            <div onClick={this.props.onClick} style={this.props.style} title={this.props.title} className={`icon text-${this.props.color} ${this.props.colorDark ? `dark:text-${this.props.colorDark}` : ''} inline-block ${this.props.title ? '' : 'pointer-events-none'} ${this.props.bg ? `bg-${this.props.bg} dark:bg-${this.props.bgDark}` : ''} ${this.props.className || ''} ${this.props.noStyling ? '' : (this.props.bg ? `rounded-full p-${this.props.padding}` : '')}`}>
                <StreamlineIcon size={this.props.size} stroke='currentColor' fill={this.props.fill ? 'currentColor' : null} icon={icon} />
            </div>
        )
    }
}

Icon.propTypes = {
    bg: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.string,
    colorDark: PropTypes.string,
    fill: PropTypes.bool,
    icon: PropTypes.string,
    noStyling: PropTypes.bool,
    padding: PropTypes.number,
    size: PropTypes.number,
    style: PropTypes.object,
};

Icon.defaultProps = {
    padding: 4,
    size: 24,
};

export default Icon;